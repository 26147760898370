var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("dt-tip-el"),
      _vm._v(" "),
      _c("dt-modal-el"),
      _vm._v(" "),
      _c("dt-message-box-el"),
      _vm._v(" "),
      _c("dt-notice-el"),
      _vm._v(" "),
      _c("dt-loading-el"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }