
<template>
  <div class="successSim-main">
      <div class="main">
        <div>申请已提交，我们会在1个工作日内与您取得联系。</div>
        <div>如您不想等待，也可以主动联系客服</div>
        <div class="img"></div>
      </div>
      <div class="footer">
          <el-button size="small" type="primary" @click="$store.dispatch('hideModal')">确定</el-button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'successSim',
  data () {
    return {}
  },
  mounted () {},
  computed: {},
  methods: {},
  beforeDestroy () {}
}
</script>

<style scoped>
  .successSim-main{
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }
  .main{
    line-height: 1.2rem;
  }
  .img{
    height: 180px;
    margin: 20px 0;
    background: url("https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png") no-repeat center center;
    background-size: 160px 160px;
  }
  .footer{
    display: flex;
    justify-content: flex-end;
    height: 50px;
    align-items: center;
    position: relative;
  }
  .footer:before{
    content: '';
    display: inline-block;
    height: 1px;
    width: calc( 100% + 40px );
    position: absolute;
    top: 0;
    left: -20px;
    background-color: rgba(0,0,0,.1);
    transform: scaleY(.5);
  }
</style>
