<template>
  <div class="layout-body">
    <el-container>
      <el-header ref="dtHeader" v-if="!isFullScreen">
        <div class="header-fl">
          <div class="l-logo" @click="handleCompany">
            <div :class="handleLogoLogic"></div>
          </div>
          <div v-if="!isFullScreen" class="ll-logo" ref="header">
            <div class="new-page">
              <template v-for="(item, index) in headerList">
                <div
                  :key="index"
                  :class="routeClass(item)"
                  @click="goPage(item)"
                >
                  <span
                    v-if="insightMsg && item.name === '智能决策'"
                    class="datMsg"
                    >{{ insightMsg }}</span
                  >
                  <span class="open-tip">{{ item.tip }}</span>
                  <span>{{ item.name }}</span>
                </div>
              </template>
              <el-popover
                v-if="!isScrm && moreRoute.length"
                placement="top-start"
                width="300"
                trigger="hover"
              >
                <div class="s-page" slot="reference">
                  更多 <i class="el-icon-arrow-down" />
                </div>
                <div style="height:300px;overflow:auto;">
                  <el-row v-for="(item, index) in moreRoute" :key="index">
                    <el-col :span="24">
                      <div :class="routeClassMore(item)" @click="goPage(item)">
                        <svg-icon
                          style="margin-right:10px;font-size:20px;margin-bottom:5px"
                          :icon-class="item.icon"
                        />
                        <span>{{ item.name }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-popover>
            </div>
            <div
              class="global-search"
              v-if="permissionList.includes('智能获客-企业查询')"
            >
              <el-autocomplete
                v-model="searchInput"
                @focus="mouseFocus"
                :fetch-suggestions="querySearchAsync"
                @change="kongInput"
                @blur="mouseBlur"
                @select="handleSelectSugg"
                size="small"
                clearable
                placeholder="搜企业"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <template slot-scope="{ item }">
                  <div class="name" v-html="item"></div>
                </template>
              </el-autocomplete>

              <img
                class="search-icon"
                src="../../assets/search-btn.png"
                @click="kongInput"
              />
            </div>
            <div class="call-system">
              <phone-strip v-if="isWaihu == '1'" />
            </div>
          </div>
        </div>

        <el-popover
          ref="popover"
          popper-class="el-popper-dt-dt"
          placement="top-start"
          trigger="click"
          v-model="chooseShow"
        >
          <div class="new-logout">
            <div @click="goPage(sysConfig)" class="center-u">
              个人中心
            </div>

            <div v-if="showPhone" @click="connectPhone">
              重新连接话机
            </div>
            <div @click="logout" v-if="env !== 'tt' && env !== 'dd'">
              退出登录
            </div>
          </div>
          <div class="n-i" slot="reference">
            <div class="version-desc">
              <div>{{ userName }}</div>
              <div>
                <span v-if="versionName"> {{ versionName }}</span>
                <span>{{ selectMode }}</span>
              </div>
            </div>
            <div>
              <el-avatar
                :src="headerImg"
                style="height: 35px; width: 35px"
              ></el-avatar>
            </div>
          </div>
        </el-popover>
      </el-header>
      <el-container>
        <el-aside
          v-if="!isFullScreen"
          :style="open ? 'width:180px' : 'width:auto'"
          ref="dtAside"
        >
          <div class="bt-aside">
            <div class="bt-btn" @click="toggleClick">
              <i :class="open ? '' : 'rotate'" />
            </div>
            <dt-menu :open="!open" />
          </div>
        </el-aside>
        <el-main :class="!isFullScreen ? 'main-wrapper' : ''">
          <div :class="showMask ? 'm-mask main' : 'main'">
            <template v-if="$route.meta.keepAlive">
              <keep-alive>
                <router-view v-if="forbidden" />
              </keep-alive>
            </template>

            <template v-else>
              <router-view v-if="forbidden" />
            </template>
            <Forbidden
              message="您没有此页面的访问权限，请联系主账号设置。"
              v-if="!forbidden"
            />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import DtMenu from './menu'
import { config } from '@/config'
import Forbidden from '../404'
import { getAccountInfo } from '@/api/usercenter'
import { getModuleConfig } from '@/api/module'
import { LoginOut } from '@/_dtbird_api/api_service'
import PhoneStrip from '@/components/PhoneStrip'
import editPwd from '@/views/usercenter/dialog/editPwd'
import viewPhone from '@/views/layout/dialog/viewPhone'
import successSim from '@/views/layout/dialog/successSim'
import successXiaoMi from '@/views/layout/dialog/successXiaoMi'
import { esHistory, searchAuto } from '@/api/highsearch'
import { debounceFn } from '@/utils/helper'
import DtScroll from '@/components/element/DtScroll'
const elementResizeDetectorMaker = require('element-resize-detector')
const watchFnc = elementResizeDetectorMaker()
export default {
  name: 'DtLayOut',
  data() {
    return {
      menuType: sessionStorage.menuType,
      sysConfig: {
        pathObj: {
          uri: '/user-center/user-center'
        },
        record: 'sys'
      },
      chooseShow: false,
      currentIndex: 0,
      searchInput: '',
      selectMode: '',
      kongNo: false,
      mouseStatus: false,
      isWaihu: '0',
      open: true,
      btUserClass: 'aside-open-user',
      btLogoClass: 'aside-open-logo',
      userName: '',
      userRemain: null,
      mealTypeName: '',
      role: '',
      logo:
        'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
      userInfo: {},
      visible1: false,
      visible2: false,
      visible: false,
      hidden: false,
      headerImg:
        'https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/h-der.svg',
      refreshMenuKey: 0,
      showSim5: true,
      showAno: true,
      showBanner: true,
      bandWaihuType: '',
      showNewHeader: false,
      headerList: [],
      setMoreHeader: [],
      count: 0,
      showList: [],
      topMenu: [],
      moreRoute: []
    }
  },

  created() {
    this.getUserInfo()
    this.getMenuInfo()
  },

  mounted() {
    this.isHx = process.env.VUE_APP_BASE_API.includes('hx')
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy() {
    const el = this.$refs.header
    watchFnc.uninstall(el)
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    async getMenuInfo() {
      const { data } = await getModuleConfig()
      this.topMenu = data?.modules
      console.log(data, 9944)
    },

    routeClass(item) {
      if (sessionStorage.menuType === item.record) {
        return 'is-active s-page'
      } else {
        return 's-page'
      }
    },

    routeClassMore(item) {
      if (sessionStorage.menuType === item.record) {
        return 'is-active-n s-page-n'
      } else {
        return 's-page-n'
      }
    },

    handleResize: debounceFn(function() {
      const viewWidth = document.body.clientWidth
      console.log(viewWidth, this.newHeaderList, 'viewWidth')
      this.showList = this.newHeaderList
      if (viewWidth >= 0 && viewWidth < 900) {
        this.headerList = []
        this.moreRoute = this.showList
      } else if (viewWidth >= 900 && viewWidth < 1000) {
        this.sliceArray(1)
      } else if (viewWidth >= 1000 && viewWidth < 1100) {
        this.sliceArray(2)
      } else if (viewWidth >= 1100 && viewWidth < 1200) {
        this.sliceArray(3)
      } else if (viewWidth >= 1200 && viewWidth < 1300) {
        this.sliceArray(4)
      } else if (viewWidth >= 1300 && viewWidth < 1400) {
        this.sliceArray(5)
      } else if (viewWidth >= 1400 && viewWidth < 1500) {
        this.sliceArray(6)
      } else if (viewWidth >= 1500 && viewWidth < 1600) {
        this.sliceArray(7)
      } else {
        if (this.showList.length > 10) {
          this.sliceArray(10)
        } else {
          this.headerList = this.showList
          this.moreRoute = []
        }
      }
    }),

    sliceArray(num) {
      this.headerList = this.showList.slice(0, num)
      this.moreRoute = this.showList.slice(num)
    },

    showAside() {
      this.$nextTick(() => {
        const dom = this.$refs.dtAside.$el
        const hideList = config.hideAside
        const path = this.$router.currentRoute.path
        const isHideAside = hideList.some(item => item.route == path)
        console.log(isHideAside, this.$router.currentRoute, path, 'isHideAside')
        dom.style.display = isHideAside ? 'none' : 'block'
      })
    },

    goPage(item) {
      console.log(item, 'ggg')
      sessionStorage.setItem('menuType', item.record)
      this.$router.push(item.pathObj.uri)
    },

    handlePathObj(list) {
      let pathObj = {}
      this.allMenuList.forEach(item => {
        if (list[0] == item.id) {
          pathObj = item?.pages?.[0]
        }
      })
      return pathObj
    },

    kongInput() {
      this.kongNo = false
      if (this.searchInput) {
        this.$router.push(`/globalquery/query?c=${this.searchInput}`)
      }
    },

    mouseFocus() {
      this.mouseStatus = true
    },

    mouseBlur() {
      this.mouseStatus = false
    },

    handleSelectSugg(item) {
      const string = item
      const reg = /<[^>]+>/g
      this.searchInput = string.replace(reg, '')
      this.$router.push(`/globalquery/query?c=${this.searchInput}`)
    },

    querySearchAsync(queryString, cb) {
      if (!this.$store.getters.sub) {
        return
      }
      if (!queryString) {
        esHistory().then(res => {
          if (res.code === 60000) {
            cb(res.data)
          }
        })
        return
      }

      searchAuto({ words: queryString }).then(res => {
        if (res.code === 60000) {
          cb(res.data)
        }
      })
    },

    connectPhone() {
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text:
          '正在准备连接话机,请确保中间件与话机已经建立连接且SIM卡状态正常后再点击确认,否则点击取消',
        onClose: res => {
          if (res === 'confirm') {
            this.$store.dispatch('showTip', {
              text: '开始尝试连接',
              type: 'success'
            })
            this.$store.dispatch('createSocket')
          }
        },
        type: 'warning'
      })
    },

    closeBanner() {
      this.showBanner = false
      localStorage.setItem('showBanner', 'false')
    },

    viewPhone() {
      this.$store.dispatch('showModal', {
        view: viewPhone,
        size: '800px',
        title: '详情',
        onClose: res => {
          console.log(res)
          if (res.isOk) {
            const time = setTimeout(() => {
              this.$store.dispatch('showModal', {
                view: successSim,
                size: '700px',
                title: '提示'
              })
              clearTimeout(time)
            }, 100)
          }
        }
      })
    },

    viewMi() {
      this.$store.dispatch('showModal', {
        view: successXiaoMi,
        size: '800px',
        title: '详情',
        onClose: res => {
          console.log(res)
          if (res.isOk) {
            const time = setTimeout(() => {
              this.$store.dispatch('showModal', {
                view: successSim,
                size: '700px',
                title: '提示'
              })
              clearTimeout(time)
            }, 100)
          }
        }
      })
    },

    toggleClick() {
      this.open = !this.open
      this.$store.state.settings.isAsideOpen = this.open
    },

    handleCompany() {
      this.$router.replace('/company/index')
    },

    getVersion() {
      this.userRemain = this.$store.getters.userInfo.remain
      this.mealTypeName = this.$store.getters.accountInfo.versionName
    },

    getUserInfo() {
      getAccountInfo().then(res => {
        this.userInfo = res.data
        this.userName = res.data.followName
        this.role = res.data.role
        const selectMode = res.data?.cloudOpenList.map(item => item.name).join()
        this.selectMode = `(${selectMode})`
        this.$store.dispatch('setAccountInfo', res.data)
        this.bandWaihuType = res.data.bandWaihuType
        this.getVersion()
        this.isWaihu = res.data.isWaihu
        this.headerImg = res.data.wxHeadImg
          ? res.data.wxHeadImg
          : 'https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/h-der.svg'
        if (res.data.modifyPwd === 1 && this.showLogout) {
          this.resetPwd()
        }
      })
    },

    resetPwd() {
      this.$store.dispatch('showModal', {
        title: '修改密码',
        view: editPwd,
        size: '600px',
        options: {
          showTip: true,
          closeModel: () => {
            LoginOut()
            this.$store.dispatch('hideModal')
          }
        }
      })
    },

    logout() {
      this.$refs.popover.showPopper = false
      LoginOut()
    }
  },

  watch: {
    open(val) {
      this.$store.commit('update', {
        target: this.$store.state.permission,
        data: {
          menuOpen: val
        }
      })

      if (val) {
        this.btUserClass = 'aside-open-user'
        this.btLogoClass = 'aside-open-logo'
      } else {
        this.btUserClass = 'aside-close-user'
        this.btLogoClass = 'aside-close-logo'
      }
    },

    $route: {
      handler(val) {
        console.log(val, 'router_change')
        this.showAside()
      },
      deep: true,
      immediate: true
    },

    '$store.state.settings.isFullScreen': {
      handler() {
        console.log('isFull')
        this.showAside()
      },
      immediate: true
    }
  },

  computed: {
    hideHeader() {},

    newHeaderList() {
      this.topMenu.forEach(item => {
        item.name = item.module_name
        item.pathObj = this.handlePathObj(item?.menu_id)
      })
      return this.topMenu
    },

    handleLogoLogic() {
      if (this.open) {
        return this.isHx ? 'open-hx' : 'open-bird'
      }
      return 'close'
    },

    message() {
      return this.$store.state.permission.messageNum
    },

    insightMsg() {
      const totalMsg = this.$store.state.permission.messageNum
      const calculateMsg =
        totalMsg?.Trz + totalMsg?.developer + totalMsg?.overseas
      return calculateMsg > 0 ? calculateMsg : 0
    },

    allMenuList() {
      return this.$store.state.permission.allMenuList
    },

    wsObj() {
      return this.$store.state.phoneSocket.wsObj
    },

    simIndex() {
      return this.$store.state.phoneSocket.simIndex
    },

    showMask() {
      return this.$store.state.user.plate
    },

    forbidden() {
      return !this.$store.state.permission.forbidden.state
    },

    showLogout() {
      return !(
        this.$store.state.plugin.env === 'dd' ||
        this.$store.state.plugin.env === 'tt'
      )
    },

    versionName() {
      if (this.role === 'admin') {
        return this.$store.state.user.accountInfo.versionName
      }
      return false
    },

    env() {
      return this.$store.state.plugin.env
    },

    isScrm() {
      return this.$store.state.user.isScrm
    },

    permissionList() {
      return this.$store.state.permission.permission
    },

    isFullScreen() {
      return this.$store.state.settings.isFullScreen
    },

    modeOptions() {
      return this.$store.state.user.accountInfo.cloudOpenList
    },

    showPhone() {
      return (
        this.wsObj?.readyState !== 1 &&
        this.bandWaihuType === 'simPhone' &&
        !this.simIndex
      )
    }
  },

  components: {
    Forbidden,
    DtMenu,
    PhoneStrip,
    DtScroll
  }
}
</script>

<style lang="scss">
.layout-body {
  .el-popover {
    height: 600px !important;
    overflow: auto !important;
  }

  .el-header {
    display: flex;
    align-items: center;
    border-bottom: rgba(0, 0, 0, 0.1) solid 3px;
    background-color: #f5f5f6;
    justify-content: space-between;
  }
  .ll-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .header-fl {
    display: flex;
  }
}
</style>
<style lang="scss">
.layout-body {
  .dataHow {
    height: 20px;
    position: absolute;
    left: 20px;
    top: -15px;
    color: #41cabf;
    font-size: 12px;
  }
  .main-wrapper {
    height: calc(100vh - 50px);
    overflow-y: auto;
    width: 100%;
  }
  .datMsg {
    position: absolute;
    right: 0;
    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #fff;
    z-index: 22;
  }
}
</style>
<style scoped lang="scss">
.global-search {
  display: flex;
  ::v-deep .el-input__inner {
    border-radius: 0;
  }
}
.frame {
  width: 43px;
  height: auto;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111;
  border-radius: 8px;
  border: 1px solid rgba(242, 242, 242, 1);
  background-color: #fff;
}
.openStage {
  position: relative;
}
.open-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -15px;
  color: #41cabf;
  font-size: 12px;
  width: 100%;
}

.frame-logo {
  width: 100%;
  height: 31px;
  background: url('../../assets/frame-logo.svg') no-repeat;
  background-size: 100% 100%;
}
.frame-item {
  width: 100%;
  height: 50px;
  cursor: pointer;
}
.frame-text {
  width: 33px;
  height: 50px;
  border-bottom: rgba(242, 242, 242, 1) solid 1px;
  margin: 0 auto;
  font-size: 13px;
  line-height: normal;
  padding: 5px 0;
  text-align: center;
  color: #333;
}
.frame-text-active {
  color: #41cabf;
}
.bt-aside {
  height: calc(100vh - 60px);
  position: relative;
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: #fff;
  transition: width 0.3s;
  overflow-x: hidden;
}
.bt-aside-hide {
  display: none;
}

.bt-btn {
  height: 40px;
  width: 11px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-20px);
  background-color: rgb(65, 202, 191);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.bt-btn i {
  display: inline-block;
  height: 40px;
  width: 10px;
  background: url('../../assets/icon_hidden@2x.png') no-repeat;
  background-size: 100% 100%;
}

.n-i {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 12px;
}
.version-desc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
  color: #8a8a8a;
}
.version-desc > div {
  margin: 2px;
  text-align: right;
}

.aside-open-user {
  width: 200px;
  height: 50px;
}

.aside-close-user {
  width: 64px;
  height: 50px;
}

.aside-open-logo {
  position: relative;
  width: calc(100% - 200px);
  height: 50px;
  background-color: #41cabf;
  z-index: 999;
}

.aside-close-logo {
  position: relative;
  width: calc(100% - 64px);
  height: 50px;
  background-color: #41cabf;
}

.contact {
  height: 150px;
  width: 300px;
  background-color: #fff;
  z-index: 9999999;
  display: flex;
}

.msg {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
  font-size: 14px;
}

.rotate {
  transform: rotate(180deg);
}

.close {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f6
    url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/zt-logo.png')
    no-repeat center center;
  background-size: 60% 60%;
}

.open-bird {
  width: 140px;
  height: 38px;
  background: url('../../assets/pe-img/logo-ai.png') no-repeat center center;
  background-size: 80% 80%;
  transform: translateY(5px);
}
.open-hx {
  width: 210px;
  height: 38px;
  background: url('../../assets/pe-img/hx-logo.jpg') no-repeat center center;
  background-size: 80% 80%;
  transform: translateY(5px);
}

.logo-user {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-msg {
  min-width: 80px;
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  position: relative;
}
.dt-icon-down {
  font-size: 15px;
}

.user-msg div:first-child {
  margin-bottom: 2px;
}
.detail {
  text-align: center;
}

.logout {
  margin-top: 3px;
  color: #4ddcd2;
  cursor: pointer;
}
.user-name {
  color: #2e2e2e;
  max-width: 90%;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-start;
}

.call-system {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.search-icon {
  background-color: rgb(65, 202, 191);
  width: 32px;
  height: 32px;
}
.new-page {
  height: 50px;
  display: flex;
  line-height: 50px;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
}

.s-page {
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  color: #8a8a8a;
  position: relative;
}
.s-page > .new {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  color: #41cabf;
  font-size: 12px;
  background: url('../../assets/new-icon.svg') no-repeat center center;
  background-size: 20px 20px;
}

.is-active-n {
  background-color: #f5f5f6 !important;
  color: #41cabf !important;
}

.s-page-n {
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  color: #8a8a8a;
  height: 50px;
  display: flex;
  align-items: center;
}
.s-page-n > div:first-child {
  width: 20px;
  margin-right: 5px;
}

.s-page-n:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.is-active {
  color: #8a8a8a;
  background-color: #fff;
  font-size: 15px;
  position: relative;
  height: 47px;
}
.is-active:before {
  content: '';
  width: 100%;
  height: 5px;
  display: inline-block;
  background-color: #41cabf;
  position: absolute;
  top: 0;
  left: 0;
}

.new-logout {
  width: 150px;
}
.new-logout > div {
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  font-size: 12px;
  color: #000000;
}
.new-logout > div:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.new-logout > div:last-child:before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleY(0.5);
}
</style>
