import { API, BASE_REQUEST } from '../_dtbird_api/api_service'
//获取数据源
export const getSalesSource = async params => {
  const api = API('GETSALESSOURCE')
  return await BASE_REQUEST({ api: api, opt: params })
}
// POSTCUSTRANSCUS
export const postCusTransCus = async params => {
  const api = API('POSTCUSTRANSCUS')
  return await BASE_REQUEST({ api: api, opt: params })
}
//GETCUSPONDLIST
export const getCusPondList = async params => {
  const api = API('GETCUSPONDLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const removeContractNum = async params => {
  const api = API('removeContractNum')
  return await BASE_REQUEST({ api: api, opt: params })
}

//GETCUSTOMERLEADERS
export const getCustomerLeaders = async params => {
  const api = API('GETCUSTOMERLEADERS')
  return await BASE_REQUEST({ api: api, opt: params })
}
//GETCUSTAGSDDIC
export const getCusTagsDdic = async params => {
  const api = API('GETCUSTAGSDDIC')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getQyTagsDic = async params => {
  const api = API('getQyTagsDic')
  return await BASE_REQUEST({ api: api, opt: params })
}
//POSTCREATECUSINFO
export const postCreateCusInfo = async params => {
  const api = API('POSTCREATECUSINFO')
  return await BASE_REQUEST({ api: api, opt: params })
}
//GETCUSTOMERRECEIVERS
export const getCustomerReceivers = async params => {
  const api = API('GETCUSTOMERRECEIVERS')
  return await BASE_REQUEST({ api: api, opt: params })
}
//
export const getCustomerReceiversRepead = async params => {
  const api = API('GETCUSTOMERRECEIVERSREPEAD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//POSTCUSTRANSTOOTHERS
export const postCusTransToOThers = async params => {
  const api = API('POSTCUSTRANSTOOTHERS')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getCusListFilter = async params => {
  const api = API('GETCUSLISTFILTER')
  return await BASE_REQUEST({ api: api, opt: params })
}
//GETCUSFOLLOWRECORDS
export const getCusFollowRecords = async params => {
  const api = API('GETCUSFOLLOWRECORDS')
  return await BASE_REQUEST({ api: api, opt: params })
}
//DELCUSTOMERINFO
export const delCustomerInfo = async params => {
  const api = API('DELCUSTOMERINFO')
  return await BASE_REQUEST({ api: api, opt: params })
}
//PUTCUSTOMERINFO
export const putCustomerInfo = async params => {
  const api = API('PUTCUSTOMERINFO')
  return await BASE_REQUEST({ api: api, opt: params })
}
//GETCUSTOMERINFOID
export const getCustomerInfoId = async params => {
  const api = API('GETCUSTOMERINFOID')
  return await BASE_REQUEST({ api: api, opt: params })
}

//备注查询
export const remarkSearch = async params => {
  const api = API('remarkSearch')
  return await BASE_REQUEST({ api: api, opt: params })
}
//添加标签组
export const addTagGroup = async params => {
  const api = API('addTagGroup')
  return await BASE_REQUEST({ api: api, opt: params })
}
//编辑标签组
export const editTagGroup = async params => {
  const api = API('editTagGroup')
  return await BASE_REQUEST({ api: api, opt: params })
}
//删除标签组
export const deleteTagGroup = async params => {
  const api = API('deleteTagGroup')
  return await BASE_REQUEST({ api: api, opt: params })
}

//批量编辑
export const customerLoteEdit = async params => {
  const api = API('CUSTOMERLOTEEDIT')
  return await BASE_REQUEST({ api: api, opt: params })
}

//脉脉埋点
export const maimaiAdd = async params => {
  const api = API('MAIMAIADD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//领英埋点
export const linkedinAdd = async params => {
  const api = API('LINKEDINADD')
  return await BASE_REQUEST({ api: api, opt: params })
}
//线索转派埋点
export const sendClueAdd = async params => {
  const api = API('SENDCLUEADD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//转成客户埋点
export const transformAdd = async params => {
  const api = API('TRANSFORMADD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//字段编辑
export const editField = async params => {
  const api = API('EDITFIELD')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 联系人来源
export const contactSource = async params => {
  const api = API('contactSource')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const haveGoodCustomer = async params => {
  const api = API('haveGoodCustomer')
  return await BASE_REQUEST({ api: api, opt: params })
}

//获取微站列表
export const getMiniWebsite = async params => {
  const api = API('miniWebsite')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 更新微站
export const miniWebsiteUpdate = async params => {
  const api = API('miniWebsiteUpdate')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取kp服务商
export const getProvider = async params => {
  const api = API('KPPROVIDER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索是否领取
export const isGeted = async params => {
  const api = API('isGeted')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 转入touch模块联系人
export const transContact = async params => {
  const api = API('transContact')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 客户公海导出
export const cusExport = async params => {
  const api = API('cusExport')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 联系人管理
export const contactManage = async params => {
  const api = API('contactManage')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 商机列表
export const nicheList = async params => {
  const api = API('nicheList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//添加商机
export const addNiche = async params => {
  const api = API('addNiche')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 编辑商机
export const editNiche = async params => {
  const api = API('editNiche')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除商机
export const delNiche = async params => {
  const api = API('delNiche')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 商机数据权限
export const leaderNiche = async params => {
  const api = API('leaderNiche')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 商机筛选
export const nicheFilter = async params => {
  const api = API('nicheFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 跟进记录列表
export const followRecordList = async params => {
  const api = API('followRecordList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 跟进记录高级筛选
export const followRecordFilter = async params => {
  const api = API('followRecordFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 售后
export const afterSales = async params => {
  const api = API('afterSales')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 售后感激筛选
export const afterSalesFilter = async params => {
  const api = API('afterSalesFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 更新商机阶段
export const updateNicheState = async params => {
  const api = API('updateNicheState')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 售后下拉筛选
export const afterSalesOption = async params => {
  const api = API('afterSalesOption')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 自动外呼列表
export const autoWaiHuList = async params => {
  const api = API('autoWaiHuList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const importWaiHu = async params => {
  const api = API('importWaiHu')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const waiHuSetting = async params => {
  const api = API('settingWaiHu')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const editWaiHuSetting = async params => {
  const api = API('editWaiHuSetting')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const addWaHuTaskFromKey = async params => {
  const api = API('addWaHuTaskFromKey')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const callWaiHu = async params => {
  const api = API('callWaiHu')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const callDel = async params => {
  const api = API('callDel')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const noTipEdit = async params => {
  const api = API('noTipEdit')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const returnList = async params => {
  const api = API('returnList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const returnDrown = async params => {
  const api = API('returnDrown')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成收款管理的编号
export const getPayBackNum = async params => {
  const api = API('getPayBackNum')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const removeBackPayNum = async params => {
  const api = API('removeBackPayNum')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除首款账单
export const delPayBack = async params => {
  const api = API('delPayBack')
  return await BASE_REQUEST({ api: api, opt: params })
}

// addPayBack
// editPayBack
export const addPayBack = async params => {
  const api = API('addPayBack')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const editPayBack = async params => {
  const api = API('editPayBack')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getContractNum = async params => {
  const api = API('getContractNum')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const searchDomainBase = async params => {
  const api = API('searchDomainBase')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getDomain = async params => {
  const api = API('getDomain')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getCdnService = async params => {
  const api = API('getCdnService')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getYunService = async params => {
  const api = API('getYunService')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getEarnNum = async params => {
  const api = API('getEarnNum')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getEarnNumPoint = async params => {
  const api = API('getEarnNumPoint')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const addEarnNum = async params => {
  const api = API('addEarnNum')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const yunServiceHistory = async params => {
  const api = API('yunServiceHistory')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getGraph = async params => {
  const api = API('graph')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const customize = async params => {
  const api = API('customize')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const customizeDel = async params => {
  const api = API('customizeDel')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const customizePost = async params => {
  const api = API('customizePost')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const packageInsight = async params => {
  const api = API('packageInsight')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const insightMould = async params => {
  const api = API('insightMould')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const insightBind = async params => {
  const api = API('insightBind')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const insightFilter = async params => {
  const api = API('insightFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const softwareFilter = async params => {
  const api = API('softwareFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const insightSearch = async params => {
  const api = API('insightSearch')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const insightList = async params => {
  const api = API('insightList')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const softwareList = async params => {
  const api = API('softwareList')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const parkInsightFilter = async params => {
  const api = API('parkInsightFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const parkList = async params => {
  const api = API('parkList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const parkPageList = async params => {
  const api = API('parkPageList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const parkDetailDesc = async params => {
  const api = API('parkDetailDesc')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const parkCompanyList = async params => {
  const api = API('parkCompanyList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const parkMap = async params => {
  const api = API('parkMap')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const overSeaFilter = async params => {
  const api = API('overSeaFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const applicationFilter = async opt => {
  const api = API('applicationFilter')
  return await BASE_REQUEST({ api, opt })
}

export const overSeaChart = async params => {
  const api = API('overSeaChart')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const applicationChart = async opt => {
  const api = API('applicationChart')
  return await BASE_REQUEST({ api, opt })
}

export const overSeaList = async params => {
  const api = API('overSeaList')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const applicationList = async params => {
  const api = API('applicationList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const overSeaDdic = async params => {
  const api = API('overSeaDdic')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const appFilter = async params => {
  const api = API('appFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const messagePush = async params => {
  const api = API('messagePush')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const clearMessagePush = async params => {
  const api = API('clearMessagePush')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const appFilterNiuBe = async params => {
  const api = API('appFilterNiuBe')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const appInsightList = async params => {
  const api = API('appInsightList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//拨打电解密
export const parseMobile = async params => {
  const api = API('parseMobile')
  return await BASE_REQUEST({ api: api, opt: params })
}

//拨打电解密
export const getConfigData = async params => {
  const api = API('getConfigData')
  return await BASE_REQUEST({ api: api, opt: params })
}
