import { API, BASE_REQUEST } from '../_dtbird_api/api_service'
// 高级筛选条件
export const highsearch = async params => {
  const api = API('HEIGHT_SEACH')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const hotMap = async params => {
  const api = API('hotMap')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 客户地图
export const searchCompanyForMap = async params => {
  const api = API('searchCompanyForMap')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 客户地图
export const establishTimeData = async params => {
  const api = API('establishTimeData')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getCityFromPro = async params => {
  const api = API('GET_ADDRESS2')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查询经纬度
export const searchAddressForMap = async params => {
  const api = API('searchAddressForMap')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查看组织架构
export const viewOrganization = async params => {
  const api = API('viewOrganization')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const searchAuto = async params => {
  const api = API('searchAuto')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 培育池搜索列表
export const booklistFilter = async params => {
  const api = API('booklistFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const esHistory = async params => {
  const api = API('esHistory')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const esCloudService = async params => {
  const api = API('esCloudHistory')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const onCloudService = async params => {
  const api = API('onCloudService')
  return await BASE_REQUEST({ api: api, opt: params })
}
