var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ContactDetail-main", on: { click: _vm.selectContact } },
    [
      _c("div", [
        _c("span", { staticClass: "phone" }, [
          _vm._v("\n      " + _vm._s(_vm.contactData.phone) + "\n    "),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.contactData.callName) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "icon-click" },
          [
            _c("i", {
              staticClass: "card-phone-icon",
              on: { click: _vm.call },
            }),
            _vm._v(" "),
            _c(
              "el-dropdown",
              {
                attrs: { placement: "bottom-start", trigger: "click" },
                on: {
                  command: function ($event) {
                    return _vm.changeAssign($event, _vm.contactData)
                  },
                },
              },
              [
                _c("i", { staticClass: "card-phone-edit-icon" }),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.assignOption, function (item) {
                    return _c(
                      "el-dropdown-item",
                      { attrs: { command: item.value } },
                      [_vm._v(_vm._s(item.name) + "\n          ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "last" }, [
        _c("div", { staticClass: "all-mark" }, [
          _vm._v("\n      " + _vm._s(_vm.contactData.companyName) + "\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "from" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }