var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c(
      "div",
      { staticClass: "p-close" },
      [
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDetail && _vm.version === "b",
              expression: "showDetail && version === 'b'",
            },
          ],
          staticClass: "el-icon-refresh refresh",
          on: {
            click: function ($event) {
              return _vm.showKey(
                _vm.formData.packageId,
                _vm.formData.companyName,
                _vm.formData.companyUuid
              )
            },
          },
        }),
        _vm._v(" "),
        _c(
          "el-link",
          {
            staticStyle: {
              "font-size": "12px",
              "text-decoration": "underline",
              color: "#8A8A8A",
            },
            attrs: { underline: false },
            on: { click: _vm.changeVersion },
          },
          [_vm._v(_vm._s(_vm.version === "b" ? "回到旧版" : "电销专业版"))]
        ),
        _vm._v(" "),
        _c("i", {
          staticClass: "el-icon-close",
          on: { click: _vm.closePlate },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.detail.show === false && _vm.version === "b"
      ? _c("div", { staticClass: "mask" }, [
          _c("span", [
            _c("span", { staticClass: "l-q", on: { click: _vm.lingQu } }, [
              _vm._v("领取"),
            ]),
            _c("span", [_vm._v("以查看所有信息")]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.version === "b"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "Workflow-main",
            style: { filter: _vm.detail.show === false ? "blur(2px)" : "" },
          },
          [
            _vm.showDetail
              ? [
                  _vm.detail && Object.keys(_vm.detail).length
                    ? _c("div", { staticClass: "c-name-icon" }, [
                        _c(
                          "div",
                          {
                            staticClass: "c-name",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.copy(_vm.detail.company_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.detail.company_name) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "c-icon" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.detail.hot &&
                                    this.versionNum.indexOf("002") < 0,
                                  expression:
                                    "detail.hot && this.versionNum.indexOf('002') < 0",
                                },
                              ],
                              staticClass: "icon-icon hot-icon",
                            },
                            [
                              _vm._v("\n            热度:\n            "),
                              !_vm.detail.exclusiveShow
                                ? _c(
                                    "span",
                                    { staticStyle: { filter: "blur(3px)" } },
                                    [_vm._v("1089136")]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.detail.hot) +
                                        "\n            "
                                    ),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.detail.activity_class,
                                  expression: "detail.activity_class",
                                },
                              ],
                              staticClass: "active-icon icon-icon",
                            },
                            [
                              _vm._v("活跃度:\n            "),
                              !_vm.detail.exclusiveShow
                                ? _c(
                                    "span",
                                    { staticStyle: { filter: "blur(3px)" } },
                                    [_vm._v("\n              高\n            ")]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.detail.activity_class) +
                                        "\n            "
                                    ),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.kpProvider,
                                  expression: "kpProvider",
                                },
                              ],
                              staticClass: "yun-icon icon-icon",
                            },
                            [
                              _vm._v("云服务商:\n            "),
                              !_vm.detail.exclusiveShow
                                ? _c("span", [
                                    _vm._v(
                                      "\n              阿里云\n            "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.kpProvider) +
                                        "\n            "
                                    ),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _vm.kpProvider
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        "popper-class": "el-popper-dt-dt",
                                        placement: "bottom-start",
                                        width: "500",
                                        trigger: "click",
                                      },
                                      on: { show: _vm.showIdcFun },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.fromPath !==
                                                "/preview/list",
                                              expression:
                                                "fromPath !== '/preview/list'",
                                            },
                                          ],
                                          staticClass: "add-clound",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [_vm._v("历史云服务商")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.idcLoading,
                                              expression: "idcLoading",
                                            },
                                          ],
                                          staticClass: "clound-list",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "header-idc" },
                                            [
                                              _c("div", [_vm._v("IDC变更")]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  style: {
                                                    filter:
                                                      _vm.detail.show === false
                                                        ? "blur(2.5px)"
                                                        : "",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-refresh-right refresh-idc",
                                                    class: _vm.refreshIdcLoading
                                                      ? "refresh-idc-start"
                                                      : "refresh-idc-stop",
                                                    on: {
                                                      click: _vm.refreshIdc,
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-scrollbar",
                                            {
                                              staticClass:
                                                "scroll-idc dt-dropdown-dt",
                                            },
                                            [
                                              _vm.detail.show === false
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "filter-lq",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "lq-span",
                                                              on: {
                                                                click:
                                                                  _vm.lingQu,
                                                              },
                                                            },
                                                            [_vm._v("领取")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#8c939d",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "以查看所有信息"
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.idcData && _vm.idcData.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "idc-main",
                                                      style: {
                                                        filter:
                                                          _vm.detail.show ===
                                                          false
                                                            ? "blur(2.5px)"
                                                            : "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-timeline",
                                                        _vm._l(
                                                          _vm.idcData,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-timeline-item",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                  color:
                                                                    index === 0
                                                                      ? "red"
                                                                      : "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "time-idc-line",
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.time
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.idcName
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  item.follows,
                                                                  function (
                                                                    follow
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "idc-line-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              follow.isp +
                                                                                " - " +
                                                                                follow.address
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass: "empty",
                                                      style: {
                                                        filter:
                                                          _vm.detail.show ===
                                                          false
                                                            ? "blur(2.5px)"
                                                            : "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.detail.show ===
                                                              false
                                                              ? ""
                                                              : "暂无数据"
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.customerInfoMap.inPool
                            ? _c(
                                "span",
                                { staticStyle: { color: "#8A8A8A" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.customerInfoMap.desc) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "next-btn" }),
                  _vm._v(" "),
                  _vm.detail && Object.keys(_vm.detail).length
                    ? _c("div", { staticClass: "c-detail" }, [
                        _c("div", [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("公司网址:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "value",
                              staticStyle: {
                                cursor: "pointer",
                                color: "#0099CC",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toShop(_vm.detail.website)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.detail.website))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _c(
                            "span",
                            { class: _vm.isExpend ? "expend-value" : "expend" },
                            [
                              _vm._v("简介:\n            "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.detail.introduce,
                                      expression: "detail.introduce",
                                    },
                                  ],
                                },
                                [_vm._v(_vm._s(_vm.detail.introduce))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.detail.introduce,
                                  expression: "detail.introduce",
                                },
                              ],
                              staticClass: "css-btn",
                              on: {
                                click: function ($event) {
                                  _vm.isExpend = !_vm.isExpend
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.isExpend ? "展开" : "收起")
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-scrollbar",
                          {
                            staticClass: "panel-left dt-dropdown-dt",
                            staticStyle: { height: "633px" },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  人工录入联系人\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: { change: _vm.changePhoneShow },
                                          model: {
                                            value: _vm.onlyShowPhoneNum,
                                            callback: function ($$v) {
                                              _vm.onlyShowPhoneNum = $$v
                                            },
                                            expression: "onlyShowPhoneNum",
                                          },
                                        },
                                        [_vm._v("只显示手机号")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.contactData.per.length
                                ? _c(
                                    "div",
                                    { staticClass: "w-has-data" },
                                    _vm._l(
                                      _vm.contactData.per,
                                      function (item) {
                                        return _c(
                                          "div",
                                          { staticClass: "contact-panel" },
                                          [
                                            _c("Contact-detail", {
                                              attrs: {
                                                active:
                                                  item.randomId ===
                                                  _vm.showBorderId,
                                                "contact-data": item,
                                                "assign-option":
                                                  _vm.assignOption,
                                                detail: _vm.detail,
                                              },
                                              on: {
                                                refresh: _vm.getAllContact,
                                                call: _vm.call,
                                                selectContact:
                                                  _vm.selectContact,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "w-no-data" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "icon",
                                        attrs: { "icon-class": "stop-icon" },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "d-empty" }, [
                                        _vm._v("暂无数据"),
                                      ]),
                                    ],
                                    1
                                  ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("系统提供联系人"),
                              ]),
                              _vm._v(" "),
                              _vm.contactData.sys.length
                                ? _c(
                                    "div",
                                    { staticClass: "w-has-data" },
                                    _vm._l(
                                      _vm.contactData.sys,
                                      function (item) {
                                        return _c(
                                          "div",
                                          { staticClass: "contact-panel" },
                                          [
                                            _c("Contact-detail", {
                                              attrs: {
                                                active:
                                                  item.randomId ===
                                                  _vm.showBorderId,
                                                "contact-data": item,
                                                "assign-option":
                                                  _vm.assignOption,
                                                detail: _vm.detail,
                                              },
                                              on: {
                                                refresh: _vm.getAllContact,
                                                call: _vm.call,
                                                selectContact:
                                                  _vm.selectContact,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "w-no-data" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "icon",
                                        attrs: { "icon-class": "stop-icon" },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "d-empty" }, [
                                        _vm._v("暂无数据"),
                                      ]),
                                    ],
                                    1
                                  ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "title" },
                          [
                            _c("span", [_vm._v(" 拨号盘 ")]),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  "popper-class": "el-popper-dt-dt",
                                  placement: "bottom-end",
                                  width: "500",
                                  trigger: "click",
                                },
                                on: { show: _vm.showRecordRequest },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "click",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 通话记录 ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.showRecordLoading,
                                        expression: "showRecordLoading",
                                      },
                                    ],
                                    staticClass: "record-list",
                                  },
                                  _vm._l(_vm.recordList, function (item) {
                                    return _c(
                                      "div",
                                      [
                                        _c("record-list", {
                                          attrs: {
                                            "contact-data": item,
                                            "assign-option": _vm.assignOption,
                                            detail: _vm.detail,
                                          },
                                          on: {
                                            refresh: _vm.getAllContact,
                                            call: _vm.call,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "wai-hu",
                            style: {
                              height:
                                _vm.bandWaihuType === "simPhone" ? "100px" : "",
                            },
                          },
                          [_vm._t("waiHu")],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.showDetail && _vm.isLn
                        ? _c("div", { staticClass: "chance-form" }, [
                            _c(
                              "div",
                              { staticClass: "title" },
                              [
                                _c("span", [_vm._v(" 新增商机 ")]),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      "popper-class": "el-popper-dt-dt",
                                      placement: "bottom-end",
                                      width: "600",
                                      trigger: "click",
                                    },
                                    on: { show: _vm.showChanceRequest },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "click",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [_vm._v(" 商机记录 ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.loadingNiche,
                                            expression: "loadingNiche",
                                          },
                                        ],
                                        staticClass: "record-list",
                                      },
                                      _vm._l(_vm.chanceList, function (item) {
                                        return _c(
                                          "div",
                                          [
                                            _c("chance-list", {
                                              attrs: { "chance-data": item },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "chance-form-main" },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "form",
                                    attrs: {
                                      size: "small",
                                      "label-width": "140px",
                                      model: _vm.formData,
                                      rules: _vm.rules,
                                      "label-position": "left",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "客户销售团队规模",
                                          prop: "saleRange",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              placeholder:
                                                "请选择客户销售团队规模",
                                            },
                                            model: {
                                              value: _vm.formData.saleRange,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "saleRange",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.saleRange",
                                            },
                                          },
                                          _vm._l(
                                            _vm.saleRangeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "客户销售模式",
                                          prop: "saleModel",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              multiple: "",
                                              placeholder: "请选择客户销售模式",
                                            },
                                            model: {
                                              value: _vm.formData.saleModel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "saleModel",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.saleModel",
                                            },
                                          },
                                          _vm._l(
                                            _vm.saleModelList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "签单销售",
                                          prop: "pzSignSale",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择签单销售",
                                            },
                                            model: {
                                              value: _vm.formData.pzSignSale,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "pzSignSale",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.pzSignSale",
                                            },
                                          },
                                          _vm._l(
                                            _vm.xiaoShouList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "客户联系人",
                                          prop: "contactNo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          style: {
                                            border: _vm.formData.contactNo
                                              ? "1px solid orange"
                                              : "",
                                          },
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.formData.contactNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "contactNo",
                                                $$v
                                              )
                                            },
                                            expression: "formData.contactNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "预估演示时间",
                                          prop: "predicateDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "datetime",
                                            "picker-options": _vm.pickerOption,
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            placeholder: "选择预估演示时间",
                                          },
                                          model: {
                                            value: _vm.formData.predicateDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "predicateDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.predicateDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "earn-niche" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.loadingNiche,
                                            expression: "loadingNiche",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: { click: _vm.earnNiche },
                                      },
                                      [_vm._v("生成商机")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                      "margin-top": "20px",
                    },
                  },
                  [
                    _c(
                      "span",
                      [
                        _c("AutoLoad", {
                          ref: "autoLoad",
                          on: {
                            showKey: _vm.showKey,
                            loadFirstData: _vm.loadFirstData,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTransToCustomer,
                                expression: "loadingTransToCustomer",
                              },
                              {
                                name: "dt-permission",
                                rawName: "v-dt-permission",
                                value: "智能获客-推荐线索-转成客户",
                                expression: "'智能获客-推荐线索-转成客户'",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fromPath !==
                                    "/customerpond/customerpond" &&
                                  !_vm.customerInfoMap.inPool,
                                expression:
                                  "\n              fromPath !== '/customerpond/customerpond' &&\n                !customerInfoMap.inPool\n            ",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.transToCustomer },
                          },
                          [_vm._v("\n            转成客户\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fromPath !==
                                    "/customerpond/customerpond" &&
                                  _vm.customerInfoMap.inPool,
                                expression:
                                  "\n              fromPath !== '/customerpond/customerpond' &&\n                customerInfoMap.inPool\n            ",
                              },
                            ],
                            attrs: {
                              type: "primary",
                              size: "small",
                              disabled: !_vm.customerInfoMap.showTransform,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sendKey(3)
                              },
                            },
                          },
                          [_vm._v("\n            发送至\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fromPath === "/customerpond/customerpond",
                                expression:
                                  "fromPath === '/customerpond/customerpond'",
                              },
                            ],
                            attrs: {
                              type: "primary",
                              size: "small",
                              disabled: !_vm.customerInfoMap.showTransform,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sendKey(_vm.activeTabs)
                              },
                            },
                          },
                          [_vm._v("发送至\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.version === "j"
      ? _c(
          "div",
          { staticClass: "j-v", staticStyle: { "background-color": "#fff" } },
          [
            _c(
              "div",
              {
                staticClass: "wai-hu",
                style: {
                  height: _vm.bandWaihuType === "simPhone" ? "100px" : "",
                },
              },
              [_vm._t("waiHu")],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }