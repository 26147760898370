var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model && _vm.userList.length
    ? _c("div", { staticClass: "data-permission" }, [
        _c(
          "div",
          { staticClass: "type-select" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              _vm._l(_vm.scopeType, function (item) {
                return _c(
                  "el-col",
                  { key: item.scope, attrs: { span: 8 } },
                  [
                    _c(
                      "el-radio",
                      {
                        staticClass: "change-radio",
                        attrs: { disabled: _vm.disabled, label: item.scope },
                        on: { change: _vm.handleRadioChange },
                        model: {
                          value: _vm.scope,
                          callback: function ($$v) {
                            _vm.scope = $$v
                          },
                          expression: "scope",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scope === 3,
                expression: "scope === 3",
              },
            ],
            staticClass: "user-select",
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              _vm._l(_vm.userList, function (user) {
                return _c(
                  "el-col",
                  { key: user.id, attrs: { span: 8 } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          disabled:
                            (user.followName && user.followName.length < 8) ||
                            !user.followName,
                          "visible-arrow": false,
                          "popper-class": "popper",
                          effect: "dark",
                          content: user.followName,
                          placement: "bottom",
                        },
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "checkbox-dt",
                            attrs: {
                              checked:
                                user.userId == _vm.userIdList[user.userId],
                              disabled: _vm.disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.change(user)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(user.followName) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }