//一些文案配置
export const config = {
  text: '全量数据请联系产品顾问升级',
  //默认二级菜单展开
  defaultOpen: [
    '0',
    '5',
    '10',
    '20',
    '60',
    '110',
    '140',
    '143',
    '146',
    '150',
    '160',
    '165',
    '180',
    '210',
    '270',
    '280',
    '290',
    '350',
    '380',
    '410',
    '420',
    '4046',
    '4047',
    '4048',
    '4050',
    '4051',
    '4668'
  ],

  menuIcon: {
    10102: 'znhk',
    20: 'khgh',
    30: 'wyxgl',
    50: 'xtgl',
    60: 'khpy',
    1: 'work-table',
    600203: 'wechat-qy',
    5: 'waihu',
    100: 'robot',
    110: 'robot',
    420: 'robot',
    150: 'jxmbsz',
    140: 'xsgcfx',
    40: 'wbscfx',
    143: 'work-table',
    146: 'xspm',
    165: 'khdc',
    160: 'plus-circle',
    170: 'admin',
    230: 'software',
    180: 'yuanqu',
    190: 'qydc',
    220: 'message',
    210: 'mobile',
    240: 'open-api',
    250: 'band',
    260: 'company_group',
    270: 'industry-chart',
    280: 'invest',
    290: 'invest_event',
    300: 'fa_customer',
    310: 'keji',
    330: 'beian',
    340: 'chain',
    2001: 'khgh',
    2002: 's-htgl',
    101: 's-sjfx',
    502: 's-thjl',
    8001: 's-dls',
    2003: 'lianxiren',
    2004: 'chance',
    200107: 'gjjs-n',
    503: 'help-icon',
    504: 'waihu-task',
    2006: 'money',
    12001: 'gys',
    12002: 'cg',
    13001: 'tool-ic',
    5001: 'khpy',
    5002: 'khgh',
    5003: 'zd-n',
    5004: 'js-n',
    5005: 'yhhx-n',
    5006: 'gys',
    3001: 'gys',
    3002: 'waihu-task',
    9001: 'money',
    15501: 'yygll-icon',
    15502: 'yumingicon',
    15503: 'khdc',
    17001: 'transaction',
    170: 'transaction'
  },

  //通过路由控制是否显示
  hideAside: [
    {
      name: 'AI选址',
      route: '/insight/location-ai'
    },
    {
      name: 'AI文案',
      route: '/ai/txt-optimize'
    },
    {
      name: 'AI罗盘',
      route: '/insight/site-ai'
    },
    {
      name: '连锁网络规划',
      route: '/insight/chain-radar'
    },
    {
      name: '地址储备',
      route: '/insight/chain-ad'
    },
    {
      name: '连锁招商',
      route: '/insight/chain-est'
    },
    {
      name: '可视化',
      route: '/insight/data/full-screen'
    }
  ]
}
