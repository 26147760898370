import store from '../store/index'

export default class Permission {
  /**
   * 检查是否拥有指定权限
   * 拥有权限返回 true，否则返回 false
   * @param {string} name 权限名
   * @returns {boolean}
   */
  static hasPermission(name) {
    let permission = store.state.permission.permission
    return permission.indexOf(name) !== -1
  }

  /**
   * 检查是否全部拥有指定权限
   * 全部拥有权限返回 true，有任何一个不拥有的权限返回 false
   * @param {array} list 权限名列表
   * @returns {boolean}
   */
  static hasPermissionAnd(list) {
    let result = true
    let permission = store.state.permission.permission
    for (let index in list) {
      result = result && permission.indexOf(list[index]) !== -1
      if (!result) {
        break
      }
    }
    return result
  }

  /**
   * 检查是否拥有指定权限中的任何一个
   * 拥有 list 中任何一个权限则返回 true,否则返回 false
   * @param list
   * @returns {boolean}
   */
  static hasPermissionOr(list) {
    let result = true
    console.log(store.state.permission)
    let permission = store.state.permission.permission

    for (let index in list) {
      result = permission.indexOf(list[index]) !== -1
      if (result) {
        break
      }
    }
    return result
  }

  /**
   * 权限判断
   * @param {string} condition 校验方式，允许的值有 permission，permissionAnd，permissionOr
   * @param {string | array} permission 权限名或权限名列表
   * @returns {string | array}
   */
  static checkPermission({ condition, permission }) {
    let forbidden = {
      state: false,
      condition: undefined,
      permission: undefined
    }
    if (typeof permission === 'string') {
      if (!Permission.hasPermission(permission)) {
        forbidden = {
          state: true,
          condition: 'permission',
          permission: permission
        }
      }
    }
    if (condition === 'permissionAnd' && typeof permission === 'object') {
      if (!Permission.hasPermissionAnd(permission)) {
        forbidden = {
          state: true,
          condition: 'permissionAnd',
          permission: permission
        }
      }
    }
    if (condition === 'permissionOr' && typeof permission === 'object') {
      console.log(Permission.hasPermissionOr(permission))
      if (!Permission.hasPermissionOr(permission)) {
        forbidden = {
          state: true,
          condition: 'permissionOr',
          permission: permission
        }
      }
    }
    console.log(forbidden, 'forbidden')
    store.commit('update', {
      target: forbidden,
      data: forbidden
    })
    return permission
  }

  /**
   * 刷新权限
   * @returns {undefined|Promise<any>|*|void}
   */
  static refreshPermission() {
    return Permission.checkPermission(store.state.permission.forbidden)
  }
}
