var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.active
        ? "ContactDetail-main-border ContactDetail-main"
        : "ContactDetail-main",
      on: { click: _vm.selectContact },
    },
    [
      _c("div", [
        _c("span", { staticClass: "phone" }, [
          _vm._v("\n      " + _vm._s(_vm.contactData.phone) + "\n    "),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.contactData.contact) + "\n    "),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.contactData.position) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "tag" },
          [
            _vm.contactData.ai_konghao
              ? _c("el-tag", { attrs: { size: "mini" } }, [_vm._v("空号")])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactData.ai_tingji
              ? _c("el-tag", { attrs: { size: "mini" } }, [_vm._v("停机")])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactData.wuxiao
              ? _c("el-tag", { attrs: { size: "mini" } }, [_vm._v("无效")])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactData.zhongjie
              ? _c("el-tag", { attrs: { size: "mini" } }, [_vm._v("疑似中介")])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactData.phoneStatus
              ? _c("el-tag", { attrs: { size: "mini" } }, [
                  _vm._v(_vm._s(_vm.contactData.phoneStatus)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactData.recommend
              ? _c("el-tag", { attrs: { size: "mini" } }, [_vm._v("推荐")])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactData.ai_can_call
              ? _c("el-tag", { attrs: { size: "mini" } }, [
                  _vm._v("电话可打通"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.contactData.callCnt,
                expression: "contactData.callCnt",
              },
            ],
            staticClass: "call-num",
          },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  "popper-class": "el-popper-dt-dt",
                  placement: "bottom-start",
                  width: "500",
                  trigger: "click",
                },
              },
              [
                _vm.contactData.callCnt
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          padding: "8px",
                          color: "#0099cc",
                          cursor: "pointer",
                        },
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          _vm._s("已拨打" + _vm.contactData.callCnt + "次")
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-scrollbar",
                  {
                    staticClass: "dt-dropdown-dt",
                    staticStyle: { height: "500px", width: "100%" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "time-line" },
                      _vm._l(
                        _vm.contactData.callRecords,
                        function (timeLine, index) {
                          return _c(
                            "el-timeline",
                            [
                              _c("div", { staticClass: "line-top" }, [
                                _c("span", [_vm._v(_vm._s(timeLine.week))]),
                                _c("span", [_vm._v(_vm._s(timeLine.day))]),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                timeLine.follows,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: index,
                                      attrs: {
                                        color:
                                          timeLine.week === "今天"
                                            ? "#FF0000"
                                            : "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "line-content" },
                                        [
                                          _c("span", [
                                            _c(
                                              "span",
                                              { staticClass: "import-text" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(activity.account) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "\n                      发起外呼，\n                    "
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _c("span", [
                                              _vm._v(
                                                "\n                      通话状态\n                    "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "import-text" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      activity.phoneStatus
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: activity.callDuration,
                                                  expression:
                                                    "activity.callDuration",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      ，通话时长\n                    "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "import-text" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        activity.callDuration
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        activity.callDuration &&
                                                        activity.ossUrl,
                                                      expression:
                                                        "activity.callDuration && activity.ossUrl",
                                                    },
                                                    {
                                                      name: "dt-permission",
                                                      rawName:
                                                        "v-dt-permission",
                                                      value:
                                                        "智能获客-推荐线索-播放录音",
                                                      expression:
                                                        "'智能获客-推荐线索-播放录音'",
                                                    },
                                                  ],
                                                  staticClass: "play-record",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.playRecord(
                                                        activity.ossUrl
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      播放录音\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "time-line-date" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(activity.time) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }
                      ),
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "icon-click" },
          [
            _c("i", {
              staticClass: "card-phone-icon",
              on: { click: _vm.call },
            }),
            _vm._v(" "),
            _c(
              "el-dropdown",
              {
                attrs: { placement: "bottom-start", trigger: "click" },
                on: {
                  command: function ($event) {
                    return _vm.changeAssign($event, _vm.contactData)
                  },
                },
              },
              [
                _c("i", { staticClass: "card-phone-edit-icon" }),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.assignOption, function (item) {
                    return _c(
                      "el-dropdown-item",
                      { attrs: { command: item.value } },
                      [_vm._v(_vm._s(item.name) + "\n          ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "last" }, [
        _c(
          "div",
          { staticClass: "all-mark" },
          [
            _vm.contactData.phoneMarkList &&
            _vm.contactData.phoneMarkList.length
              ? _c(
                  "el-popover",
                  {
                    attrs: {
                      "popper-class": "el-popper-dt-dt",
                      placement: "bottom-start",
                      width: "600",
                      trigger: "click",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#0099cc",
                          cursor: "pointer",
                          padding: "8px 0",
                        },
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "共" + _vm._s(_vm.contactData.cnt || 0) + "条标记"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-scrollbar",
                      {
                        staticClass: "dt-dropdown-dt",
                        staticStyle: { height: "500px", width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "time-line" },
                          _vm._l(
                            _vm.contactData.phoneMarkList,
                            function (timeLine, index) {
                              return _c(
                                "el-timeline",
                                [
                                  _c("div", { staticClass: "line-top" }, [
                                    _c("span", [_vm._v(_vm._s(timeLine.week))]),
                                    _c("span", [_vm._v(_vm._s(timeLine.day))]),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    timeLine.follows,
                                    function (activity, index) {
                                      return _c(
                                        "el-timeline-item",
                                        {
                                          key: index,
                                          attrs: {
                                            color:
                                              timeLine.week === "今天"
                                                ? "#FF0000"
                                                : "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "line-content" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(activity.markSummary) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "time-line-date" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(activity.time) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            }
                          ),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "from",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.jump()
              },
            },
          },
          _vm._l(_vm.contactData.fromToUrl, function (item) {
            return _c(
              "span",
              {
                staticClass: "l-span",
                staticStyle: { "margin-right": "3px" },
                on: {
                  click: function ($event) {
                    return _vm.jump(item.url)
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      color: item.url && item.url.trim() ? "" : "#8a8a8a",
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item.from) + "\n        ")]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("|")]),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }