<template>
  <div class="send-key-main">
    <div style="padding: 0 30px;box-sizing: border-box">
      <el-form :rules="rule" ref="form" :model="formData" label-width="auto">
        <el-row>
          <el-col :span="24">
            <el-form-item label="发送给" prop="userId">
              <el-select
                style="width: 100%"
                v-model="formData.userId"
                default-first-option
                filterable
                placeholder="选择线索接收人"
              >
                <el-option
                  v-for="item in allSendUser"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- v-if="formData.showCheckBox" -->
            <el-form-item>
              <el-checkbox v-model="checked" @change="handleCheckChange"
                >同步更新合同负责销售</el-checkbox
              >
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="退回理由"
              v-if="formData.userId == '-1' && activeTabs !== 4"
              prop="reason"
            >
              <el-select
                style="width: 100%"
                v-model="formData.reason"
                default-first-option
                filterable
                placeholder="选择线索接收人"
              >
                <el-option
                  v-for="item in allReason"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="postCusTransToOThers"
        :loading="loadStatus"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import { getCusTagsDdic, postCusTransToOThers } from '@/api/customerpond'

export default {
  name: 'sendKey',
  data() {
    return {
      loadStatus: false,
      allSendUser: [],
      allReason: [],
      activeTabs: 1,
      checked: true,
      rule: {
        userId: [
          { required: true, message: '请选择线索接收人', trigger: 'change' }
        ],
        reason: [
          { required: true, message: '请选择退回理由', trigger: 'change' }
        ]
      },
      formData: {
        userId: '',
        reason: '',
        showCheckBox: false
      },
      type: 'keyDetail',
      ids: ''
    }
  },

  mounted() {
    console.log(this.$store.state)
    this.formData.showCheckBox =
      this.$store.state.plugin.modal.options.showCheckBox || false
    this.activeTabs = parseInt(localStorage.getItem('activeTabs') || 1)
    this.allSendUser = this.$store.state.plugin.modal.options.userList
    this.type = this.$store.state.plugin.modal.options.type
    if (this.allSendUser.findIndex(i => i.id === this.user.id) >= 0) {
      this.formData.userId = this.user.id
    }
    if (this.type === 'customerSea') {
      this.ids = this.$store.state.plugin.modal.options.ids
    }
    this.getReturnType()
  },

  computed: {
    customerInfoMap() {
      return this.$store.state.user.customerInfoMap
    },
    user() {
      return this.$store.state.user.accountInfo
    }
  },

  methods: {
    getReturnType() {
      getCusTagsDdic({ type: 7 }).then(res => {
        if (res.code === 60000) {
          this.allReason = res.data
        }
      })
    },
    handleCheckChange(e) {
      this.$store.state.plugin.modal.isChecked = e
    },
    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    postCusTransToOThers() {
      console.log(776655)
      this.$refs['form'].validate(valid => {
        if (valid) {
          const params = {
            ids:
              this.type === 'customerSea' ? this.ids : this.customerInfoMap.id,
            userId: this.formData.userId,
            isUpdateContract: this.checked
          }
          if (this.formData.reason) {
            params['returnType'] = this.formData.reason
          }
          this.loadStatus = true
          postCusTransToOThers(params).then(res => {
            if (res.code === 60000) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: {
                  refresh: true
                }
              })
              this.cancelBtn()
            }
            this.loadStatus = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.send-key-main {
  width: 100%;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
  .footer {
    position: relative;
    padding-top: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
  }
  .footer::before {
    content: '';
    display: inline-block;
    position: absolute;
    height: 1px;
    width: calc(100% + 40px);
    top: 0;
    left: -20px;
    background-color: rgba(0, 0, 0, 0.1);
    transform: scaleY(0.5);
  }
  >>> .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #fff !important;
    border-color: #000;

    &::after {
      border-color: #000;
    }
  }
  >>> .el-checkbox__label {
    color: #000 !important;
  }
}
</style>
