import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 通话记录
export const callHistoryList = async params => {
  const api = API('CALLHISTORYLLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}
//高级筛选
export const getCallHeiOption = async params => {
  const api = API('GETCALLHEIOPTION')
  return await BASE_REQUEST({ api: api, opt: params })
}
//表格筛选
export const getCallOption = async params => {
  const api = API('GETCALLOPTION')
  return await BASE_REQUEST({ api: api, opt: params })
}
//导出通话记录
export const exportCallHistory = async params => {
  const api = API('EXPORTCALLHISTORY')
  return await BASE_REQUEST({ api: api, opt: params })
}
//申请开通外呼
export const applyOpenwaihu = async params => {
  const api = API('APPLYOPENWAIHU')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const newPhoneStatus = async params => {
  const api = API('newPhoneStatus')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const newPhoneCall = async params => {
  const api = API('newPhoneCall')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const newPhoneHangUp = async params => {
  const api = API('newPhoneHangUp')
  return await BASE_REQUEST({ api: api, opt: params })
}
