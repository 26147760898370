// 配置项目websocket
// 定义websocket

import { MessageBox } from 'element-ui'
import { getAccountInfo } from '@/api/usercenter'
var websocket = null
var closeType = true
var heart = null

var ws = 'ws://47.103.196.71:8091/webSocket/token='
// var ws = 'ws://192.168.13.133:8086/webSocket/token=' + socToken
import store from '@/store'
import router from '@/router'
export function AppWebSockt(val) {
  if (process.env.VUE_APP_BASE_API === 'https://api.dtbird.cn') {
    ws = 'wss://ws.dtbird.cn/webSocket/token='
  } else {
    if (location.href.indexOf('https') !== -1) {
      ws = 'wss://139.224.72.178:8091/webSocket/token='
    } else {
      ws = 'ws://139.224.72.178:8091/webSocket/token='
    }
  }

  // var socToken = localStorage.getItem('dtBird_token')
  var socToken = store.state.permission.token
  if (socToken) {
    socToken = socToken.replace(/["]/g, '')
  }

  if (!socToken) {
    return
  }
  if ('WebSocket' in window) {
    websocket = new WebSocket(ws + socToken)
  } else {
    console.log('浏览器不支持websocket')
    return
  }

  websocket.onopen = function() {
    // 链接成功
    heartTest()
    if (val) {
      sendWebsocket(val)
    }
  }

  websocket.onerror = function(res) {
    clearInterval(heart)
    // 链接失败
    if (websocket.readyState === 3) {
      const token = store.state.permission.token
      if (!token) {
        return
      }
      if (!closeType) {
        return
      }
      websocket = new WebSocket(ws)
    }
  }

  websocket.onmessage = function(e) {
    // 接受服务端信息
    websocketOnMessage(e)
  }

  websocket.onclose = function() {
    // 关闭链接
    // ----------
  }

  window.onbeforeunload = function() {
    websocket.close()
  }
}

export function sendWebsocket(data) {
  websocket.send(data)
}

export function closeWebsocket() {
  if (websocket) {
    websocket.close()
  }
}

export function websocketOnMessage(e) {
  // console.log(e.data)

  if (e.data.indexOf('{') === 0) {
    const message = JSON.parse(e.data)
    localStorage.setItem('messagePush', e.data)
    store.commit('update', {
      target: store.state.permission.messageNum,
      data: message
    })
  }

  if (e.data === 'kick_user_offline') {
    closeType = false
    websocket.close()
    store.dispatch('hideModal')
    store.commit('logout')
    MessageBox({
      title: '提示',
      message: '当前账号在其他设备登录',
      type: 'warning',
      showConfirmButton: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      callback: () => {
        router.replace('/login')
      }
    })
  }
  if (e.data === 'refresh_user_account') {
    getUserAccount()
  }
}

// 刷新用户信息
function getUserAccount() {
  getAccountInfo({}).then(res => {
    if (res.code === 60000) {
      store.dispatch('setAccountInfo', res.data)
    }
  })
}

function heartTest() {
  heart = setInterval(() => {
    if (websocket.readyState === 3 || websocket.readyState === 4) {
      clearInterval(heart)
      return
    }
    websocket.send('heart')
  }, 18000)
}
