var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "h-div" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "el-breadcrumb",
              { attrs: { "separator-class": "el-icon-arrow-right" } },
              [
                _c(
                  "el-breadcrumb-item",
                  { attrs: { to: "/permission/permission" } },
                  [_vm._v("角色和权限")]
                ),
                _vm._v(" "),
                _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.type))]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticStyle: { "margin-top": "20px" },
                attrs: { model: _vm.form, inline: true, size: "small" },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "角色名",
                      prop: "permissionName",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "text",
                        disabled: _vm.type === "详细",
                        placeholder: "请输入角色名",
                      },
                      model: {
                        value: _vm.form.permissionName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "permissionName", $$v)
                        },
                        expression: "form.permissionName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "权限设置", rules: _vm.rules } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.handleSelectChange },
                        model: {
                          value: _vm.permissionValue,
                          callback: function ($$v) {
                            _vm.permissionValue = $$v
                          },
                          expression: "permissionValue",
                        },
                      },
                      _vm._l(_vm.permissionOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "permission" }, [
          _c(
            "div",
            { staticClass: "permission-content" },
            [
              _c("update-permission", {
                attrs: {
                  "permission-obj": _vm.permissionList,
                  "update-data": _vm.updatePermission,
                  "permission-value": _vm.permissionValue,
                  "is-forbid": _vm.type === "详细",
                },
                on: { update: _vm.permissionUpdate },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }