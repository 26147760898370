var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showMsg,
            expression: "showMsg",
          },
        ],
        staticClass: "msg",
      },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          _vm._l(_vm.msgList, function (item) {
            return _c(
              "el-col",
              {
                key: item.key,
                staticStyle: { "margin-bottom": "10px" },
                attrs: { span: 12 },
              },
              [
                _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v(_vm._s(item.name) + ":"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.row[item.key] || item.defaultValue)),
                ]),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "home" },
      [
        _c("vue-audio-native", {
          attrs: {
            size: "default",
            url: _vm.url,
            showCurrentTime: _vm.showCurrentTime,
            showControls: _vm.showControls,
            showVolume: _vm.showVolume,
            showDownload: _vm.showDownload,
            autoplay: _vm.autoplay,
            hint: _vm.hint,
            waitBuffer: _vm.waitBuffer,
            downloadName: _vm.downloadName,
          },
          on: {
            "on-change": _vm.change,
            "on-timeupdate": _vm.timeupdate,
            "on-metadata": _vm.metadata,
            "on-audioId": _vm.audioId,
            "on-end": _vm.end,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.mp3download },
          },
          [_vm._v("\n      下载录音\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }