import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import permission from './modules/permission'
import plugin from './modules/plugin'
import phoneSocket from '@/store/modules/phoneSocket'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    permission,
    plugin, // 提示组件
    phoneSocket
  },
  getters
})

export default store
