import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { isObjNull } from '@/utils/validate'

const state = {
  plate: false,
  isReceive: false, //是否领取
  isScrm: localStorage.getItem('isScrm') === 'true',
  plateVersion: 'b',
  token: getToken(),
  name: localStorage.getItem('userName') || '',
  avatar: '',
  currHsearch: false,
  registStep: localStorage.getItem('dtBird_user_step') || '88',
  buyMeal: false,
  buyMealType: '1',
  InvalidLogin: false,
  accountType: localStorage.getItem('accountType')
    ? parseInt(localStorage.getItem('accountType'))
    : 0,
  goBuyMeal: false,
  menus: localStorage.getItem('authMenus')
    ? JSON.parse(localStorage.getItem('authMenus'))
    : [],
  sub: localStorage.getItem('authSub') === 'true',
  showExportLoad: false,
  showGuide: localStorage.getItem('showGuide'),
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {},
  accountInfo: localStorage.getItem('accountInfo')
    ? JSON.parse(localStorage.getItem('accountInfo'))
    : {},
  isAdmin: localStorage.getItem('isAdmin')
    ? parseInt(localStorage.getItem('isAdmin'))
    : 0,
  sysConfig: localStorage.getItem('sysConfig')
    ? JSON.parse(localStorage.getItem('sysConfig'))
    : {},
  callStatus: '空闲',
  callStartTime: '',
  callNum: '',
  pageId: localStorage.getItem('pageId') || null,
  customerInfoMap: {
    inPool: false,
    canFollow: false,
    id: '',
    desc: '客户当前位置：客户公海-可跟进的'
  }
}

const mutations = {
  SET_SYSCONFIG: (state, sysConfig) => {
    Object.assign(state.sysConfig, {}, sysConfig)
  },
  SET_EXPORTLOAD: (state, showExportLoad) => {
    state.showExportLoad = showExportLoad
  },
  SET_SHOWGUIDE: (state, showGuide) => {
    state.showGuide = showGuide
  },
  SET_ACCSUB: (state, sub) => {
    state.sub = sub
  },
  SET_AUTHMENUS: (state, menus) => {
    state.menus = menus
  },
  SET_GOBUYMEAL: (state, goBuyMeal) => {
    state.goBuyMeal = goBuyMeal
  },
  SET_INVALIDLOGIN: (state, InvalidLogin) => {
    state.InvalidLogin = InvalidLogin
  },
  SET_ACCOUNTTYPE: (state, accountType) => {
    state.accountType = accountType
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_CURRHSEARCH: (state, currHsearch) => {
    state.currHsearch = currHsearch
  },
  SET_GRGISTSTEP: (state, registStep) => {
    state.registStep = registStep
  },
  SET_BUYMEAL: (state, buyMeal) => {
    state.buyMeal = buyMeal
  },
  SET_BUYMEALTYPE: (state, buyMealType) => {
    state.buyMealType = buyMealType
  },
  SET_USERINFO: (state, userInfo) => {
    Object.assign(state.userInfo, {}, userInfo)
  },
  SET_ACCOUNTINFO: (state, accountInfo) => {
    Object.assign(state.accountInfo, {}, accountInfo)
  },
  clearAccount: (state, accountInfo) => {
    state.accountInfo = {}
  },
  SET_ISADMIN: (state, isAdmin) => {
    state.isAdmin = isAdmin
  },
  SET_CALLSTATUS: (state, callStatus) => {
    state.callStatus = callStatus
  },
  SET_CALLSTARTTIME: (state, callStartTime) => {
    state.callStartTime = callStartTime
  },
  SET_CALLNUM: (state, callNum) => {
    state.callNum = callNum
  },
  SET_CUSTOMERINFOMAP: (state, callNum) => {
    state.customerInfoMap = callNum
  }
}

const actions = {
  setName({ commit }, name) {
    if (name) {
      localStorage.setItem('userName', name)
    }
    commit('SET_NAME', name)
  },
  setUserLoginOut({ commit }, InvalidLogin) {
    commit('SET_INVALIDLOGIN', InvalidLogin)
  },
  setSysConfig({ commit }, sysConfig) {
    if (isObjNull(sysConfig)) {
      localStorage.setItem('sysConfig', JSON.stringify(sysConfig))
    }
    commit('SET_SYSCONFIG', sysConfig)
  },
  setUserInfo({ commit }, userInfo) {
    if (isObjNull(userInfo)) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    }
    commit('SET_USERINFO', userInfo)
  },
  setAccountInfo({ commit }, accountInfo) {
    if (isObjNull(accountInfo)) {
      localStorage.setItem('accountInfo', JSON.stringify(accountInfo))
    }
    commit('SET_ACCOUNTINFO', accountInfo)
  },
  setIsAdmin({ commit }, isAdmin) {
    localStorage.setItem('isAdmin', isAdmin)
    commit('SET_ISADMIN', isAdmin)
  },
  setShowGuide({ commit }, showGuide) {
    localStorage.setItem('showGuide', showGuide)
    commit('SET_SHOWGUIDE', showGuide)
  },
  setExportLoad({ commit }, showExportLoad) {
    commit('SET_EXPORTLOAD', showExportLoad)
  },
  setAccSub({ commit }, sub) {
    localStorage.setItem('authSub', sub)
    commit('SET_ACCSUB', sub)
  },
  setAuthMenus({ commit }, menus) {
    if (menus) {
      localStorage.setItem('authMenus', JSON.stringify(menus))
    }
    commit('SET_AUTHMENUS', menus)
  },
  setGoBuyMeal({ commit }, goBuyMeal) {
    commit('SET_GOBUYMEAL', goBuyMeal)
  },
  setAccountType({ commit }, accountType) {
    localStorage.setItem('accountType', accountType)
    commit('SET_ACCOUNTTYPE', accountType)
  },
  changeUserBuyType({ commit }, buyMealType) {
    commit('SET_BUYMEALTYPE', buyMealType)
  },
  changeUserBuy({ commit }, buyMeal) {
    commit('SET_BUYMEAL', buyMeal)
  },
  changeHeardSearch({ commit }, currHsearch) {
    commit('SET_CURRHSEARCH', currHsearch)
  },
  changeStep({ commit }, registStep) {
    commit('SET_GRGISTSTEP', registStep)
  },
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then(response => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          const { data } = response

          if (!data) {
            reject('Verification failed, please Login again.')
          }

          const { name, avatar } = data

          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
          localStorage.setItem('userName', name)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit('SET_TOKEN', '')
          removeToken()
          resetRouter()
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  // namespaced: true,
  state,
  mutations,
  actions
}
