var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "view-div",
    },
    [
      _c("permission", {
        key: _vm.loading,
        attrs: {
          name: _vm.name,
          "permission-list": _vm.updatePermission,
          "update-permission": _vm.updatePermission,
          type: "详细",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }