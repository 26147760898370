var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chanceList-main" }, [
    _c(
      "div",
      [
        _c(
          "el-row",
          { attrs: { gutter: 20, align: "middle" } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _vm._v(
                "\n        创建人：" +
                  _vm._s(_vm.chanceData.createFollowName) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 12 } }, [
              _vm._v(
                "\n        签单销售：" +
                  _vm._s(_vm.chanceData.followName) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-row",
          { attrs: { gutter: 20, align: "middle" } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _vm._v(
                "\n        客户联系人：" +
                  _vm._s(_vm.chanceData.billKeypersonDesc || "") +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 12 } }, [
              _vm._v(
                "\n        预估演示时间：" +
                  _vm._s(_vm.chanceData.productShowDate) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }