var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "div",
    },
    [
      _c(
        "el-row",
        { ref: "row", staticClass: "header border" },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "text" }, [_vm._v("一级菜单栏")]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "text border" }, [
                      _vm._v("二级菜单栏"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 16 } }, [
                            _c("div", { staticClass: "text border" }, [
                              _vm._v("功能权限"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "text border" }, [
                              _vm._v("数据权限"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.permissionList, function (item) {
          return _c(
            "el-row",
            {
              key: item.id,
              staticClass: "menu",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c(
                "el-col",
                { staticClass: "menu-item text", attrs: { span: 4 } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: _vm.isForbid },
                      on: {
                        change: function ($event) {
                          return _vm.change("menu", item.checked, item.id)
                        },
                      },
                      model: {
                        value: item.checked,
                        callback: function ($$v) {
                          _vm.$set(item, "checked", $$v)
                        },
                        expression: "item.checked",
                      },
                    },
                    [_vm._v(_vm._s(item.name) + "\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-row",
                    { staticClass: "page-pre" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        _vm._l(item["pages"], function (page) {
                          return _c(
                            "el-row",
                            {
                              key: page.id,
                              staticClass: "page",
                              attrs: { type: "flex", align: "middle" },
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "text", attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: _vm.isForbid },
                                      on: {
                                        change: function ($event) {
                                          return _vm.change(
                                            "page",
                                            page.checked,
                                            page.id
                                          )
                                        },
                                      },
                                      model: {
                                        value: page.checked,
                                        callback: function ($$v) {
                                          _vm.$set(page, "checked", $$v)
                                        },
                                        expression: "page.checked",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(page.name) + "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticClass: "func-pre",
                                      attrs: { type: "flex", align: "middle" },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "data-permission text",
                                          attrs: { span: 16 },
                                        },
                                        _vm._l(
                                          page["functions"],
                                          function (func) {
                                            return _c(
                                              "el-col",
                                              {
                                                key: func.id,
                                                staticClass: "func",
                                                attrs: { span: 8 },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      disabled: _vm.isForbid,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.change(
                                                          "func",
                                                          func.checked,
                                                          func.id,
                                                          {
                                                            item: item,
                                                            func: func,
                                                          }
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: func.checked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          func,
                                                          "checked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "func.checked",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(func.name) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c("data-permission", {
                                            ref: "permission" + page.id,
                                            refInFor: true,
                                            attrs: {
                                              "parent-id": item.id,
                                              disabled: _vm.isForbid,
                                              "user-list": _vm.userList,
                                              "data-model":
                                                page["dataPermissionModel"],
                                              "page-id": page.id,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "save" }, [
        !_vm.isForbid
          ? _c("div", { staticClass: "btn", on: { click: _vm.save } }, [
              _vm._v("保存"),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }