//新加模块接口

import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

//行业图谱企业成员
export const schemaMemberList = async params => {
  const api = API('schemaMemberList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//行业图谱详情
export const schemaDetail = async params => {
  const api = API('schemaDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

//行业图谱详情
export const schemaDetailCount = async params => {
  const api = API('schemaDetailCount')
  return await BASE_REQUEST({ api: api, opt: params })
}

//行业图谱首页
export const schemaIndexData = async params => {
  const api = API('schemaIndexData')
  return await BASE_REQUEST({ api: api, opt: params })
}

//行业图谱首页分类
export const schemaIndexItem = async params => {
  const api = API('schemaIndexItem')
  return await BASE_REQUEST({ api: api, opt: params })
}

//上市企业config
export const marketConfig = async params => {
  const api = API('marketConfig')
  return await BASE_REQUEST({ api: api, opt: params })
}

//数据图表
export const dataDiagram = async params => {
  const api = API('dataDiagram')
  return await BASE_REQUEST({ api: api, opt: params })
}

//行业图谱
export const investRecord = async params => {
  const api = API('investRecord')
  return await BASE_REQUEST({ api: api, opt: params })
}

//投资机构
export const investGroupList = async params => {
  const api = API('investGroupList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//投资机构详情
export const investGroupDetail = async params => {
  const api = API('investGroupDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 投资机构筛选
export const investDetailFilter = async params => {
  const api = API('investDetailFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 投资机构详情列表
export const investDetailList = async params => {
  const api = API('investDetailList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 配置路由跳转
export const getRouterUrl = async params => {
  const api = API('getRouterUrl')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 投资事件详情
export const getInvestEventInfo = async params => {
  const api = API('getInvestEventInfo')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 投资事件筛选
export const getInvestEventFilter = async params => {
  const api = API('getInvestEventFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 投资事件图表
export const getInvestEventChart = async params => {
  const api = API('getInvestEventChart')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 投资事件列表
export const getInvestEventList = async params => {
  const api = API('getInvestEventList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// FA筛选
export const getFaFilter = async params => {
  const api = API('getFaFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

// FA列表
export const getFaList = async params => {
  const api = API('getFaList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查找父节点
export const getFatherNode = async params => {
  const api = API('getFatherNode')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成订单
export const getChartOrderNo = async params => {
  const api = API('getChartOrderNo')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 虚拟币生成订单
export const getVirtualOrderNo = async params => {
  const api = API('getVirtualOrderNo')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 图普洞察
export const getChartInsight = async params => {
  const api = API('getChartInsight')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 云列表
export const getCloudList = async params => {
  const api = API('getCloudList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 域名监测
export const getNetCheck = async params => {
  const api = API('getNetCheck')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 算力监测
export const getMapCheck = async params => {
  const api = API('getMapCheck')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 刷新数字
export const getRefreshCount = async params => {
  const api = API('getRefreshCount')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 云logo
export const getCloudLogo = async params => {
  const api = API('getCloudLogo')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 域名列表
export const getNewCloudList = async params => {
  const api = API('getNewCloudList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 域名刷新
export const getDomainRefresh = async params => {
  const api = API('getDomainRefresh')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 门店数据
export const getStoreData = async params => {
  const api = API('getStoreData')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 门店开业
export const getStoreOpen = async params => {
  const api = API('getStoreOpen')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 门店关业
export const getStoreClose = async params => {
  const api = API('getStoreClose')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 门店营业
export const getStoreBusiness = async params => {
  const api = API('getStoreBusiness')
  return await BASE_REQUEST({ api: api, opt: params })
}

//出海企业
export const getOverSeaData = async params => {
  const api = API('getOverSeaData')
  return await BASE_REQUEST({ api: api, opt: params })
}

//商圈信息
export const getDistrictData = async params => {
  const api = API('getDistrictData')
  return await BASE_REQUEST({ api: api, opt: params })
}

//族群门店
export const groupListStore = async params => {
  const api = API('groupListStore')
  return await BASE_REQUEST({ api: api, opt: params })
}

//连锁雷达菜单
export const getChainRadarMenu = async params => {
  const api = API('getChainRadarMenu')
  return await BASE_REQUEST({ api: api, opt: params })
}
//连锁雷达列表
export const getChainRadarList = async params => {
  const api = API('getChainRadarList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//门店品牌 城市下级分类
export const getCityLevel = async params => {
  const api = API('getCityLevel')
  return await BASE_REQUEST({ api: api, opt: params })
}

//新闻舆论
export const getNewsList = async params => {
  const api = API('getNewsList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//新闻舆论筛选
export const getNewsListFilter = async params => {
  const api = API('getNewsListFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

//新闻舆论筛选
export const getNewsListRefresh = async params => {
  const api = API('getNewsListRefresh')
  return await BASE_REQUEST({ api: api, opt: params })
}

//品牌打假
export const getBrandFalse = async params => {
  const api = API('getBrandFalse')
  return await BASE_REQUEST({ api: api, opt: params })
}

//新闻舆情图表
export const getBrandCharts = async params => {
  const api = API('getBrandCharts')
  return await BASE_REQUEST({ api: api, opt: params })
}

//品牌打假
export const getBrandDetail = async params => {
  const api = API('getBrandDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

//人气商场
export const getShopCircle = async params => {
  const api = API('getShopCircle')
  return await BASE_REQUEST({ api: api, opt: params })
}

//城市下拉
export const getCitySelect = async params => {
  const api = API('getCitySelect')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址
export const getAiCircle = async params => {
  const api = API('getAiCircle')
  return await BASE_REQUEST({ api: api, opt: params })
}

//连锁地域排名
export const getAreaRank = async params => {
  const api = API('getAreaRank')
  return await BASE_REQUEST({ api: api, opt: params })
}

//连锁城市排名
export const getCityRank = async params => {
  const api = API('getCityRank')
  return await BASE_REQUEST({ api: api, opt: params })
}

//连锁详情
export const getRankDetail = async params => {
  const api = API('getRankDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

//品牌相关联
export const getBrandLink = async params => {
  const api = API('getBrandLink')
  return await BASE_REQUEST({ api: api, opt: params })
}

//品牌相关联
export const getBrandFilter = async params => {
  const api = API('getBrandFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

//品牌相关计算数量
export const getBrandTotal = async params => {
  const api = API('getBrandTotal')
  return await BASE_REQUEST({ api: api, opt: params })
}

//微信扫码异常
export const getWxScanError = async params => {
  const api = API('getWxScanError')
  return await BASE_REQUEST({ api: api, opt: params })
}

//门店合集
export const getStoreTotal = async params => {
  const api = API('getStoreTotal')
  return await BASE_REQUEST({ api: api, opt: params })
}

//楼层合集
export const getStoreFloor = async params => {
  const api = API('getStoreFloor')
  return await BASE_REQUEST({ api: api, opt: params })
}

//楼层门店合集
export const getFloorList = async params => {
  const api = API('getFloorList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//消费全景
export const getConsumeList = async params => {
  const api = API('getConsumeList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//消费全景地图
export const getConsumeMap = async params => {
  const api = API('getConsumeMap')
  return await BASE_REQUEST({ api: api, opt: params })
}

//热力地图
export const getConsumeHot = async params => {
  const api = API('getConsumeHot')
  return await BASE_REQUEST({ api: api, opt: params })
}

//推荐选址
export const getConfigMenu = async params => {
  const api = API('getConfigMenu')
  return await BASE_REQUEST({ api: api, opt: params })
}

//点位附近
export const getPointNearby = async params => {
  const api = API('getPointNearby')
  return await BASE_REQUEST({ api: api, opt: params })
}

//线路附近
export const getLineNearby = async params => {
  const api = API('getLineNearby')
  return await BASE_REQUEST({ api: api, opt: params })
}

//二级菜单
export const getChildMenu = async params => {
  const api = API('getChildMenu')
  return await BASE_REQUEST({ api: api, opt: params })
}

//点位地图
export const getPointMapData = async params => {
  const api = API('getPointMapData')
  return await BASE_REQUEST({ api: api, opt: params })
}

//热力地图
export const getHotMapData = async params => {
  const api = API('getHotMapData')
  return await BASE_REQUEST({ api: api, opt: params })
}

//风水盘详情
export const getGeomancyDetail = async params => {
  const api = API('getGeomancyDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

//风水盘历史
export const getGeomancyHistory = async params => {
  const api = API('getGeomancyHistory')
  return await BASE_REQUEST({ api: api, opt: params })
}

//指定半径人数
export const getRangeHot = async params => {
  const api = API('getRangeHot')
  return await BASE_REQUEST({ api: api, opt: params })
}

//性别饼图
export const getSexChart = async params => {
  const api = API('getSexChart')
  return await BASE_REQUEST({ api: api, opt: params })
}

//商铺信息
export const getShopInfo = async params => {
  const api = API('getShopInfo')
  return await BASE_REQUEST({ api: api, opt: params })
}

//点位配置
export const getPointConfig = async params => {
  const api = API('getPointConfig')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址三级联动
export const getAddressNew = async params => {
  const api = API('getAddressNew')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址行业分类
export const getIndustryClassify = async params => {
  const api = API('getIndustryClassify')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址
export const getAddrSure = async params => {
  const api = API('getAddrSure')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址 默认城市区域
export const getDefaultAddr = async params => {
  const api = API('getDefaultAddr')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址 记录区域
export const getRecordAddr = async params => {
  const api = API('getRecordAddr')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai选址 楼层
export const getAiFloor = async params => {
  const api = API('getAiFloor')
  return await BASE_REQUEST({ api: api, opt: params })
}

//扫码
export const getPcQrCode = async params => {
  const api = API('getPcQrCode')
  return await BASE_REQUEST({ api: api, opt: params })
}

//竞争跟随品牌
export const getComReMapList = async params => {
  const api = API('getComReMapList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai我的文案列表
export const getAiMyList = async params => {
  const api = API('getAiMyList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai多轮对话列表
export const getMassAiList = async params => {
  const api = API('getMassAiList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai生成列表
export const getAiCreated = async params => {
  const api = API('getAiCreated')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai保存列表
export const getAiSaved = async params => {
  const api = API('getAiSaved')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai列表删除
export const getAiDelete = async params => {
  const api = API('getAiDelete')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai列表删除
export const getAiSelectAddress = async params => {
  const api = API('getAiSelectAddress')
  return await BASE_REQUEST({ api: api, opt: params })
}

//ai打字机
export const getAiPrintResult = async params => {
  const api = API('getAiPrintResult')
  return await BASE_REQUEST({ api: api, opt: params })
}

//计算人口
export const getPeopleCount = async params => {
  const api = API('getPeopleCount')
  return await BASE_REQUEST({ api: api, opt: params })
}

//追随品牌
export const getSimilarBrandList = async params => {
  const api = API('getSimilarBrandList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//追随品牌列表
export const getRecommendBrand = async params => {
  const api = API('getRecommendBrand')
  return await BASE_REQUEST({ api, opt: params })
}

//招商城市选择
export const getInvestCitySelect = async params => {
  const api = API('getInvestCitySelect')
  return await BASE_REQUEST({ api, opt: params })
}

//招商地图数据
export const getInvestData = async params => {
  const api = API('getInvestData')
  return await BASE_REQUEST({ api, opt: params })
}

//招商地图数据
export const getInvestDetailData = async params => {
  const api = API('getInvestDetailData')
  return await BASE_REQUEST({ api, opt: params })
}

//查看经营状态
export const getBusinessInvestStatus = async params => {
  const api = API('getBusinessInvestStatus')
  return await BASE_REQUEST({ api, opt: params })
}

//配置经营状态
export const getConfigInvestStatus = async params => {
  const api = API('getConfigInvestStatus')
  return await BASE_REQUEST({ api, opt: params })
}

//设置经营状态
export const getSetInvestStatus = async params => {
  const api = API('getSetInvestStatus')
  return await BASE_REQUEST({ api, opt: params })
}

//企业列表监测
export const getMonitorList = async params => {
  const api = API('getMonitorList')
  return await BASE_REQUEST({ api, opt: params })
}

//企业标签列表
export const getQyTagFilter = async params => {
  const api = API('getQyTagFilter')
  return await BASE_REQUEST({ api, opt: params })
}

//企业标签列表
export const getModuleConfig = async params => {
  const api = API('getModuleConfig')
  return await BASE_REQUEST({ api, opt: params })
}

//行业政策列表
export const getPolicyList = async params => {
  const api = API('getPolicyList')
  return await BASE_REQUEST({ api, opt: params })
}

//政策详情
export const getPolicyDetail = async params => {
  const api = API('getPolicyDetail')
  return await BASE_REQUEST({ api, opt: params })
}

//园区列表
export const getYqList = async params => {
  const api = API('getYqList')
  return await BASE_REQUEST({ api, opt: params })
}

//园区标签
export const getYqTags = async params => {
  const api = API('getYqTags')
  return await BASE_REQUEST({ api, opt: params })
}

//政策词云
export const getPolicyCloud = async params => {
  const api = API('getPolicyCloud')
  return await BASE_REQUEST({ api, opt: params })
}

//大屏列表
export const getScreenConfig = async params => {
  const api = API('getScreenConfig')
  return await BASE_REQUEST({ api, opt: params })
}

//获取卡片数据
export const getScreenData = async params => {
  const api = API('getScreenData')
  return await BASE_REQUEST({ api, opt: params })
}

//获取卡片数据
export const getScreenTagTree = async params => {
  const api = API('getScreenTagTree')
  return await BASE_REQUEST({ api, opt: params })
}

//获取卡片数据
export const getVisualList = async params => {
  const api = API('getVisualList')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化作品
export const getVisualWorks = async params => {
  const api = API('getVisualWorks')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化组件
export const getVisualComponents = async params => {
  const api = API('getVisualComponents')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化作品详情
export const getVisualWorksDetail = async params => {
  const api = API('getVisualWorksDetail')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化作品测试接口
export const getVisualTest = async params => {
  const api = API('getVisualTest')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化树状图
export const getVisualTree = async params => {
  const api = API('getVisualTree')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化标签树状图
export const getPackageTag = async params => {
  const api = API('getPackageTag')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化标签echarts
export const getScreenTagChart = async params => {
  const api = API('getScreenTagChart')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化 融资历史
export const getScreenFinanceList = async params => {
  const api = API('getScreenFinanceList')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化 自定义配置
export const getScreenEdit = async params => {
  const api = API('getScreenEdit')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化 单个卡片详情
export const getScreenCardDetail = async params => {
  const api = API('getScreenCardDetail')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化 模板
export const getVisualTemplate = async params => {
  const api = API('getVisualTemplate')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化 新建模板
export const getTempAddScreen = async params => {
  const api = API('getTempAddScreen')
  return await BASE_REQUEST({ api, opt: params })
}

//可视化 创建模板
export const getTempCreateScreen = async params => {
  const api = API('getTempCreateScreen')
  return await BASE_REQUEST({ api, opt: params })
}
