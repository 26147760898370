var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-body" },
    [
      _c(
        "el-container",
        [
          !_vm.isFullScreen
            ? _c(
                "el-header",
                { ref: "dtHeader" },
                [
                  _c("div", { staticClass: "header-fl" }, [
                    _c(
                      "div",
                      {
                        staticClass: "l-logo",
                        on: { click: _vm.handleCompany },
                      },
                      [_c("div", { class: _vm.handleLogoLogic })]
                    ),
                    _vm._v(" "),
                    !_vm.isFullScreen
                      ? _c("div", { ref: "header", staticClass: "ll-logo" }, [
                          _c(
                            "div",
                            { staticClass: "new-page" },
                            [
                              _vm._l(_vm.headerList, function (item, index) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: index,
                                      class: _vm.routeClass(item),
                                      on: {
                                        click: function ($event) {
                                          return _vm.goPage(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm.insightMsg && item.name === "智能决策"
                                        ? _c(
                                            "span",
                                            { staticClass: "datMsg" },
                                            [_vm._v(_vm._s(_vm.insightMsg))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "open-tip" }, [
                                        _vm._v(_vm._s(item.tip)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v(_vm._s(item.name))]),
                                    ]
                                  ),
                                ]
                              }),
                              _vm._v(" "),
                              !_vm.isScrm && _vm.moreRoute.length
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        width: "300",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "s-page",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v("\n                更多 "),
                                          _c("i", {
                                            staticClass: "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            height: "300px",
                                            overflow: "auto",
                                          },
                                        },
                                        _vm._l(
                                          _vm.moreRoute,
                                          function (item, index) {
                                            return _c(
                                              "el-row",
                                              { key: index },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        class:
                                                          _vm.routeClassMore(
                                                            item
                                                          ),
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.goPage(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "10px",
                                                            "font-size": "20px",
                                                            "margin-bottom":
                                                              "5px",
                                                          },
                                                          attrs: {
                                                            "icon-class":
                                                              item.icon,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.permissionList.includes("智能获客-企业查询")
                            ? _c(
                                "div",
                                { staticClass: "global-search" },
                                [
                                  _c(
                                    "el-autocomplete",
                                    {
                                      attrs: {
                                        "fetch-suggestions":
                                          _vm.querySearchAsync,
                                        size: "small",
                                        clearable: "",
                                        placeholder: "搜企业",
                                      },
                                      on: {
                                        focus: _vm.mouseFocus,
                                        change: _vm.kongInput,
                                        blur: _vm.mouseBlur,
                                        select: _vm.handleSelectSugg,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("div", {
                                                  staticClass: "name",
                                                  domProps: {
                                                    innerHTML: _vm._s(item),
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2873645791
                                      ),
                                      model: {
                                        value: _vm.searchInput,
                                        callback: function ($$v) {
                                          _vm.searchInput = $$v
                                        },
                                        expression: "searchInput",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-input__icon el-icon-search",
                                        attrs: { slot: "prefix" },
                                        slot: "prefix",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "search-icon",
                                    attrs: {
                                      src: require("../../assets/search-btn.png"),
                                    },
                                    on: { click: _vm.kongInput },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "call-system" },
                            [_vm.isWaihu == "1" ? _c("phone-strip") : _vm._e()],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      ref: "popover",
                      attrs: {
                        "popper-class": "el-popper-dt-dt",
                        placement: "top-start",
                        trigger: "click",
                      },
                      model: {
                        value: _vm.chooseShow,
                        callback: function ($$v) {
                          _vm.chooseShow = $$v
                        },
                        expression: "chooseShow",
                      },
                    },
                    [
                      _c("div", { staticClass: "new-logout" }, [
                        _c(
                          "div",
                          {
                            staticClass: "center-u",
                            on: {
                              click: function ($event) {
                                return _vm.goPage(_vm.sysConfig)
                              },
                            },
                          },
                          [_vm._v("\n            个人中心\n          ")]
                        ),
                        _vm._v(" "),
                        _vm.showPhone
                          ? _c("div", { on: { click: _vm.connectPhone } }, [
                              _vm._v("\n            重新连接话机\n          "),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.env !== "tt" && _vm.env !== "dd"
                          ? _c("div", { on: { click: _vm.logout } }, [
                              _vm._v("\n            退出登录\n          "),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "n-i",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("div", { staticClass: "version-desc" }, [
                            _c("div", [_vm._v(_vm._s(_vm.userName))]),
                            _vm._v(" "),
                            _c("div", [
                              _vm.versionName
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(_vm.versionName)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.selectMode))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-avatar", {
                                staticStyle: { height: "35px", width: "35px" },
                                attrs: { src: _vm.headerImg },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-container",
            [
              !_vm.isFullScreen
                ? _c(
                    "el-aside",
                    {
                      ref: "dtAside",
                      style: _vm.open ? "width:180px" : "width:auto",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bt-aside" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bt-btn",
                              on: { click: _vm.toggleClick },
                            },
                            [_c("i", { class: _vm.open ? "" : "rotate" })]
                          ),
                          _vm._v(" "),
                          _c("dt-menu", { attrs: { open: !_vm.open } }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-main",
                { class: !_vm.isFullScreen ? "main-wrapper" : "" },
                [
                  _c(
                    "div",
                    { class: _vm.showMask ? "m-mask main" : "main" },
                    [
                      _vm.$route.meta.keepAlive
                        ? [
                            _c(
                              "keep-alive",
                              [_vm.forbidden ? _c("router-view") : _vm._e()],
                              1
                            ),
                          ]
                        : [_vm.forbidden ? _c("router-view") : _vm._e()],
                      _vm._v(" "),
                      !_vm.forbidden
                        ? _c("Forbidden", {
                            attrs: {
                              message:
                                "您没有此页面的访问权限，请联系主账号设置。",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }