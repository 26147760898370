import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 数据概览
export const dataOne = async params => {
  const api = API('dataOne')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取工作台下拉框数据
export const dataOptions = async params => {
  const api = API('dropdown')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取外呼看板下拉框数据
export const boardOptions = async params => {
  const api = API('boarddropdown')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取分布图数据
export const dataChartBar = async params => {
  const api = API('dataChart')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataChartTrend = async params => {
  const api = API('dataChart1')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataPOptions = async params => {
  const api = API('dropdown1')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataHk = async params => {
  const api = API('dataHk')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataWz = async params => {
  const api = API('dataWz')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataPy = async params => {
  const api = API('dataPy')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataZh = async params => {
  const api = API('dataZh')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const dataLd = async params => {
  const api = API('dataLd')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 下载任务列表
export const taskList = async params => {
  const api = API('taskList')
  return await BASE_REQUEST({ api, opt: params })
}

// 上传任务列表
export const uploadList = async params => {
  const api = API('uploadList')
  return await BASE_REQUEST({ api, opt: params })
}

// 客户地图埋点
export const maidianMap = async params => {
  const api = API('maidianMap')
  return await BASE_REQUEST({ api, opt: params })
}

// 自动转派列表
export const autoTransferList = async params => {
  const api = API('autoTransferList')
  return await BASE_REQUEST({ api, opt: params })
}

// 添加自动转派任务
export const addTranffer = async params => {
  const api = API('addTranffer')
  return await BASE_REQUEST({ api, opt: params })
}

// 删除转派任务
export const deleteTransfer = async params => {
  const api = API('deleteTransfer')
  return await BASE_REQUEST({ api, opt: params })
}

// 更新转派任务
export const updateTransfer = async params => {
  const api = API('updateTransfer')
  return await BASE_REQUEST({ api, opt: params })
}

// 转派条件
export const selectTransfer = async params => {
  const api = API('selectTransfer')
  return await BASE_REQUEST({ api, opt: params })
}

export const tableDtData = async params => {
  const api = API('tableDtData')
  return await BASE_REQUEST({ api, opt: params })
}

export const targetList = async params => {
  const api = API('targetList')
  return await BASE_REQUEST({ api, opt: params })
}

export const updateTargetList = async params => {
  const api = API('updateTargetList')
  return await BASE_REQUEST({ api, opt: params })
}
export const processTouch = async params => {
  const api = API('processTouch')
  return await BASE_REQUEST({ api, opt: params })
}
export const processTranslation = async params => {
  const api = API('processTranslation')
  return await BASE_REQUEST({ api, opt: params })
}

export const processReturn = async params => {
  const api = API('processReturn')
  return await BASE_REQUEST({ api, opt: params })
}

export const warfareSign = async params => {
  const api = API('warfareSign')
  return await BASE_REQUEST({ api, opt: params })
}

export const warfareFinancial = async params => {
  const api = API('warfareFinancial')
  return await BASE_REQUEST({ api, opt: params })
}

export const warfareNewRenew = async params => {
  const api = API('warfareNewRenew')
  return await BASE_REQUEST({ api, opt: params })
}

export const saleRankSignContract = async params => {
  const api = API('saleRankSignContract')
  return await BASE_REQUEST({ api, opt: params })
}

export const saleRankReturnRank = async params => {
  const api = API('saleRankReturnRank')
  return await BASE_REQUEST({ api, opt: params })
}

export const saleRankAchievement = async params => {
  const api = API('saleRankAchievement')
  return await BASE_REQUEST({ api, opt: params })
}
