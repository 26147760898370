var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "call-info" },
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c("div", {
              staticClass: "call-btn",
              staticStyle: { cursor: "pointer" },
              attrs: { slot: "reference" },
              on: { click: _vm.showWebRtc },
              slot: "reference",
            }),
            _vm._v(" "),
            _vm.bandWaihuType === "simPhone" && _vm.plate
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      "text-align": "center",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-setting",
                      staticStyle: { color: "#8A8A8A", cursor: "pointer" },
                      on: { click: _vm.switchSim },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-top": "2px",
                          color: "#8A8A8A",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.simIndex
                              ? "当前卡槽" + _vm.simIndex
                              : "未插卡或中间件未连接话机"
                          )
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.plate
          ? _c("web-rtc", {
              ref: "web",
              staticStyle: {
                position: "fixed",
                top: "53px",
                right: "130px",
                "z-index": "1999",
              },
              attrs: {
                companyName: _vm.companyName,
                closePanel: _vm.closePanel,
                phone: _vm.phone,
                callStatus: _vm.callStatus,
                state: _vm.state,
                asignOption: _vm.asignOption,
                type: "rtc",
              },
              on: {
                postCallId: _vm.postCallId,
                callUp: _vm.callUp,
                hangUp: _vm.hangUp,
                changeAsign: _vm.changeAsign,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }