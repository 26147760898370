import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

//  线索包
export const package_list = async params => {
  const api = API('PACKAGE_LIST')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 线索包详情
export const package_detail_condition = async params => {
  const api = API('PACKAGE_DETAIL_CONDITION')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 自动更新数据
export const AutoFilterData = async params => {
  const api = API('AutoFilterData')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 线索包详情(不带条件)
export const package_detail = async params => {
  const api = API('PACKAGE_DETAIL')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 线索包高级查询筛选条件
export const package_filter = async params => {
  const api = API('PACKAGE_FILTER')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 线索收藏
export const collection_add = async params => {
  const api = API('COLLECTION_ADD')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 取消线索收藏
export const collection_remove = async params => {
  const api = API('COLLECTION_REMOVE')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 批量线索收藏
export const collection_adds = async params => {
  const api = API('COLLECTION_ADDS')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 批量取消线索收藏
export const collection_removes = async params => {
  const api = API('COLLECTION_REMOVES')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 线索详情
export const companyuuid = async params => {
  const api = API('COMPANYUUID')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 收藏列表
export const collection_list = async params => {
  const api = API('COLLECTION_LIST')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 领取进度条
export const collectionProcess = async params => {
  const api = API('collectionProcess')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 拨打电话
export const collection_call = async params => {
  const api = API('COLLECTION_CALL')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 获取主拨电话
export const get_call = async params => {
  const api = API('GET_CALL')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 对线索评价
export const collection_comment = async params => {
  const api = API('COLLECTION_COMMENT')
  return await BASE_REQUEST({ api: api, opt: params })
}

//  通话状态
export const getCallStatus = async params => {
  const api = API('GET_CALLSTATUS')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 错误提交
export const error_commit = async params => {
  const api = API('ERROR_COMMIT')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 手动修改电话状态
export const changeStatusPhone = async params => {
  const api = API('GET_CHANGEPHONESTA')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 收藏列表查询
export const getSearchList = async params => {
  const api = API('GET_SEARCHLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取账号绑定手机号
export const getBindPhone = async params => {
  const api = API('GET_BINDPHONE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查询线索包高级查询状态
export const getPackageTag = async params => {
  const api = API('GET_PACKAGETAG')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除线索包高级查询状态
export const delPackageTag = async params => {
  const api = API('DEL_PACKAGETAG')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 保存线索包高级查询状态
export const postPackageTag = async params => {
  const api = API('POST_PACKAGETAG')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索详情
export const getComDetailModel = async params => {
  const api = API('GET_COMDETAILMODEL')
  return await BASE_REQUEST({ api: api, opt: params })
}
// app详情
export const getAppDetail = async params => {
  const api = API('GET_APP_DETAIL')
  return await BASE_REQUEST({ api: api, opt: params })
}

//标记下拉框
export const getDetailAsignOpthon = async params => {
  const api = API('GET_DETAILASIGNOPTION')
  return await BASE_REQUEST({ api: api, opt: params })
}

//添加标记
export const postDetailPhoneAsign = async params => {
  const api = API('POST_DETAILPHONEASIGN')
  return await BASE_REQUEST({ api: api, opt: params })
}

//软电话条记录
export const postWebrtc = async params => {
  const api = API('POSTWEBRTC')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 一键消耗
export const postConsumpTion = async params => {
  const api = API('POST_CONSUMPTION')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取公海页码
export const getCoditionPage = async params => {
  const api = API('GET_CODITIONPAGE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索包基础配置
export const getPackageModuleConfig = async params => {
  const api = API('GETPACKAGEMODULECONFIG')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 刷新云服务商
export const refreshIdc = async params => {
  const api = API('refreshIdc')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 是否显示外呼提示
export const showWaihuTip = async params => {
  const api = API('showWaihuTip')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 关闭提示
export const closeShowWaihuTip = async params => {
  const api = API('closeShowWaihuTip')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索包tab
export const marketTab = async params => {
  const api = API('marketTab')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const marketList = async params => {
  const api = API('marketList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const marketBook = async params => {
  const api = API('marketBook')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const postSim = async params => {
  const api = API('postSim')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const canCall = async params => {
  const api = API('canCall')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const monitorList = async params => {
  const api = API('monitorList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const addMonitor = async params => {
  const api = API('addMonitor')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const delMonitor = async params => {
  const api = API('delMonitor')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const monitorLog = async params => {
  const api = API('monitorLog')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const monitorDetail = async params => {
  const api = API('monitorDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const isMonitor = async params => {
  const api = API('isMonitor')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const applySim = async params => {
  const api = API('applySim')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const callSlot = async params => {
  const api = API('callSlot')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const batchLq = async params => {
  const api = API('batchLq')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const packageListNew = async params => {
  const api = API('packageListNew')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const packageListNews = async params => {
  const api = API('packageListNews')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const packageEditNew = async params => {
  const api = API('packageEditNew')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const packageOne = async params => {
  const api = API('packageOne')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const contractHeaderShow = async params => {
  const api = API('contractHeaderShow')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const contractHeaderUpdate = async params => {
  const api = API('contractHeaderUpdate')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const addNichePz = async params => {
  const api = API('addNichePz')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getAllSales = async params => {
  const api = API('getAllSales')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getAllContact = async params => {
  const api = API('getAllContact')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const supplierList = async params => {
  const api = API('supplierList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const supplierAdd = async params => {
  const api = API('supplierAdd')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const supplierEdit = async params => {
  const api = API('supplierEdit')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const supplierNum = async params => {
  const api = API('supplierNum')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const supplierDel = async params => {
  const api = API('supplierDel')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const purchaseList = async params => {
  const api = API('purchaseList')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const purchaseEdit = async params => {
  const api = API('purchaseEdit')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const purchaseAdd = async params => {
  const api = API('purchaseAdd')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const purchaseDel = async params => {
  const api = API('purchaseDel')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const heightSearchTypeList = async params => {
  const api = API('heightSearchTypeList')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const heightSearchFilter = async params => {
  const api = API('heightSearchFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const customerColumn = async params => {
  const api = API('customerColumn')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const editCustomerColumn = async params => {
  const api = API('editCustomerColumn')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getBandList = async params => {
  const api = API('getBandList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getBandFilter = async params => {
  const api = API('getBandFilter')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getBandDetail = async params => {
  const api = API('getBandDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const listDetailData = async params => {
  const api = API('listDetailData')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const currentVersion = async params => {
  const api = API('currentVersion')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const pyToast = async params => {
  const api = API('pyToast')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const latestPercent = async params => {
  const api = API('latestPercent')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const groupList = async params => {
  const api = API('groupList')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const groupListDetail = async params => {
  const api = API('groupListDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const groupListDrown = async params => {
  const api = API('groupListDrown')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const groupListBand = async params => {
  const api = API('groupListBand')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const groupListApp = async params => {
  const api = API('groupListApp')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const groupListCount = async params => {
  const api = API('groupListCount')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getMemoryParams = async params => {
  const api = API('getMemoryParams')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const setMemoryParams = async params => {
  const api = API('setMemoryParams')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getTagList = async params => {
  const api = API('getTagList')
  return await BASE_REQUEST({ api: api, opt: params })
}
