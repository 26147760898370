<template>
  <div class="tip-main">
    <div class="tip-text">{{ title }}</div>
    <div class="er-code"></div>
    <el-table :data="tableData" border>
      <el-table-column prop="mobile" label="手机号" width="180">
      </el-table-column>
      <el-table-column prop="openId" label="微信OpenID" width="180">
      </el-table-column>
      <el-table-column prop="bindTime" label="绑定时间"> </el-table-column>
    </el-table>

    <div class="dt-modal-footer">
      <el-button type="primary" size="mini" @click="closeModal">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReBindWx',
  computed: {
    env() {
      return this.$store.state.plugin.env
    },
    mobile() {
      return (
        this.$store.state.plugin.modal.options?.phone?.[0].mobile?.replace(
          /^(\d{3})\d{4}(\d+)/,
          '$1****$2'
        ) || '-'
      )
    },
    tableData() {
      return this.$store.state.plugin.modal.options?.phone
    },
    code() {
      return this.$store.state.plugin.modal.options?.code
    },
    title() {
      return this.$store.state.plugin.modal.options?.title
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('hideModal')
    }
  }
}
</script>

<style scoped>
.tip-main {
  width: 100%;
  height: auto;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
}
.er-code {
  height: 140px;
  width: 100%;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png')
    no-repeat top center;
  background-size: auto 120px;
}
.er-code-tt {
  height: 140px;
  width: 100%;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/qrcode.png')
    no-repeat top center;
  background-size: auto 120px;
}
.tip-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  user-select: none;
}
.dt-modal-footer {
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.dt-modal-footer::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(100% + 20px);
  position: absolute;
  top: 0;
  left: -10px;
  transform: scaleY(0.5);
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
