var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-div" },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-menu",
            {
              class: _vm.menuType === "si-yu" ? "menu-siyu menu" : "menu",
              attrs: {
                "default-openeds": _vm.openeds,
                "default-active": _vm.currentPage,
                collapse: _vm.open,
                router: true,
                "active-text-color": "#78d9d1",
                "text-color": "#8a8a8a",
                "collapse-transition": false,
              },
            },
            [
              [
                _vm.menuType === "si-yu"
                  ? [
                      _vm._l(_vm.routeList, function (menu) {
                        return [
                          _c(
                            "el-submenu",
                            {
                              key: menu.id,
                              attrs: { index: menu.id.toString() },
                            },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "icon",
                                    attrs: { "icon-class": _vm.getIcon(menu) },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v(_vm._s(menu.name))]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(
                                menu.pages.filter(function (item) {
                                  return (
                                    item.name !== "自助找客" &&
                                    item.name !== "联系人培育" &&
                                    ![
                                      "公域潜客池",
                                      "私域培育池",
                                      "私域成交池",
                                    ].includes(item.name)
                                  )
                                }),
                                function (page) {
                                  return _c(
                                    "el-menu-item",
                                    {
                                      key: page.id,
                                      attrs: { index: page.uri },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setPageId(page, menu)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "m-name" }, [
                                        _vm._v(" " + _vm._s(page.name)),
                                      ]),
                                      _vm._v(" "),
                                      page.name === "数据看板" ||
                                      page.name === "企业查询"
                                        ? _c("span", {
                                            staticClass: "new-icon",
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      }),
                    ]
                  : [
                      _vm._l(_vm.routeList, function (menu) {
                        return [
                          _c(
                            "el-submenu",
                            {
                              key: menu.id,
                              attrs: { index: menu.id.toString() },
                            },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "icon",
                                    attrs: { "icon-class": _vm.getIcon(menu) },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v(_vm._s(menu.name))]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(menu.pages, function (page) {
                                return [
                                  _c(
                                    "el-menu-item",
                                    {
                                      key: page.uri,
                                      attrs: { index: page.uri },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setPageId(page, menu)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(page.name) +
                                              "\n                    "
                                          ),
                                          _c("el-badge", {
                                            staticClass: "item",
                                            attrs: {
                                              value: _vm.messageNum(page.name),
                                            },
                                          }),
                                          _vm._v(" "),
                                          page.name === "电销外呼"
                                            ? _c("span", {
                                                staticClass: "new-icon",
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "-2px",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      }),
                    ],
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }