<template>
  <div class="div" v-loading="loading">
    <el-row class="header border" ref="row">
      <el-col :span="4">
        <div class="text">一级菜单栏</div>
      </el-col>
      <el-col :span="20">
        <el-row>
          <el-col :span="4">
            <div class="text border">二级菜单栏</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="16">
                <div class="text border">功能权限</div>
              </el-col>
              <el-col :span="8">
                <div class="text border">数据权限</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="content">
      <el-row
        align="middle"
        type="flex"
        class="menu"
        v-for="item in permissionList"
        :key="item.id"
      >
        <el-col :span="4" class="menu-item text">
          <el-checkbox
            v-model="item.checked"
            :disabled="isForbid"
            @change="change('menu', item.checked, item.id)"
            >{{ item.name }}
          </el-checkbox>
        </el-col>
        <el-col :span="20">
          <el-row class="page-pre">
            <el-col :span="24">
              <el-row
                type="flex"
                align="middle"
                v-for="page in item['pages']"
                :key="page.id"
                class="page"
              >
                <el-col :span="4" class="text">
                  <el-checkbox
                    v-model="page.checked"
                    :disabled="isForbid"
                    @change="change('page', page.checked, page.id)"
                    >{{ page.name }}
                  </el-checkbox>
                </el-col>
                <el-col :span="20">
                  <el-row class="func-pre" type="flex" align="middle">
                    <el-col :span="16" class="data-permission text">
                      <el-col
                        :span="8"
                        v-for="func in page['functions']"
                        :key="func.id"
                        class="func"
                      >
                        <el-checkbox
                          v-model="func.checked"
                          :disabled="isForbid"
                          @change="
                            change('func', func.checked, func.id, {
                              item,
                              func
                            })
                          "
                          >{{ func.name }}
                        </el-checkbox>
                      </el-col>
                    </el-col>
                    <el-col :span="8">
                      <data-permission
                        :parent-id="item.id"
                        :disabled="isForbid"
                        :user-list="userList"
                        :data-model="page['dataPermissionModel']"
                        :page-id="page.id"
                        :ref="`permission${page.id}`"
                      />
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="save">
      <div class="btn" @click="save" v-if="!isForbid">保存</div>
    </div>
  </div>
</template>

<script>
import { userList } from '@/api/permission'
import dataPermission from './dataPermission'
import { configPermissionId } from '@/utils/helper'
export default {
  name: 'UpdatePermission',
  props: {
    //可以更改的权限对象
    permissionObj: {
      type: Array,
      default: () => {
        return []
      }
    },
    //存在的权限数据
    updateData: {
      type: Array,
      default: () => {
        return []
      }
    },
    //编辑时 需要设置menu和page选中
    setChecked: {
      type: Boolean,
      default: true
    },
    isForbid: {
      type: Boolean,
      default: false
    },
    permissionValue: {
      type: Number,
      default: -1
    }
  },
  components: {
    dataPermission
  },
  data() {
    return {
      list: [],
      permissionList: [],
      key: 0,
      userList: [],
      loading: true
    }
  },
  created() {
    this.$bus.$on('permissionValueChange', e => {
      this.permissionList = configPermissionId(this.list, e)
    })
    this.$bus.$on('handleCreatedPermission', res => {
      this.list.forEach(item => {
        if (res.parent_id === item.id) {
          item.pages.forEach(it => {
            if (it.id === res.page_id) {
              it.dataPermissionModel = res
            }
          })
        }
      })
    })
  },
  mounted() {
    userList()
      .then(res => {
        this.userList = res.data
        this.list = JSON.parse(JSON.stringify(this.permissionObj))
        this.handleData(this.updateData)
        this.loading = false
      })
      .catch(() => {
        this.loading = false
        this.$message.error('获取用户失败')
      })
  },
  methods: {
    save() {
      this.$emit('update', this.list) //发送保存事件
    },

    change(type, status, id, { item, func } = {}) {
      // debugger

      //点击menu
      if (type === 'menu' && !status) {
        //将该分支下的所有选中取消
        this.list.forEach(item => {
          if (item.id === id) {
            let pages = item.pages
            pages.forEach(page => {
              page.checked = false //page选中取消
              let functions = page.functions
              functions.forEach(func => {
                func.checked = false //function选中取消
              })
            })
          }
        })
      }

      //点击page
      if (type === 'page' && !status) {
        // 将该分支下的所有functions选中取消
        this.list.forEach(item => {
          let pages = item.pages
          pages.forEach(page => {
            if (page.id === id) {
              let functions = page.functions
              functions.forEach(func => {
                func.checked = false //function取消选中
              })
            }
          })
        })
      }

      //点击page
      if (type === 'page' && status) {
        // 选中该page 对应的menu也应选中
        this.list.forEach(item => {
          let pages = item.pages
          pages.forEach(page => {
            if (page.id === id && page.checked) {
              item.checked = true
            }
          })
        })
      }

      //点击func
      if (type === 'func' && status) {
        // 选中该function 对应的page和menu也应选中
        const pageId = func.parent_id
        const menuId = item.id
        this.list.forEach(_item => {
          if (_item.id === menuId) {
            _item.checked = true
            let pages = _item.pages
            pages.forEach(page => {
              if (page.id === pageId) {
                page.checked = true
              }
            })
          }
        })
      }
      this.$forceUpdate()
    },

    // 处理 编辑操作的 权限数据
    handleData(updateData) {
      // 处理需要修改的权限
      let updateDataInstance = {}
      let updateDataPermissionInstance = {}
      updateData.forEach(item => {
        //添加选中menu的id
        updateDataInstance[item.id] = true
        // 添加选中的page的id
        const pages = item.pages
        pages.forEach(page => {
          updateDataInstance[page.id] = page['checked']
          updateDataPermissionInstance[page.id] = page['dataPermissionModel']
          //添加选中的function的id
          const functions = page.functions
          functions.forEach(func => {
            updateDataInstance[func.id] = func.checked
          })
        })
      })
      //设置checked
      this.list.forEach(item => {
        //设置menu选中
        // item['checked'] = updateDataInstance[item.id]
        let pages = item.pages
        pages.forEach(page => {
          if (updateDataPermissionInstance[page.id]) {
            page['dataPermissionModel'] = updateDataPermissionInstance[page.id]
          }
          page['checked'] = updateDataInstance[page.id] ? true : false
          this.change('page', page['checked'], page.id)
          let functions = page.functions
          functions.forEach(func => {
            func['checked'] = updateDataInstance[func.id] ? true : false //深拷贝会将undefined删除
            this.change('func', func.checked, func.id, { item, func })
          })
        })
      })
      this.permissionList = this.list
      console.log(this.list, 1122)
      this.$forceUpdate()
    }
  },
  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>

<style scoped>
.div {
  height: 100%;
  width: 100%;
  font-size: 12px;
  padding-right: 15px;
}

.header {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.border {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.header .text {
  height: 50px;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.text {
  padding-left: 10px;
  box-sizing: border-box;
}

.set-padding {
  padding: 20px;
  box-sizing: border-box;
}

.data-permission:after {
  content: '';
  position: absolute;
  right: 33.33%;
  top: 0;
  display: inline-block;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.05);
}

.menu {
  box-sizing: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  height: auto;
}

.menu-item {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-item:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
}

.content {
  min-height: calc(100% - 80px - 52px);
  height: auto;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.content .menu:last-child::after {
  display: none;
}

.content .menu:first-child {
  padding-top: 0;
}

.page {
  /*margin-bottom: 20px;*/
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.page-pre .page:first-child {
  border-top: none;
}

.func-pre {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.func {
  margin: 10px 0;
}

.page-pre .page:last-child {
  margin-bottom: 0;
}

.save {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
  box-sizing: border-box;
}

.btn {
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #41cabf;
  border-radius: 5px;
  cursor: pointer;
}

.account-list {
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 10px 10px 0 0;
  padding: 20px;
  box-sizing: border-box;
}

/* 更改checkbox选中后的颜色和字号 */
.content >>> .el-checkbox__label {
  color: #333 !important;
  font-size: 12px !important;
}

/* 清除横向滚动条 */
.content >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
