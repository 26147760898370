var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wscn-http404-container" }, [
    _c("div", { staticClass: "wscn-http404" }, [
      _c("div", { staticClass: "bullshit" }, [
        _c("div", { staticClass: "bullshit__headline" }, [
          _vm._v(_vm._s(_vm.message)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bullshit__info" }, [_vm._v(_vm._s(_vm.tip))]),
        _vm._v(" "),
        _c("a", { staticClass: "bullshit__return-home", attrs: { href: "" } }, [
          _vm._v("首页"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("span", [_vm._v("All rights reserved")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticStyle: { color: "#20a0ff" },
          attrs: { href: "https://www.dtbird.cn/", target: "_blank" },
        },
        [_vm._v("DTsales")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }