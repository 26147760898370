var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-component-list" },
    [
      _vm.updateFlag && Object.keys(_vm.tagList).length
        ? [
            _c(
              "el-popover",
              {
                ref: "popRef",
                attrs: {
                  title: _vm.tagTitle,
                  width: _vm.tagWidth,
                  placement: "top-start",
                  trigger: "hover",
                  "popper-options": {
                    strategy: "fixed",
                  },
                },
                on: { show: _vm.handleShow },
              },
              [
                _vm._l(_vm.tagList, function (it, index) {
                  return [
                    _c("div", { staticClass: "flex-tag-wrap" }, [
                      _c("p", [_vm._v(_vm._s(index))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._l(it, function (el) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  style: _vm.hoverTag,
                                  attrs: { size: "mini" },
                                },
                                [_vm._v(_vm._s(el.tagName))]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style: _vm.hoverStyle,
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _vm._l(_vm.tagList, function (value, key) {
                      return [
                        _vm._l(value, function (it) {
                          return [
                            _c(
                              "el-tag",
                              { style: _vm.showTag, attrs: { size: "mini" } },
                              [_vm._v(_vm._s(it.tagName))]
                            ),
                          ]
                        }),
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      { style: _vm.showTag, attrs: { size: "mini" } },
                      [_vm._v("...")]
                    ),
                  ],
                  2
                ),
              ],
              2
            ),
          ]
        : [
            _vm.tagList.length > 3
              ? [
                  _c(
                    "el-popover",
                    {
                      ref: "popRef",
                      attrs: {
                        placement: "top-start",
                        title: _vm.tagTitle,
                        width: _vm.tagWidth,
                        trigger: "hover",
                        "popper-options": {
                          strategy: "fixed",
                        },
                      },
                      on: { show: _vm.handleShow },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm._l(_vm.tagList, function (it) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  style: _vm.hoverTag,
                                  attrs: { size: "mini" },
                                },
                                [_vm._v(_vm._s(it))]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          style: _vm.hoverStyle,
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._l(_vm.tagList.slice(0, 3), function (item) {
                            return [
                              _c(
                                "el-tag",
                                { style: _vm.showTag, attrs: { size: "mini" } },
                                [_vm._v(_vm._s(item))]
                              ),
                            ]
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tag",
                            { style: _vm.showTag, attrs: { size: "mini" } },
                            [_vm._v("...")]
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              : [
                  _vm._l(_vm.tagList, function (item) {
                    return [
                      _c(
                        "el-tag",
                        { style: _vm.showTag, attrs: { size: "mini" } },
                        [_vm._v(_vm._s(item))]
                      ),
                    ]
                  }),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }