<template>
  <div v-loading="loading" element-loading-text="正在切卡中，请耐心等待">
    <div class="switchSim-main">
      <el-card shadow="hover" class="sim-index">
        <div class="text">SIM1</div>
        <div @click="switchSim(1)">
          <el-card shadow="hover" :class="simIndex === 1 ? 'btn active' :'btn'"> {{ simIndex === 1 ? '正在使用' : '一键切卡' }}</el-card>
        </div>
      </el-card>

      <el-card shadow="hover" class="sim-index">
        <div class="text">SIM2</div>
        <div @click="switchSim(2)">
          <el-card shadow="hover" :class="simIndex === 2 ? 'btn active' :'btn'" >{{ simIndex === 2 ? '正在使用' : '一键切卡' }}</el-card>
        </div>
      </el-card>

      <el-card shadow="hover" class="sim-index">
        <div class="text">SIM3</div>
        <div @click="switchSim(3)">
          <el-card shadow="hover" :class="simIndex === 3 ? 'btn active' :'btn'">{{ simIndex === 3 ? '正在使用' : '一键切卡' }}</el-card>
        </div>
      </el-card>

      <el-card shadow="hover" class="sim-index">
        <div class="text">SIM4</div>
        <div @click="switchSim(4)">
          <el-card shadow="hover" :class="simIndex === 4 ? 'btn active' :'btn'">{{ simIndex === 4 ? '正在使用' : '一键切卡' }}</el-card>
        </div>
      </el-card>

      <el-card shadow="hover" class="sim-index">
        <div class="text">SIM5</div>
        <div  @click="switchSim(5)">
          <el-card shadow="hover" :class="simIndex === 5 ? 'btn active' :'btn'">{{ simIndex === 5 ? '正在使用' : '一键切卡' }}</el-card>
        </div>
      </el-card>
    </div>
    <div class="footer">
      <el-button type="primary" size="small" @click="$store.dispatch('hideModal')">关闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'switchSim',
  data () {
    return {
      loading: false
    }
  },
  mounted () {},
  watch: {
    simIndex: function (){
      this.loading = false
      this.$store.dispatch('showTip', {
        text: '切换成功',
        type: 'success'
      })
    },
    close: function (val){
      if(val) this.loading = false
    }
  },
  computed: {
    simIndex(){
      return this.$store.state.phoneSocket.simIndex
    },
    wsObj(){
      return this.$store.state.phoneSocket.wsObj
    },
    close(){
      return this.$store.state.phoneSocket.close
    }
  },
  methods: {
    switchSim(index){
      console.log(index === this.simIndex)
      if(this.wsObj && this.wsObj.readyState !== 1){
        this.$store.dispatch('showTip', {
          text: '话机连接失败,请确认中间件已经开启',
          type: 'error'
        })
        return
      }
      if(index === this.simIndex || this.loading) return
      if(this.wsObj){
          this.loading = true
          const code = `{"cmd": "changeSim","value": ${ index },"status":0}`
          this.wsObj.send(code)
      }
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.switchSim-main{
  /*height: 400px;*/
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}
.sim-index{
  height: 300px;
  width: 150px;
  /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text{
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bolder;
}
.btn{
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 20px;
  cursor: pointer;
}
.active{
  background-color: #41cabf;
  color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.footer{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  position: relative;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleY(.5);
}
</style>
