
<template>
  <div class="editUser-main">
      <div class="edit-main">
        <el-form ref="userForm" v-model="userFormData" :rules="userRules" label-width="auto">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="姓名" prop="contact">
                <el-input placeholder="请输入姓名" v-model="userFormData.contact"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="身份">
                <el-select v-model="userFormData.isKp" placeholder="请选择" style="width: 100%;">
                  <el-option  label="关键人" value="1"></el-option>
                  <el-option  label="中间人" value="2"></el-option>
                  <el-option  label="普通" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="部门">
                <el-input placeholder="请输入部门" v-model="userFormData.department"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="职位">
                <el-input placeholder="请输入职位" v-model="userFormData.position"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="手机">
                <el-input placeholder="请输入手机号码" v-model.trim="userFormData.mobile"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="座机">
                <el-input placeholder="请输入座机号码" v-model.trim="userFormData.landLine"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="微信号">
                <el-input placeholder="请输入微信号" v-model="userFormData.wechat"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="邮箱">
                <el-input placeholder="请输入邮箱" v-model="userFormData.email"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="状态">
                <el-select v-model="userFormData.mark" placeholder="请选择状态" style="width: 100%">
                  <el-option :label="_item.name" :value="_item.value" v-for="_item in markDescList" :key="_item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  placeholder="请输入联系人备注"
                  v-model="userFormData.remark"
                  maxlength="100"
                  show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
      </div>
      <div class="footer">
        <div>
          <el-checkbox v-model="notShow">不再显示</el-checkbox>
        </div>
        <div>
          <el-button size="small" @click="cancelBtn">取消</el-button>
          <el-button size="small" type="primary" @click="confirmBtn">确定</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import { getDetailAsignOpthon } from '@/api/thread'
import { coustomerEdit } from '@/api/user'
import { noTipEdit } from '@/api/customerpond'

export default {
  name: 'editUser',
  data () {
    return {
      notShow: false,
      markDescList: [],
      userFormData: {
        contact: '',
        isKp: '',
        department: '',
        position: '',
        mobile: '',
        landLine: '',
        wechat: '',
        email: '',
        mark: '',
        remark: ''
      },

      userRules: {
        contact: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    const modalOptions = this.$store.state.plugin.modal.options
    this.userFormData = Object.assign({}, this.userFormData, modalOptions.userFormData)
    if(!['1', '2', '3'].includes(modalOptions.userFormData.isKp + '')){
      this.userFormData.isKp = ''
    }else {
      this.userFormData.isKp = modalOptions.userFormData.isKp.toString()
    }
    getDetailAsignOpthon().then(res => {
      this.markDescList = res.data
    });
  },
  computed: {},
  methods: {
    cancelBtn(){
      this.$store.dispatch('hideModal')
    },

    confirmBtn(){
      this.$refs['userForm'].validate(valid =>{
        if(!valid) return
        coustomerEdit(this.userFormData).then(res =>{
          if(res.code === 60000){
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: "success"
            })
            if(this.notShow){
              noTipEdit().then()
            }
          }
        })
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.editUser-main{
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: calc( 100% + 20px );
  position: absolute;
  left: -10px;
  top: 0;
  transform: scaleY(.5);
  background: rgba(0,0,0,.1);
}
</style>
