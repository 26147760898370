<template>
  <div class="editCompanyOrUser-main">
    <div class="edit-main">
      <el-form ref="keyForm" v-model="formData" :rules="rules" label-width="auto" size="small" class="form-edit">
        <el-row :gutter="10">
          <el-col :span="24">
            <div class="header-title">公司信息</div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人" prop="userId">
              <el-select v-model="formData.userId" placeholder="请选择负责人" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="item in leaderList" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="公司名称" prop="companyName">
              <el-input v-model="formData.companyName" disabled placeholder="请输入公司名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="客户来源" prop="fromId">
              <el-select v-model="formData.fromId" placeholder="请选择客户来源" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="item in allSource" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="省份" prop="province">
              <el-select v-model="formData.province" @change="getAllCity" placeholder="请选择省份" style="width: 100%;box-sizing: border-box">
                <el-option :label="item.name" :value="item.name" v-for="item in allProvince" :key="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="城市" prop="province">
              <el-select v-model="formData.city" placeholder="请选择城市" style="width: 100%;float: right">
                <el-option :label="item" :value="item" v-for="item in allCity" :key="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="状态">
              <el-select v-model="formData.stateId" placeholder="请选择客户状态" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="item in allStatus" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="线索等级">
              <el-select v-model="formData.clueGradeId" placeholder="请选择线索等级" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="item in allClue" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="客户等级">
              <el-select v-model="formData.gradeId" placeholder="请选择客户等级" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="item in allLevel" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="标签">
              <el-select multiple v-model="selectTags" @change="selectChange" ref="selectTag" placeholder="请选择标签" style="width: 100%" size="small">
                <el-option
                  v-for="item in allPerTags"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注">
              <el-input v-model="formData.remark" placeholder="请输入备注" type="textarea" :autosize="{ minRows: 2, maxRows: 10 }" maxlength="100" show-word-limit style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>


      <el-form ref="userForm" v-model="userFormData" :rules="userRules" label-width="auto" style="padding-left: 1em" size="small">
        <el-row :gutter="10">
          <el-col :span="24">
            <div class="header-title2">联系人信息</div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名" prop="contact">
              <el-input placeholder="请输入姓名" v-model="userFormData.contact"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="身份">
              <el-select v-model="userFormData.isKp" placeholder="请选择" style="width: 100%;">
                <el-option  label="关键人" value="1"></el-option>
                <el-option  label="中间人" value="2"></el-option>
                <el-option  label="普通" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="部门">
              <el-input placeholder="请输入部门" v-model="userFormData.department"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="职位">
              <el-input placeholder="请输入职位" v-model="userFormData.position"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="手机">
              <el-input placeholder="请输入手机号码" v-model.trim="userFormData.mobile"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="座机">
              <el-input placeholder="请输入座机号码" v-model.trim="userFormData.landLine"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="微信号">
              <el-input placeholder="请输入微信号" v-model="userFormData.wechat"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="邮箱">
              <el-input placeholder="请输入邮箱" v-model="userFormData.email"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="状态">
              <el-select v-model="userFormData.mark" placeholder="请选择状态" style="width: 100%">
                <el-option :label="_item.name" :value="_item.value" v-for="_item in markDescList" :key="_item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                type="textarea"
                placeholder="请输入联系人备注"
                v-model="userFormData.remark"
                maxlength="100"
                show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>
    <div class="footer">
      <div>
        <el-checkbox v-model="notShow">不再显示</el-checkbox>
      </div>
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailAsignOpthon } from '@/api/thread'
import { getCusTagsDdic, getCustomerLeaders, putCustomerInfo, noTipEdit } from '@/api/customerpond'
import { coustomerEdit, getAddress, getAddress2 } from '@/api/user'

export default {
  name: 'editCompanyOrUser',
  data () {
    return {
      leaderList: [],
      allLevel: [], // 客户等级
      allSource: [], //客户来源
      allStatus: [], // 状态
      allClue: [], // 线索等级
      allPerTags: [],
      allProvince: [],
      allCity: [],

      selectTags: [],
      formData: {
        userId: '',
        companyName: '',
        province: '',
        city: '',
        fromId: '',
        clueGradeId: '',
        gradeId: '',
        stateId: '',
        remark: '',
        customerId: ''
      },
      rules: {
        userId: [
          { required: true, message: '请选择负责人', trigger: 'blur' },
        ],
      },

      markDescList: [],
      userFormData: {
        contact: '',
        isKp: '',
        department: '',
        position: '',
        mobile: '',
        landLine: '',
        wechat: '',
        email: '',
        mark: '',
        remark: ''
      },

      userRules: {
        contact: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
      },
      notShow: false,
      activeTab: '',
      loading: false
    }
  },
  mounted () {
    const modalOptions = this.$store.state.plugin.modal.options
    this.activeTab = modalOptions.activeTab
    this.userFormData = Object.assign({}, this.userFormData, modalOptions.userFormData)
    this.formData = Object.assign({}, this.formData, modalOptions.formData)
    this.formData.customerId = modalOptions.formData.id
    // selectTags
    if(modalOptions.formData.perTagsId){
      this.selectTags = (modalOptions.formData.perTagsId).toString().split(',').map(i => parseInt(i))
    }
    if(!['1', '2', '3'].includes(modalOptions.userFormData.isKp + '')){
      this.userFormData.isKp = ''
    }else {
      this.userFormData.isKp = modalOptions.userFormData.isKp.toString()
    }
    this.init()
  },
  computed: {},
  methods: {
    init(){
      getDetailAsignOpthon().then(res => {
        this.markDescList = res.data
      });

      const leaderParams = {
        pageId: 2001,
        type: this.activeTab
      }
      getCustomerLeaders(leaderParams).then(res => {
        this.leaderList = res.data
      });

      ['1', '2', '3', '4', '5'].forEach(val => {
        getCusTagsDdic({ type: val }).then(res => {
          if (res.code === 60000) {
            if (val === '1') {
              this.allLevel = res.data
            }
            if (val === '2') {
              this.allSource = res.data
            }
            if (val === '3') {
              this.allStatus = res.data
            }
            if (val === '4') {
              this.allClue = res.data
            }
            if (val === '5') {
              this.allPerTags = res.data
            }
          }
        })
      })

      getAddress({ level: 1, pCode: 0 }).then(res => {
        if (res.code === 60000) {
          this.allProvince = res.data
          if (this.formData.province){
            getAddress2({ province: this.formData.province }).then(res => {
              if (res.code === 60000) {
                this.allCity = res.data
              }
            })
          }
        }
      })
    },

    async confirmBtn(){
      this.$refs['keyForm'].validate()
      this.$refs['userForm'].validate()
      if(!this.formData.userId || !this.userFormData.contact) return
      try {
        this.loading = true
        const editKeySuccess = await this.editCompany()
        if(editKeySuccess){
          const editCusSuccess = await this.editCustomer()
          if(!editCusSuccess) return
          if(this.notShow) noTipEdit().then()
          this.loading = false
          await this.$store.dispatch('hideModal')
          await this.$store.dispatch('showTip', {
            text: editCusSuccess,
            type: 'success'
          })
        }
      }catch (e) {
        this.loading = false
        await this.$store.dispatch('showTip', {
          text: '请稍后再试',
          type: 'error'
        })
      }
    },

    cancelBtn(){
        this.$store.dispatch('hideModal')
    },

    // 编辑公司
    editCompany(){
      return new Promise((resolve, reject) => {
        const params = Object.assign({}, this.formData, {perTags: this.selectTags.join(',')})
        putCustomerInfo(params).then(res=>{
          if(res.code === 60000){
            resolve(true)
          }else {
            resolve(false)
          }
        }).catch(()=>reject(false))
      })
    },

    // 编辑联系人
    editCustomer(){
      return new Promise((resolve, reject) => {
        coustomerEdit(this.userFormData).then(res =>{
          if(res.code === 60000){
            resolve('操作成功')
          }else {
            this.loading = false
            resolve(false)
          }
        }).catch(()=>reject(false))
      })
    },

    getAllCity(){
      this.formData.city = ''
      if (!this.formData.province) return
      getAddress2({ province: this.formData.province }).then(res => {
        if (res.code === 60000) {
          this.allCity = res.data
        }
      })
    },

    // 标签
    selectChange(){

    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.edit-main{
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  cursor: default;
}

.form-edit > .el-row >.el-col:last-child > .el-form-item{
  margin-bottom: 0!important;
}

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: scaleY(.5);
  background: rgba(0,0,0,.1);
}
.header-title{
  padding: 10px 0;
  box-sizing: border-box;
  transform: translateX(-10px);
  font-size: 12px;
}
.header-title2{
  padding: 20px 0;
  box-sizing: border-box;
  transform: translateX(-20px);
  font-size: 12px;
}
</style>
