<template>
  <div class="body">
    <div class="msg" v-show="showMsg">
      <el-row :gutter="20">
        <el-col
          :span="12"
          v-for="item in msgList"
          :key="item.key"
          style="margin-bottom: 10px"
        >
          <span style="margin-right: 10px">{{ item.name }}:</span
          ><span>{{ row[item.key] || item.defaultValue }}</span>
        </el-col>
      </el-row>
    </div>
    <div class="home">
      <vue-audio-native
        size="default"
        :url="url"
        :showCurrentTime="showCurrentTime"
        :showControls="showControls"
        :showVolume="showVolume"
        :showDownload="showDownload"
        :autoplay="autoplay"
        :hint="hint"
        :waitBuffer="waitBuffer"
        :downloadName="downloadName"
        @on-change="change"
        @on-timeupdate="timeupdate"
        @on-metadata="metadata"
        @on-audioId="audioId"
        @on-end="end"
      >
      </vue-audio-native>
    </div>

    <div class="btn">
      <el-button type="primary" @click="mp3download" size="small">
        下载录音
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileaudio',
  data() {
    return {
      row: {},
      urlArray: [
        'https://dtsales.oss-cn-shanghai.aliyuncs.com/waihu-record/test/988ff0bb-1ec3-4a38-8391-332d4af1cdbc.mp3'
      ], //演示路径
      url: '',
      showCurrentTime: true, //默认true，是否显示当前播放时间
      showControls: false, //默认false，true:展示原生音频播放控制条，false：展示模拟播放控制条
      showVolume: true, //默认true，默认显示音量调节和静音按钮 true显示音量调节和静音按钮
      showDownload: false, //默认true，默认显示下载按钮
      autoplay: false, //默认false，自动播放有效音频(由于高版本浏览器协议限制，初始化页面时无法自动播放，可以在点击页面后手动触发)
      waitBuffer: true, //默认true，拖拽到未加载的时间，是否需要等待加载，true:滑块位置不动，等待加载音频资源后播放，false：当滑动位置大于当前缓冲的最大位置，则重置到当前最大缓冲位置
      downloadName: '下载音频', //默认“下载音频”，在Chrome和火狐、同域名下，修改下载文件名称，其它保持原文件服务器名称
      hint: '音频正在上传中，请稍等…', //无音频情况下提示文案
      currentAudioId: [], //当前正在播放的audio id数组
      showMsg: true,
      msgList: [
        {
          key: 'account',
          name: '账号',
          defaultValue: ''
        },
        {
          key: 'companyName',
          name: '公司名称',
          defaultValue: ''
        },
        {
          key: 'phone',
          name: '呼叫号码',
          defaultValue: ''
        },
        {
          key: 'province',
          name: '归属地(省)',
          defaultValue: ''
        },
        {
          key: 'callTime',
          name: '呼叫时间',
          defaultValue: ''
        },
        {
          key: 'city',
          name: '归属地(市)',
          defaultValue: ''
        }
      ]
    }
  },
  mounted() {
    this.row = this.$store.state.plugin.modal.options.row
    console.log(this.row)
    this.url = this.row.ossUrl
    if (this.row.type === 'record') {
      this.showMsg = false
    }
  },

  methods: {
    mp3download() {
      if (!this.showMsg) {
        saveAs(this.row.ossUrl, new Date().Format('yyyyMMddhhmmssS'))
      } else {
        saveAs(
          this.row.ossUrl,
          this.row.account + new Date().Format('yyyyMMddhhmmssS')
        )
      }
    },
    cancel() {
      this.$store.dispatch('hideModal')
    },
    change(event) {
      console.log('当前播放状态：', event)
    },
    timeupdate(event) {
      console.log('当前播放时间：', event)
    },
    metadata(event) {
      console.log(event, '音频长度：', event.target.duration)
    },
    //切换音频地址
    swtichSrc(index) {
      let t = this
      t.url = t.urlArray[index]
    },
    //获取渲染能够播放音频currentAudioId，可存全局，用以播放和暂停音频
    audioId(event) {
      console.log(event, 9999999)
      this.currentAudioId.push(event)
    },
    //获取到音频停止的事件，true为
    end(event) {
      console.log(event, 281789)
    },
    //通过音频列表的下标获取渲染能够播放音频currentAudioId即组件的id，并使用组件的id，用来开始播放录音
    play(index) {
      document.getElementById(this.currentAudioId[index]).play()
    },
    //通过音频列表的下标获取渲染能够播放音频currentAudioId即组件的id，并使用组件的id，，用来暂停播放录音
    pause(index) {
      document.getElementById(this.currentAudioId[index]).pause()
    }
  }
}
</script>

<style scoped>
.home >>> .audio-muted {
  color: #41cabf !important;
}
.home
  >>> .vueAudioNative
  .audio-volume
  .vertical-slider-box
  .vertical-slider
  .slider-bar {
  background-color: #41cabf !important;
}
.home >>> .slider-bar {
  background-color: #41cabf !important;
}
.home >>> .confont played {
  color: #41cabf !important;
}
.home >>> .vueAudioNative a {
  color: #41cabf !important;
}
.msg {
  margin-bottom: 20px;
  min-height: 80px;
  height: auto;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
}
.body {
  height: auto;
  width: 100%;
  padding: 20px 40px 20px 40px;
  box-sizing: border-box;
}
.row {
  height: auto;
  width: 100%;
}
.btn {
  padding-top: 0;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.home >>> .slider-btn {
  border: 2px solid #41cabf !important;
}
.home >>> .played {
  color: #41cabf !important;
}
</style>
