var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "successSim-main" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$store.dispatch("hideModal")
              },
            },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main" }, [
      _c("div", [_vm._v("申请已提交，我们会在1个工作日内与您取得联系。")]),
      _vm._v(" "),
      _c("div", [_vm._v("如您不想等待，也可以主动联系客服")]),
      _vm._v(" "),
      _c("div", { staticClass: "img" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }