<template>
  <span>
    <dt-tip-el></dt-tip-el>
    <dt-modal-el></dt-modal-el>
    <dt-message-box-el></dt-message-box-el>
    <dt-notice-el></dt-notice-el>
    <dt-loading-el></dt-loading-el>
    </span>
</template>

<script>
  import DtTipEl from "./DtTip";
  import DtModalEl from "./DtModal";
  import DtMessageBoxEl from "./DtMessageBox";
  import DtNoticeEl from "./DtNotice";
  import DtLoadingEl from "./DtLoading";
  export default {
    name: "DtPlugins",
    components: {
      DtLoadingEl,
      DtNoticeEl,
      DtMessageBoxEl,
      DtModalEl,
      DtTipEl
    }
  }
</script>

<style scoped>

</style>
