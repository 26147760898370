<template>
  <div class="showTip-main">
    <div class="tip-row">
      <div>
        <i class="el-icon-warning icon-color"></i>
        <span
          >陌拜营销受运营商管控，过度营销行为存在号码标记、投诉甚至封号风险。请注意营销话术，勿在营销过程中出现欺骗、辱骂、挑衅等行为。感谢配合！</span
        >
      </div>
    </div>
    <div class="footer">
      <div class="save-this-search">
        <span><el-checkbox v-model="checked">不再提示</el-checkbox></span>
      </div>
      <div>
        <el-button size="mini" @click="cancelBtn">取消</el-button>
        <el-button type="primary" size="mini" @click="confirmBtn"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'waihuTip',
  data() {
    return {
      checked: false
    }
  },
  mounted() {},
  computed: {},
  methods: {
    confirmBtn() {
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          confirm: true,
          checked: this.checked
        }
      })
      this.cancelBtn()
    },

    cancelBtn() {
      this.$store.dispatch('hideModal')
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.showTip-main {
  padding: 0 20px;
  box-sizing: border-box;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
}
.footer::before {
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(0.5);
}
.tip-row {
  /*height: 67px;*/
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tip-row > div:first-child {
  text-align: right;
}
.icon-color {
  color: #e6a23c;
  font-size: 18px;
}
</style>
