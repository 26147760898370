<template>
  <div style="position: relative">
    <div class="p-close">
      <i
        class="el-icon-refresh refresh"
        v-show="showDetail && version === 'b'"
        @click="
          showKey(
            formData.packageId,
            formData.companyName,
            formData.companyUuid
          )
        "
      ></i>
      <el-link
        :underline="false"
        style="font-size: 12px;text-decoration: underline;color: #8A8A8A"
        @click="changeVersion"
        >{{ version === 'b' ? '回到旧版' : '电销专业版' }}</el-link
      >
      <i class="el-icon-close" @click="closePlate"></i>
    </div>
    <div class="mask" v-if="detail.show === false && version === 'b'">
      <span
        ><span @click="lingQu" class="l-q">领取</span
        ><span>以查看所有信息</span></span
      >
      <!--      <el-button type="primary" size="small" @click="lingQu">领取</el-button>-->
    </div>
    <div
      v-if="version === 'b'"
      class="Workflow-main"
      v-loading="loading"
      :style="{ filter: detail.show === false ? 'blur(2px)' : '' }"
    >
      <template v-if="showDetail">
        <div v-if="detail && Object.keys(detail).length" class="c-name-icon">
          <div
            class="c-name"
            style="cursor: pointer"
            @click="copy(detail.company_name)"
          >
            {{ detail.company_name }}
          </div>
          <div class="c-icon">
            <span
              v-show="detail.hot && this.versionNum.indexOf('002') < 0"
              class="icon-icon hot-icon"
            >
              热度:
              <span v-if="!detail.exclusiveShow" style="filter: blur(3px)"
                >1089136</span
              >
              <span v-else>
                {{ detail.hot }}
              </span>
            </span>
            <span v-show="detail.activity_class" class="active-icon icon-icon"
              >活跃度:
              <span v-if="!detail.exclusiveShow" style="filter: blur(3px)">
                高
              </span>
              <span v-else>
                {{ detail.activity_class }}
              </span>
            </span>
            <span v-show="kpProvider" class="yun-icon icon-icon"
              >云服务商:
              <span v-if="!detail.exclusiveShow">
                阿里云
              </span>
              <span v-else>
                {{ kpProvider }}
              </span>
            </span>
            <span>
              <el-popover
                popper-class="el-popper-dt-dt"
                @show="showIdcFun"
                v-if="kpProvider"
                placement="bottom-start"
                width="500"
                trigger="click"
              >
                <span
                  class="add-clound"
                  slot="reference"
                  v-show="fromPath !== '/preview/list'"
                  >历史云服务商</span
                >
                <div class="clound-list" v-loading="idcLoading">
                  <div class="header-idc">
                    <div>IDC变更</div>
                    <div
                      :style="{
                        filter: detail.show === false ? 'blur(2.5px)' : ''
                      }"
                    >
                      <i
                        class="el-icon-refresh-right refresh-idc"
                        :class="
                          refreshIdcLoading
                            ? 'refresh-idc-start'
                            : 'refresh-idc-stop'
                        "
                        @click="refreshIdc"
                      ></i>
                    </div>
                  </div>
                  <el-scrollbar class="scroll-idc dt-dropdown-dt">
                    <div v-if="detail.show === false" class="filter-lq">
                      <div>
                        <span
                          ><span class="lq-span" @click="lingQu">领取</span
                          ><span style="color: #8c939d"
                            >以查看所有信息</span
                          ></span
                        >
                      </div>
                    </div>
                    <div
                      class="idc-main"
                      :style="{
                        filter: detail.show === false ? 'blur(2.5px)' : ''
                      }"
                      v-if="idcData && idcData.length"
                    >
                      <el-timeline>
                        <el-timeline-item
                          placement="top"
                          v-for="(item, index) in idcData"
                          :color="index === 0 ? 'red' : ''"
                        >
                          <div class="time-idc-line">
                            <span>{{ item.time }}</span
                            ><span>{{ item.idcName }}</span>
                          </div>
                          <div
                            v-for="follow in item.follows"
                            class="idc-line-text"
                          >
                            {{ `${follow.isp} - ${follow.address}` }}
                          </div>
                        </el-timeline-item>
                      </el-timeline>
                    </div>
                    <span
                      class="empty"
                      v-else
                      :style="{
                        filter: detail.show === false ? 'blur(2.5px)' : ''
                      }"
                    >
                      {{ detail.show === false ? '' : '暂无数据' }}
                    </span>
                  </el-scrollbar>
                </div>
              </el-popover>
            </span>
            <span v-if="customerInfoMap.inPool" style="color: #8A8A8A">
              {{ customerInfoMap.desc }}
            </span>
          </div>
        </div>
        <span class="next-btn"> </span>
        <div v-if="detail && Object.keys(detail).length" class="c-detail">
          <div>
            <span class="label">公司网址:</span>
            <span
              class="value"
              style="cursor: pointer;color: #0099CC"
              @click="toShop(detail.website)"
              >{{ detail.website }}</span
            >
          </div>
          <div style="position: relative">
            <span :class="isExpend ? 'expend-value' : 'expend'"
              >简介:
              <span v-show="detail.introduce">{{
                detail.introduce
              }}</span></span
            >
            <span
              class="css-btn"
              v-show="detail.introduce"
              @click="isExpend = !isExpend"
            >
              {{ isExpend ? '展开' : '收起' }}</span
            >
          </div>
        </div>
      </template>
      <div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-scrollbar
              class="panel-left dt-dropdown-dt"
              style="height: 633px"
            >
              <div>
                <div
                  class="title"
                  style="display:flex;justify-content: space-between"
                >
                  <div>
                    人工录入联系人
                  </div>
                  <div style="display: flex;justify-content: flex-end;">
                    <el-checkbox
                      v-model="onlyShowPhoneNum"
                      @change="changePhoneShow"
                      >只显示手机号</el-checkbox
                    >
                  </div>
                </div>
                <div class="w-has-data" v-if="contactData.per.length">
                  <div class="contact-panel" v-for="item in contactData.per">
                    <Contact-detail
                      :active="item.randomId === showBorderId"
                      :contact-data="item"
                      :assign-option="assignOption"
                      :detail="detail"
                      @refresh="getAllContact"
                      @call="call"
                      @selectContact="selectContact"
                    >
                    </Contact-detail>
                  </div>
                </div>
                <div class="w-no-data" v-else>
                  <svg-icon icon-class="stop-icon" class="icon"></svg-icon>
                  <span class="d-empty">暂无数据</span>
                </div>
              </div>
              <div>
                <div class="title">系统提供联系人</div>
                <div class="w-has-data" v-if="contactData.sys.length">
                  <div class="contact-panel" v-for="item in contactData.sys">
                    <Contact-detail
                      :active="item.randomId === showBorderId"
                      :contact-data="item"
                      @refresh="getAllContact"
                      @call="call"
                      @selectContact="selectContact"
                      :assign-option="assignOption"
                      :detail="detail"
                    >
                    </Contact-detail>
                  </div>
                </div>
                <div class="w-no-data" v-else>
                  <svg-icon icon-class="stop-icon" class="icon"></svg-icon>
                  <span class="d-empty">暂无数据</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
          <el-col :span="12">
            <div>
              <div class="title">
                <span> 拨号盘 </span>
                <el-popover
                  popper-class="el-popper-dt-dt"
                  @show="showRecordRequest"
                  placement="bottom-end"
                  width="500"
                  trigger="click"
                >
                  <span class="click" slot="reference"> 通话记录 </span>
                  <div class="record-list" v-loading="showRecordLoading">
                    <div v-for="item in recordList">
                      <record-list
                        :contact-data="item"
                        @refresh="getAllContact"
                        @call="call"
                        :assign-option="assignOption"
                        :detail="detail"
                      >
                      </record-list>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div
                class="wai-hu"
                :style="{ height: bandWaihuType === 'simPhone' ? '100px' : '' }"
              >
                <slot name="waiHu"></slot>
              </div>
            </div>

            <div class="chance-form" v-if="showDetail && isLn">
              <div class="title">
                <span> 新增商机 </span>

                <el-popover
                  popper-class="el-popper-dt-dt"
                  @show="showChanceRequest"
                  placement="bottom-end"
                  width="600"
                  trigger="click"
                >
                  <span class="click" slot="reference"> 商机记录 </span>
                  <div class="record-list" v-loading="loadingNiche">
                    <div v-for="item in chanceList">
                      <chance-list :chance-data="item"></chance-list>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="chance-form-main">
                <el-form
                  size="small"
                  label-width="140px"
                  :model="formData"
                  :rules="rules"
                  ref="form"
                  label-position="left"
                >
                  <el-form-item label="客户销售团队规模" prop="saleRange">
                    <el-select
                      v-model="formData.saleRange"
                      filterable
                      placeholder="请选择客户销售团队规模"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in saleRangeList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="客户销售模式" prop="saleModel">
                    <el-select
                      v-model="formData.saleModel"
                      multiple
                      placeholder="请选择客户销售模式"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in saleModelList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="签单销售" prop="pzSignSale">
                    <el-select
                      v-model="formData.pzSignSale"
                      filterable
                      placeholder="请选择签单销售"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in xiaoShouList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="客户联系人" prop="contactNo">
                    <el-input
                      v-model="formData.contactNo"
                      disabled
                      :style="{
                        border: formData.contactNo ? '1px solid orange' : ''
                      }"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="预估演示时间" prop="predicateDate">
                    <el-date-picker
                      style="width: 100%"
                      v-model="formData.predicateDate"
                      type="datetime"
                      :picker-options="pickerOption"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="选择预估演示时间"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form>
                <div class="earn-niche">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="earnNiche"
                    v-loading="loadingNiche"
                    >生成商机</el-button
                  >
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div style="display: flex;justify-content: flex-end;margin-top: 20px">
          <span>
            <AutoLoad
              @showKey="showKey"
              @loadFirstData="loadFirstData"
              ref="autoLoad"
            />
            <el-button
              v-loading="loadingTransToCustomer"
              size="small"
              type="primary"
              v-dt-permission="'智能获客-推荐线索-转成客户'"
              v-show="
                fromPath !== '/customerpond/customerpond' &&
                  !customerInfoMap.inPool
              "
              @click="transToCustomer"
            >
              转成客户
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="sendKey(3)"
              :disabled="!customerInfoMap.showTransform"
              v-show="
                fromPath !== '/customerpond/customerpond' &&
                  customerInfoMap.inPool
              "
            >
              发送至
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="sendKey(activeTabs)"
              :disabled="!customerInfoMap.showTransform"
              v-show="fromPath === '/customerpond/customerpond'"
              >发送至
            </el-button>
          </span>
        </div>
      </div>
    </div>
    <div class="j-v" v-if="version === 'j'" style="background-color:#fff;">
      <div
        class="wai-hu"
        :style="{ height: bandWaihuType === 'simPhone' ? '100px' : '' }"
      >
        <slot name="waiHu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addNichePz,
  getAllSales,
  getAllContact,
  companyuuid,
  getComDetailModel,
  refreshIdc,
  getDetailAsignOpthon,
  collection_add
} from '@/api/thread'
import {
  getCustomerLeaders,
  getCustomerReceivers,
  getProvider,
  nicheList,
  postCreateCusInfo,
  transformAdd
} from '@/api/customerpond'

import { postConSum } from '@/api/subaccount'
import AutoLoad from './ScrollLoad'
import ContactDetail from '@/components/PhoneStrip/ContactDetail'
import chanceList from '@/components/PhoneStrip/chanceList'
import CustomerReceiver from '@/components/PhoneStrip/dialog/CustomerReceiver'
import sendKey from '@/views/mykey/dialog/sendKey'
import recordList from '@/components/PhoneStrip/recordList'
import { callHistoryList } from '@/api/call'
import { getMode } from '@/api/user'
import { getAccountInfo } from '@/api/usercenter'
export default {
  name: 'Workflow',
  components: {
    AutoLoad,
    ContactDetail,
    recordList,
    chanceList
  },
  data() {
    return {
      bandWaihuType: '',
      version: 'b',
      onlyShowPhoneNum: false,
      isLn: false,
      loading: false,
      detail: {},
      kpProvider: null,
      idcLoading: false,
      idcData: [],
      refreshIdcLoading: false,
      fromPath: localStorage.getItem('fromPath'),
      assignOption: [],
      customerInfoMap: {},
      isExpend: true,
      loadingTransToCustomer: false,
      showRecord: false,
      showRecordLoading: false,
      showChance: false,
      showChanceLoading: false,
      recordList: [],
      chanceList: [],
      xiaoShouList: [], // 签单销售列表
      saleRangeList: [],
      saleModelList: [],
      contactData: {
        per: [],
        sys: []
      }, // 联系人列表

      per: [],
      sys: [],

      showBorderId: '',
      formData: {
        companyName: '',
        companyUuid: '',
        packageId: '',

        pzSignSale: '',
        contactNo: '',
        contact: '',
        predicateDate: '',
        saleRange: '',
        saleModel: []
      },
      rules: {
        pzSignSale: [
          { required: true, message: '请选择签单销售', trigger: 'blur' }
        ],
        contact: [{ required: true, message: '请选择联系人', trigger: 'blur' }],
        predicateDate: [
          { required: true, message: '请选择预估演示时间', trigger: 'blur' }
        ],
        contactNo: [
          { required: true, message: '请选择客户联系人', trigger: 'blur' }
        ],
        saleModel: [
          { required: true, message: '请选择客户销售模式', trigger: 'blur' }
        ],
        saleRange: [
          { required: true, message: '请选择客户销售团队规模', trigger: 'blur' }
        ]
      },
      pickerOption: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      loadingNiche: false
    }
  },
  created() {
    let accountInfo = localStorage.getItem('accountInfo')
      ? JSON.parse(localStorage.getItem('accountInfo'))
      : {}
    this.bandWaihuType = accountInfo.bandWaihuType

    this.$bus.$on('callInit', params => {
      if (this.$refs['autoLoad']) {
        this.$refs['autoLoad'].getCurrentKeyListAndIndex(params)
      }
    })
  },

  watch: {
    version: function(val) {
      if (val === 'b' && this.showDetail) {
        this.$nextTick(() => {
          if (this.$refs['autoLoad']) {
            this.$refs['autoLoad'].getCurrentKeyListAndIndex()
          }
        })
      }
      this.$store.commit('update', {
        target: this.$store.state.user,
        data: {
          plateVersion: val
        }
      })
    }
  },

  mounted() {
    // 获取签单销售
    this.getQdXs()
    this.getAssignOption()
    this.getSaleRangeList()
    this.getSaleModelList()
    getAccountInfo().then(res => {
      if (res.code === 60000) {
        this.isLn = res.data.versionNum === 'LN001'
      }
    })
    this.version = localStorage.getItem('waiHuPanelVersion') || 'b'
  },
  computed: {
    user() {
      return this.$store.state.user.accountInfo
    },

    versionNum() {
      return this.$store.state.user.accountInfo.versionNum
    },

    showDetail() {
      const fromPath = localStorage.getItem('fromPath')
      const showDetailPath = [
        '/',
        '/package',
        '/globalquery/query',
        '/globalquery/query2',
        '/customerpond/customerpond',
        '/booklist/booklist',
        '/globalquery/map',
        '/preview/list'
      ]
      return showDetailPath.includes(fromPath)
    },

    activeTabs() {
      if (this.customerInfoMap.poolPosition === 3) return 2
      if (this.customerInfoMap.poolPosition === 2) return 3
      return this.customerInfoMap.poolPosition
    },

    isAdmin() {
      return this.$store.getters.userInfo.isAdmin
    }
  },
  methods: {
    changePhoneShow() {
      if (this.onlyShowPhoneNum) {
        this.contactData.per = this.per.filter(i => this.isMobile(i.phone))
        this.contactData.sys = this.sys.filter(i => this.isMobile(i.phone))
      } else {
        this.contactData.per = this.per
        this.contactData.sys = this.sys
      }
    },

    isMobile(phone) {
      let reg = /^[1][3,4,5.6,7,8,9][0-9]{9}$/
      return reg.test(phone)
    },

    changeVersion() {
      if (this.version === 'b') {
        this.version = 'j'
        localStorage.setItem('waiHuPanelVersion', 'j')
      } else {
        this.version = 'b'
        localStorage.setItem('waiHuPanelVersion', 'b')
      }
    },

    toShop(url) {
      if (!url) return
      if (
        url.substr(0, 7).toLowerCase() == 'http://' ||
        url.substr(0, 8).toLowerCase() == 'https://'
      ) {
        url = url
      } else {
        url = 'http://' + url
      }
      window.open(url)
    },

    closePlate() {
      this.$bus.$emit('closePlate')
      this.$store.commit('update', {
        target: this.$store.state.user,
        data: {
          plate: false
        }
      })
    },

    copy(text) {
      event.stopPropagation()
      var oInput = document.createElement('input')
      oInput.value = text
      document.body.appendChild(oInput)
      this.selectText(oInput)
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$store.dispatch('showTip', {
        text: '复制成功',
        type: 'success',
        duration: 800
      })
    },
    selectText(oInput) {
      if (oInput.createTextRange) {
        oInput.select()
      } else {
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },

    call(params) {
      const phone = params.phone
      const contact = params.contact
      const companyName = this.detail.company_name
      const packageId = this.formData.packageId || 0
      this.$bus.$emit('callup', phone, contact, companyName, packageId)
    },

    selectContact(params) {
      this.showBorderId = params.randomId
      this.formData.contactNo = `${params.phone} ${params.contact || ''}`
      this.formData.contact = JSON.stringify({
        contact: params.contact,
        phone: params.phone,
        position: params.position || ''
      })
    },

    loadFirstData(params) {
      this.showKey(params.packageId, params.companyName, params.companyUuid)
    },

    async showKey(packageId, companyName, companyUuid) {
      this.formData.packageId = packageId || 0
      this.formData.companyName = companyName
      this.formData.companyUuid = companyUuid

      this.formData.contactNo = ''
      this.formData.contact = ''
      this.formData.predicateDate = ''
      this.formData.saleRange = ''
      this.formData.saleModel = []
      this.$refs['form'] && this.$refs['form'].clearValidate()
      if (this.xiaoShouList.filter(i => i.id === this.user.id).length) {
        this.formData.pzSignSale = this.user.id
      }

      this.showBorderId = ''
      // 不显示detail数据
      if (!this.showDetail) {
        this.loading = false
        return
      }
      this.loading = true
      await Promise.allSettled([this.getAllContact(), this.getDetail()])
      this.loading = false
    },

    // 获取联系人信息
    getAllContact() {
      const params = {
        companyName: this.formData.companyName,
        packageId: this.formData.packageId || 0,
        module: 'touch'
      }
      return new Promise((resolve, reject) => {
        getAllContact(params)
          .then(res => {
            resolve(true)
            if (res.code === 60000 && res.data) {
              res.data.sys
                ? (this.contactData.sys = res.data.sys.map(i =>
                    Object.assign(i, { randomId: Math.random() })
                  ))
                : (this.contactData.sys = [])
              res.data.per
                ? (this.contactData.per = res.data.per.map(i =>
                    Object.assign(i, { randomId: Math.random() })
                  ))
                : (this.contactData.per = [])
              this.sys = this.contactData.sys
              this.per = this.contactData.per
            }
          })
          .catch(() => resolve(true))
      })
    },

    getDetail() {
      const params = {
        packageId: this.formData.packageId,
        companyUuid: this.formData.companyUuid,
        pageId: 2001,
        companyName: this.formData.companyName,
        customerSea:
          localStorage.getItem('beforePath') === '/customerpond/customerpond', // 是否来自客户公海 影响业务简介返回形式,
        preview: localStorage.getItem('isPreview') === 'true'
      }
      return new Promise((resolve, reject) => {
        companyuuid(params)
          .then(res => {
            resolve(true)
            if (res.code === 60000) {
              this.detail = res.data || {}
              this.customerInfoMap = this.detail.customerInfoMap || {}
              this.getKeyService(
                this.formData.companyName,
                this.formData.companyUuid
              )
              this.$store.commit('update', {
                target: this.$store.state.user,
                data: {
                  customerInfoMap: this.customerInfoMap
                }
              })
            }
          })
          .catch(() => resolve(false))
      })
    },

    getKeyService(cname, cuid) {
      if (!Object.keys(this.detail).includes('gongyingshang')) {
        this.kpProvider = null
        return
      }
      // 调用失败则清空供应商信息
      getProvider({ companyName: cname, companyUuid: cuid })
        .then(res => {
          if (res.code === 60000) {
            this.kpProvider = res.data
          } else {
            this.kpProvider = null
          }
        })
        .catch(() => {
          this.kpProvider = null
        })
    },

    showIdcFun() {
      this.idcData = []
      const params = {
        module: 'idc',
        packageId: this.formData.packageId,
        companyUuid: this.formData.companyUuid
      }
      this.idcLoading = true
      getComDetailModel(params).then(res => {
        this.idcLoading = false
        if (res.code === 60000 && res.data && res.data['云供应商变更']) {
          const idcData = res.data['云供应商变更'].list
          const result = {}
          idcData.forEach(item => {
            result[item.timeAt]
              ? (result[item.timeAt] = [...result[item.timeAt], item])
              : (result[item.timeAt] = [item])
          })
          const _res = []
          for (let i in result) {
            _res.push({
              time: i,
              idcName: result[i][0]['gongyingshang'],
              follows: result[i]
            })
          }
          this.idcData = _res
        }
      })
    },

    refreshIdc() {
      if (!this.detail.company_name || this.detail.show === false) return
      const params = {
        companyName: this.detail.company_name,
        companyUuid: this.formData.companyUuid
      }
      this.refreshIdcLoading = true
      this.$store.dispatch('showTip', {
        text: '正在为您刷新云服务商信息',
        type: 'success'
      })
      refreshIdc(params).then(res => {
        this.refreshIdcLoading = false
        if (res.code === 60000 && res.data) {
          this.showIdcFun()
          this.getDetail()
        }
      })
    },

    getQdXs() {
      getAllSales().then(res => {
        if (res.code === 60000) {
          const user = res.data.filter(i => i.id === this.user.id)
          this.xiaoShouList = [
            ...user,
            ...res.data.filter(i => i.id !== this.user.id)
          ]
          if (user && user.length) {
            this.formData.pzSignSale = this.user.id
          }
        }
      })
    },

    getAssignOption() {
      getDetailAsignOpthon().then(res => {
        this.assignOption = res.data.map(item => {
          if (item.name === '意向') item.name = '意向 (自动转入客户公海)'
          if (item.name === '有效') item.name = '有效（电话可打通）'
          return item
        })
      })
    },

    getSaleRangeList() {
      getMode({ type: 'XS_01' }).then(res => {
        if (res.code === 60000) {
          this.saleRangeList = res.data['XS_01']
        }
      })
    },

    getSaleModelList() {
      getMode({ type: 'XS_MODEL_01' }).then(res => {
        if (res.code === 60000) {
          this.saleModelList = res.data['XS_MODEL_01']
        }
      })
    },

    earnNiche() {
      if (this.loadingNiche) return
      this.$refs['form'].validate(valid => {
        if (!valid) return
        this.loadingNiche = true
        const params = Object.assign({}, this.formData, {
          saleModel: this.formData.saleModel.join(',')
        })
        addNichePz(params).then(res => {
          this.loadingNiche = false
          if (res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
          }
          this.formData.pzSignSale = ''
          this.formData.contactNo = ''
          this.formData.contact = ''
          this.formData.predicateDate = ''
          this.formData.saleRange = ''
          this.formData.saleModel = []
        })
      })
    },

    lingQu() {
      this.$store.dispatch('showMessageBox', {
        title: `领取线索`,
        text: '是否添加至默认培育池？',
        type: 'warning',
        onClose: res => {
          if (res === 'confirm') {
            // 添加到默认培育池
            // 领取线索
            Promise.allSettled([this.addLq(), this.addToPeiYu()])
          }
          if (res === 'cancel') {
            // 领取线索
            this.addLq()
          }
        }
      })
    },

    addToPeiYu() {
      new Promise((resolve, reject) => {
        const params = {
          companyUuids: this.formData.companyUuid,
          packageId: this.formData.packageId,
          favoriteId: '',
          isShowSelectBook: 0
        }
        collection_add(params)
          .then(res => resolve(true))
          .catch(() => resolve(true))
      })
    },

    addLq() {
      new Promise((resolve, reject) => {
        const params = {
          companyUuid: this.formData.companyUuid,
          packageId: this.formData.packageId
        }
        postConSum(params)
          .then(res => {
            resolve(true)
            if (res.code === 60000 && res.data) {
              this.showKey(
                this.formData.packageId,
                this.formData.companyName,
                this.formData.companyUuid
              )
            }
          })
          .catch(() => resolve(true))
      })
    },

    async transToCustomer() {
      if (this.loadingTransToCustomer) return
      this.loadingTransToCustomer = true
      let leaders = await this.getLeader()
      leaders = leaders.filter(i => i.name !== '可跟进的')
      console.log(leaders)
      if (leaders.length === 0) {
        this.loadingTransToCustomer = false
        return
      }
      if (leaders.length === 1) {
        const id = leaders[0].id
        this.transformRequest(id)
      }
      if (leaders.length > 1) {
        const userId = this.user.id
        await this.$store.dispatch('showModal', {
          title: '选择发送对象',
          size: '550px',
          view: CustomerReceiver,
          options: {
            userId: userId,
            list: leaders
          },
          onClose: res => {
            if (res.refresh) {
              this.transformRequest(res.id)
            } else {
              this.loadingTransToCustomer = false
            }
          }
        })
      }
    },

    transformRequest(id) {
      const params = {
        userId: id,
        companyName: this.formData.companyName,
        packageId: this.formData.packageId,
        haveContact: false
      }
      postCreateCusInfo(params)
        .then(res => {
          this.loadingTransToCustomer = false
          if (res.code === 60000) {
            transformAdd({ companyUuid: this.formData.companyUuid }).then(
              res => {}
            )
            this.getDetail()
            this.$store.dispatch('showTip', {
              text: '转成客户成功',
              type: 'success'
            })
          }
        })
        .catch(() => {
          this.loadingTransToCustomer = false
        })
    },

    getLeader() {
      const params = {
        pageId: 2001,
        type: 1
      }
      return new Promise((resolve, reject) => {
        getCustomerLeaders(params)
          .then(res => {
            if (res.code === 60000) {
              resolve(res.data)
            } else {
              resolve([])
            }
          })
          .catch(() => resolve([]))
      })
    },

    sendKey(type) {
      const params = {
        type: type,
        pageId: 2001
      }
      getCustomerReceivers(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showModal', {
            title: '选择发送对象',
            options: {
              userList: res.data,
              type: 'keyDetail'
            },
            view: sendKey,
            onClose: res => {
              if (res.refresh) {
                this.getDetail()
              }
            }
          })
        }
      })
    },

    showRecordRequest() {
      this.showRecord = true
      // 获取通话记录
      const params = {
        pageNo: 1,
        pageSize: 3,
        pageId: 502
      }
      this.showRecordLoading = true
      callHistoryList(params).then(res => {
        this.showRecordLoading = false
        if (res.code === 60000) {
          this.recordList = res.data.data
        }
      })
    },

    showChanceRequest() {
      if (!this.formData.companyName) {
        this.chanceList = []
        return
      }
      this.showChanceLoading = true
      const params = {
        keyWords: this.formData.companyName,
        pageNo: 1,
        pageSize: 5,
        pageId: 2001
      }
      nicheList(params).then(res => {
        this.showChanceLoading = true
        if (res.code === 60000) {
          this.chanceList = res.data.data
        }
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.Workflow-main {
  max-height: calc(100vh - 70px);
  min-height: 200px;
  overflow-y: auto;
  width: calc(100vw - 250px);
  background-color: #fff;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 3px;
  position: relative;
}
.mask {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8a8a8a;
  z-index: 9999;
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}
.l-q {
  color: #0099cc;
  cursor: pointer;
  margin-right: 3px;
  font-weight: bold;
  text-decoration: underline;
}

.contact-panel {
  margin-bottom: 20px;
}
.w-has-data > .contact-panel:last-child {
  margin-bottom: 0 !important;
}
.c-name-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  position: relative;
}
.btn {
  position: absolute;
  right: 0;
  top: 0;
}
.next-btn {
  position: absolute;
  right: 110px;
  top: 25px;
}
.c-name {
  font-size: 20px;
  font-weight: bolder;
}
.c-icon {
  font-size: 12px;
  height: 100%;
  line-height: 30px;
}
.c-icon > span {
  /*margin-left: 5px;*/
}
.panel-left {
  width: 100%;
  min-height: 500px;
  /*height: auto;*/
}
.w-no-data {
  height: 100px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #8a8a8a;
  padding-left: 20px;
  box-sizing: border-box;
}
.icon {
  font-size: 40px;
}
.title {
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
}
.d-empty {
  margin-left: 15px;
}
.wai-hu {
  min-height: 100px;
  height: auto;
  width: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.click {
  color: #0099cc;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.chance-form-main {
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.earn-niche {
  display: flex;
  justify-content: flex-end;
}
.label,
.value {
  font-size: 12px;
}
.expend-value {
  font-size: 12px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  padding-right: 20px;
  box-sizing: border-box;
}
.expend {
  font-size: 12px;
}
.css-btn {
  position: absolute;
  right: -20px;
  bottom: 0;
  display: inline-block;
  height: 20px;
  width: 40px;
  line-height: 20px;
  background-color: #fff;
}
.label {
  margin: 5px 0;
  display: inline-block;
}
.c-detail {
  padding: 10px 0;
  box-sizing: border-box;
}

/*刷新云服务商*/
.add-clound {
  color: rgb(18, 150, 219);
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}
.clound-list {
  width: 100%;
}
.scroll-idc {
  height: 300px;
  width: 100%;
  position: relative;
}
.idc-main {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0;
  box-sizing: border-box;
}
.time-idc-line {
  transform: translateY(-12px);
  color: #333333;
  font-size: 14px;
}
.time-idc-line > span:first-child {
  margin-right: 20px;
}
.idc-line-text {
  color: #aaaaaa;
  line-height: 150%;
  font-size: 13px;
}
.header-idc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
}
.header-idc::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  transform: scaleY(0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.header-idc i {
  font-size: 20px;
  color: #41cabf;
  cursor: pointer;
}
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8c939d;
  font-size: 12px;
}
.refresh-idc-start {
  transform: rotate(259deg);
  animation: load 1.04s linear infinite;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.new-add-right /deep/ .el-tag--dark {
  border: none !important;
}

.filter-lq {
  position: absolute;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.lq-span {
  z-index: 99;
  cursor: pointer;
  color: rgb(0, 140, 217);
  text-decoration: underline;
  margin-right: 3px;
}
.icon-icon {
  font-size: 14px;
  display: inline-block;
  padding-left: 25px;
  position: relative;
}
.hot-icon:before {
  content: '';
  width: 15px;
  height: 15px;
  left: 8px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: url('../../assets/yun1.png') no-repeat;
  background-size: 100% 100%;
}
.active-icon:before {
  content: '';
  width: 15px;
  height: 15px;
  left: 8px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: url('../../assets/active-key.svg') no-repeat;
  background-size: 100% 100%;
}
.yun-icon:before {
  content: '';
  width: 12px;
  height: 12px;
  left: 8px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: url('../../assets/yun2.png') no-repeat;
  background-size: 100% 100%;
}
.css-btn {
  font-size: 12px;
  cursor: pointer;
  color: #0099cc;
}
.record-list {
  min-height: 200px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.p-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999999;
  cursor: pointer;
}
</style>
