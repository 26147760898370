import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 退出登录
export const userLogout = async() => {
  const api = API('USER_LOGOUT')
  return await BASE_REQUEST({ api: api })
}

// 个人信息
export const userInfo = async() => {
  const api = API('USER_CENTER')
  return await BASE_REQUEST({ api: api })
}

// 修改手机号
export const changePhone = async(params) => {
  const api = API('CHANGE_MOBILE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 修改密码
export const changePsw = async(params) => {
  const api = API('CHANGE_PASSWORD')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 修改企业信息
export const changeComInfo = async(params) => {
  const api = API('CHANGECOM_INFO')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取手机验证码
export const getSmsSend = async(params) => {
  const api = API('SMS_SEND')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 校验验证码
export const checkPhoneCode = async(params) => {
  const api = API('CHECK_CODE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 推荐刷新线索包
export const getRefreshPackge = async(params) => {
  const api = API('GIVE_PACKAGE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 用户高级定制线索包
export const getPackMade = async(params) => {
  const api = API('GET_CUSMADE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 购买版本计算价格
export const getPrePrice = async(params) => {
  const api = API('GET_PREPRICE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 绑定手机号
export const getPhoneBind = async(params) => {
  const api = API('GET_PHONEBIND')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 拨打电话
export const getPhoneCall = async(params) => {
  const api = API('GET_PHONECALL')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 外呼结果回调
export const getCallBack = async(params) => {
  const api = API('GET_CALLBACK')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 根据code获取地址
export const getCodeArea = async(params) => {
  const api = API('GET_THREEAREA')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除客户画像
export const DelCustomerPic = async(params) => {
  const api = API('DELETE_CUSPIC')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心 基本账户信息
export const getAccountInfo = async(params) => {
  const api = API('GET_ACCOUNTINFO')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心 企业信息
export const getCompanyInfo = async(params) => {
  const api = API('GET_COMPANYINFO')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心 企业画像
export const getCompanyPic = async(params) => {
  const api = API('GET_COMPANYPIC')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心 已购订单
export const getHadOrder = async(params) => {
  const api = API('GET_HADORDER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取版本定价
export const getPversionPrice = async(params) => {
  const api = API('GET_PVERSION')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取用户名
export const getUserName = async(params) => {
  const api = API('GET_USERNAME')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 申请测试
export const getUserApply = async(params) => {
  const api = API('GET_USERAPPLY')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 申请测试状态
export const getTestStutus = async(params) => {
  const api = API('GET_TESTSTATUS')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 检测手机号
export const getAuthCheckMobild = async(params) => {
  const api = API('GET_AUTHCHECKMOBILE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取用户基础信息
export const getAuthUserMeal = async(params) => {
  const api = API('GET_AUTHUSERMEAL')
  return await BASE_REQUEST({ api: api, opt: params })
}
