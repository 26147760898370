import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 系统配置
export const getFavoritesList = async params => {
  const api = API('GET_FAVORITESLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getFavoritesAdd = async params => {
  const api = API('GET_FAVORITESADD')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const postFavoritesUpdate = async params => {
  const api = API('POST_FAVORITESUPDATE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const delFavoritesDel = async params => {
  const api = API('DEL_FAVORITESDEL')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const postMoveFavoritesGroup = async params => {
  const api = API('POST_MOVEFAVOTITESGROUP')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getMarketingLandList = async params => {
  const api = API('GET_MARKETINGLANDLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getPreview = async params => {
  const api = API('GET_PREVIEW')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const enableView = async params => {
  const api = API('POST_ENABLEVIEW')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const postMarketUpload = async params => {
  const api = API('POST_MARKETUPLOAD')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getStsToken = async params => {
  const api = API('GET_STSTOKEN')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getMarketingLandPage = async params => {
  const api = API('POST_MARKINGLANDPAGE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const postMarketingPublish = async params => {
  const api = API('POST_MARKETINGPUBLISH')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const delMarketLandPage = async params => {
  const api = API('DEL_MARKETLANDPAGE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getMarketlandPageDetail = async params => {
  const api = API('GETMARKETLANDPAGEDETAIL')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getMarketLandPageUpdate = async params => {
  const api = API('GETMARKETLANPAGEUPDATE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const putMarketPageRename = async params => {
  const api = API('PUTMARKETPAGERENAME')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const postMarketLandpageCopy = async params => {
  const api = API('POSTMARKETLANPAGECOPY')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const postMakeMicro = async params => {
  const api = API('MARKEMICRO')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const addConvert = async params => {
  const api = API('ADDCONVERT')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getLeaveInfoList = async params => {
  const api = API('GETLEAVEINFOLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const postMarketLoadRecords = async params => {
  const api = API('POST_MARKETUPLOADRECORDS')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const postFollowSaveRecords = async params => {
  const api = API('POSTFOLLOWSAVERECORDS')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getFollowType = async params => {
  const api = API('GETFOLLOWTYPE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const runIn = async params => {
  const api = API('runIn')
  return await BASE_REQUEST({ api: api, opt: params })
}
