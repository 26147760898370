<template>
  <div class="CustomerReceiver-main">
    <div class="c-r-main">
      <el-form label-width="auto" :model="formData" :rules="rules" ref="form">
        <el-form-item label="选择发送对象" prop="customer">
          <el-select filterable v-model="formData.customer" placeholder="请选择发送对象" style="width: 100%">
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button size="small" type="primary" @click="confirmBtn">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerReceiver',
  data () {
    return {
      list: [],
      formData: {
        customer: ''
      },
      rules: {
        customer: [
          { required: true, message: '请选择发送对象', trigger: 'change' },
        ]
      }
    }
  },
  mounted () {
    const modalOption = this.$store.state.plugin.modal.options
    this.list =modalOption.list
    if(modalOption.userId === this.userId){
     this.formData.customer = this.userId
    }
  },
  computed: {
    userId(){
      return this.$store.state.user.accountInfo.id
    }
  },
  methods: {
    cancelBtn(){
      this.$store.dispatch('hideModal')
    },
    confirmBtn(){
      this.$refs['form'].validate(valid => {
        if(valid){
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              id: this.formData.customer,
              refresh: true,
            }
          })
          this.cancelBtn()
        }
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.c-r-main{
  padding: 10px;
  box-sizing: border-box;
}
.footer{
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-right: 10px;
  box-sizing: border-box;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
}

</style>
