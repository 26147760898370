<template>
  <div class="chanceList-main">
    <div>
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          创建人：{{ chanceData.createFollowName }}
        </el-col>
        <el-col :span="12">
          签单销售：{{ chanceData.followName }}
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          客户联系人：{{ chanceData.billKeypersonDesc || '' }}
        </el-col>
        <el-col :span="12">
          预估演示时间：{{ chanceData.productShowDate }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chanceList',
  data () {
    return {}
  },
  props: ['chanceData'],
  mounted () {},
  computed: {},
  methods: {},
  beforeDestroy () {}
}
</script>

<style scoped>
.chanceList-main{
  height: 100px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid rgba(0,0,0,.1);
  padding: 20px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
