<template>
  <div class="autoCallTask-main" />
</template>

<script>
import { autoWaiHuList, callWaiHu } from '@/api/customerpond'
export default {
  name: 'autoCallTask',
  mounted() {
    this.$bus.$on('autoCallTask', () => {
      if (this.openAutoCallTask) this.init()
    })
  },
  computed: {
    openAutoCallTask() {
      return this.$store.state.plugin.openAutoCallTask
    },
    waiHuFilter() {
      return this.$store.state.plugin.waiHuFilter
    }
  },
  watch: {
    openAutoCallTask(val) {
      if (val) this.init()
    }
  },
  methods: {
    async init() {
      try {
        // 获取一个外呼号码 （取列表的第一条数据）
        const data = await this.getPhoneOne()
        if (!data) {
          // 关闭自动外呼
          this.closeAutoCallTask()
          return
        }
        const isOk = await this.changeCallStatus(data)
        if (!isOk) {
          // 关闭自动外呼
          this.closeAutoCallTask()
          return
        }
        // 拨打电话
        this.startAutoCallTask(data)
      } catch (e) {
        this.closeAutoCallTask()
      }
    },

    startAutoCallTask(data) {
      // todo 通过总线通知外呼组件拨打改号码（传入号码、外呼类型：是否为自动外呼、间隔时长）
      this.$bus.$emit('callUpByAutoCall', data)
    },

    // 获取一个外呼号码 （取列表的第一条数据）
    getPhoneOne() {
      return new Promise((resolve, reject) => {
        const params = {
          from: this.waiHuFilter.from || '',
          pageNo: this.waiHuFilter.pageNo || 1,
          sort: this.waiHuFilter.sort || '',
          sortOrder: this.waiHuFilter.sortOrder || '',
          word: this.waiHuFilter.word || '',
          type: 0
        }
        autoWaiHuList(params).then(res => {
          if (res.code === 60000 && res.data && res.data.list) {
            if (!res.data.list.length) {
              this.$store.dispatch('showTip', {
                text: '任务已经完成',
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin,
                data: {
                  openAutoCallTask: false
                }
              })
              return
            }
            const one = res.data.list[0]
            resolve({
              phone: one.phone,
              contact: one.contact_name,
              companyName: one.company_name,
              type: 'autoCall',
              id: one.id
            })
          } else {
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                openAutoCallTask: false
              }
            })
            this.$store.dispatch('showTip', {
              text: `任务终止`,
              type: 'error'
            })
          }
        })
      })
    },

    // 发送请求通知后端 此号码已经进入外呼任务
    changeCallStatus(data) {
      return new Promise((resolve, reject) => {
        callWaiHu({ id: data.id }).then(res => {
          if (res.code === 60000) {
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                autoCallCurrent: data
              }
            })
            resolve(true)
          } else {
            this.closeAutoCallTask()
          }
        })
      })
    },

    // 关闭外呼任务
    closeAutoCallTask() {
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          openAutoCallTask: false
        }
      })
    }
  }
}
</script>
