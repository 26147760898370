var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-star" }, [
    _vm.data == "true"
      ? _c("span", { staticClass: "has_star", on: { click: _vm.handleRemove } })
      : _c("span", { staticClass: "no_star", on: { click: _vm.handleNature } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }