import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 个人信息
export const userInfo = async () => {
  const api = API('USER_CENTER')
  return await BASE_REQUEST({ api: api })
}

// 修改手机号
export const getOrderNum = async params => {
  const api = API('GET_BUTORDER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 支付宝api
export const getZFBPay = async params => {
  const api = API('GET_ZHBPAY')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 支付宝支付结果
export const getPayResult = async params => {
  const api = API('GET_PAYRESULT')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取版本定价
export const getPversionPrice = async params => {
  const api = API('GET_PVERSION')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 购买版本计算价格
export const getPrePrice = async params => {
  const api = API('GET_PREPRICE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 计算充值价格
export const getPrePriceOther = async params => {
  const api = API('GET_PREPRICEOTHER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 订单列表
export const getOrderList = async params => {
  const api = API('GET_ORDERLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成购买子账户订单
export const postSubOrder = async params => {
  const api = API('POST_SUBORDER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成订阅卡订单
export const postOrderCard = async params => {
  const api = API('POST_ORDERCARD')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成充值话费订单
export const postCallCharge = async params => {
  const api = API('POST_CALLCHARGE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成基础套餐订单
export const postBasicOrder = async params => {
  const api = API('POST_BASICORDER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成购买线索订单
export const postClubOrder = async params => {
  const api = API('POST_CLUBORDER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 生成优惠卷订单
export const couponOrder = async params => {
  const api = API('couponOrder')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 拨打电话
export const postApiCall = async params => {
  const api = API('POST_APICALL')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查询拨打状态
export const getCollCallStatus = async params => {
  const api = API('GET_COLLCALLSTATUS')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 上传文件
export const postFileUpload = async params => {
  const api = API('GET_FILEUPLOAD')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 导出文件
export const postExport = async params => {
  const api = API('POST_EXPORT')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取基础套餐列表
export const getBaseMealList = async params => {
  const api = API('GET_BASEMEALLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const refreshPayBack = async params => {
  const api = API('refreshPayBack')
  return await BASE_REQUEST({ api: api, opt: params })
}
