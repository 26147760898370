<template>
  <div class="VmiScroll-main" :style="{ height: scrollHeight }">
    <el-scrollbar ref="scrollbar" class="scroll-main">
      <div
        v-if="subscribeScroll"
        class="slot-div"
        :style="{ margin: '0 auto', width: `calc( 100% - ${margin * 2}px )` }"
        v-infinite-scroll="load"
        :infinite-scroll-delay="500"
        :infinite-scroll-immediate="false"
      >
        <slot />
      </div>
      <div
        v-else
        class="slot-div"
        :style="{ margin: '0 auto', width: `calc( 100% - ${margin * 2}px )` }"
      >
        <slot />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'DtScroll',
  props: {
    height: {
      type: [Number, String],
      default: '100%'
    },
    // 是否订阅滚动事件
    subscribeScroll: {
      type: Boolean,
      default: false
    },
    margin: {
      type: [Number, String],
      default: 10
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$bus.$on('handleScrollTop', () => {
        this.$refs.scrollbar?.wrap?.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
    })
  },
  computed: {
    scrollHeight() {
      return typeof this.height === 'number' ? `${this.height}px` : this.height
    }
  },
  methods: {
    load() {
      this.$emit('onScrollBottom')
    }
  }
}
</script>

<style scoped lang="scss">
.VmiScroll-main {
  width: 100%;
  overflow-x: hidden;
  .scroll-main {
    height: 100%;

    .slot-div {
      height: 100%;
    }

    .el-scrollbar__wrap {
      &::-webkit-scrollbar {
        width: 17px;
      }
    }
  }
}
</style>
