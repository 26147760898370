var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editCompanyOrUser-main" }, [
    _c(
      "div",
      { staticClass: "edit-main" },
      [
        _c(
          "el-form",
          {
            ref: "keyForm",
            staticClass: "form-edit",
            attrs: { rules: _vm.rules, "label-width": "auto", size: "small" },
            model: {
              value: _vm.formData,
              callback: function ($$v) {
                _vm.formData = $$v
              },
              expression: "formData",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("div", { staticClass: "header-title" }, [
                    _vm._v("公司信息"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "负责人", prop: "userId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择负责人" },
                            model: {
                              value: _vm.formData.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "userId", $$v)
                              },
                              expression: "formData.userId",
                            },
                          },
                          _vm._l(_vm.leaderList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司名称", prop: "companyName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: "",
                            placeholder: "请输入公司名称",
                          },
                          model: {
                            value: _vm.formData.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "companyName", $$v)
                            },
                            expression: "formData.companyName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户来源", prop: "fromId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择客户来源" },
                            model: {
                              value: _vm.formData.fromId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "fromId", $$v)
                              },
                              expression: "formData.fromId",
                            },
                          },
                          _vm._l(_vm.allSource, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "省份", prop: "province" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "100%",
                              "box-sizing": "border-box",
                            },
                            attrs: { placeholder: "请选择省份" },
                            on: { change: _vm.getAllCity },
                            model: {
                              value: _vm.formData.province,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "province", $$v)
                              },
                              expression: "formData.province",
                            },
                          },
                          _vm._l(_vm.allProvince, function (item) {
                            return _c("el-option", {
                              key: item.name,
                              attrs: { label: item.name, value: item.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "城市", prop: "province" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%", float: "right" },
                            attrs: { placeholder: "请选择城市" },
                            model: {
                              value: _vm.formData.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "city", $$v)
                              },
                              expression: "formData.city",
                            },
                          },
                          _vm._l(_vm.allCity, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择客户状态" },
                            model: {
                              value: _vm.formData.stateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "stateId", $$v)
                              },
                              expression: "formData.stateId",
                            },
                          },
                          _vm._l(_vm.allStatus, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "线索等级" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择线索等级" },
                            model: {
                              value: _vm.formData.clueGradeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "clueGradeId", $$v)
                              },
                              expression: "formData.clueGradeId",
                            },
                          },
                          _vm._l(_vm.allClue, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户等级" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择客户等级" },
                            model: {
                              value: _vm.formData.gradeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "gradeId", $$v)
                              },
                              expression: "formData.gradeId",
                            },
                          },
                          _vm._l(_vm.allLevel, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "标签" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "selectTag",
                            staticStyle: { width: "100%" },
                            attrs: {
                              multiple: "",
                              placeholder: "请选择标签",
                              size: "small",
                            },
                            on: { change: _vm.selectChange },
                            model: {
                              value: _vm.selectTags,
                              callback: function ($$v) {
                                _vm.selectTags = $$v
                              },
                              expression: "selectTags",
                            },
                          },
                          _vm._l(_vm.allPerTags, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请输入备注",
                            type: "textarea",
                            autosize: { minRows: 2, maxRows: 10 },
                            maxlength: "100",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.formData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "remark", $$v)
                            },
                            expression: "formData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "userForm",
            staticStyle: { "padding-left": "1em" },
            attrs: {
              rules: _vm.userRules,
              "label-width": "auto",
              size: "small",
            },
            model: {
              value: _vm.userFormData,
              callback: function ($$v) {
                _vm.userFormData = $$v
              },
              expression: "userFormData",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("div", { staticClass: "header-title2" }, [
                    _vm._v("联系人信息"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名", prop: "contact" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入姓名" },
                          model: {
                            value: _vm.userFormData.contact,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "contact", $$v)
                            },
                            expression: "userFormData.contact",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "身份" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.userFormData.isKp,
                              callback: function ($$v) {
                                _vm.$set(_vm.userFormData, "isKp", $$v)
                              },
                              expression: "userFormData.isKp",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "关键人", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "中间人", value: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "普通", value: "3" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入部门" },
                          model: {
                            value: _vm.userFormData.department,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "department", $$v)
                            },
                            expression: "userFormData.department",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "职位" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入职位" },
                          model: {
                            value: _vm.userFormData.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "position", $$v)
                            },
                            expression: "userFormData.position",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号码" },
                          model: {
                            value: _vm.userFormData.mobile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userFormData,
                                "mobile",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userFormData.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "座机" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入座机号码" },
                          model: {
                            value: _vm.userFormData.landLine,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userFormData,
                                "landLine",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userFormData.landLine",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "微信号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入微信号" },
                          model: {
                            value: _vm.userFormData.wechat,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "wechat", $$v)
                            },
                            expression: "userFormData.wechat",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮箱" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入邮箱" },
                          model: {
                            value: _vm.userFormData.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "email", $$v)
                            },
                            expression: "userFormData.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择状态" },
                            model: {
                              value: _vm.userFormData.mark,
                              callback: function ($$v) {
                                _vm.$set(_vm.userFormData, "mark", $$v)
                              },
                              expression: "userFormData.mark",
                            },
                          },
                          _vm._l(_vm.markDescList, function (_item) {
                            return _c("el-option", {
                              key: _item.value,
                              attrs: { label: _item.name, value: _item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入联系人备注",
                            maxlength: "100",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.userFormData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "remark", $$v)
                            },
                            expression: "userFormData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.notShow,
                callback: function ($$v) {
                  _vm.notShow = $$v
                },
                expression: "notShow",
              },
            },
            [_vm._v("不再显示")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }