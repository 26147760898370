<template>
  <div class="h-div" v-if="show">
    <div class="header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/permission/permission"
          >角色和权限</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ type }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="input">
      <el-form
        :model="form"
        :inline="true"
        ref="form"
        size="small"
        style="margin-top:20px"
      >
        <el-form-item label="角色名" prop="permissionName" :rules="rules">
          <el-input
            style="width: 100%"
            type="text"
            :disabled="type === '详细'"
            v-model="form.permissionName"
            placeholder="请输入角色名"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限设置" :rules="rules">
          <el-select
            v-model="permissionValue"
            placeholder="请选择"
            @change="handleSelectChange"
          >
            <el-option
              v-for="item in permissionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div class="permission">
      <div class="permission-content">
        <update-permission
          :permission-obj="permissionList"
          @update="permissionUpdate"
          :update-data="updatePermission"
          :permission-value="permissionValue"
          :is-forbid="type === '详细'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UpdatePermission from './updatePermission'
export default {
  name: 'permission',
  components: {
    UpdatePermission
  },
  props: {
    //角色名称
    name: {
      type: String,
      default: ''
    },
    //需要更改的权限
    updatePermission: {
      type: Array,
      default: () => {
        return []
      }
    },
    //可以更改的权限
    permissionList: {
      type: Array,
      default: () => {
        return []
      }
    },
    //操作类型 新建 编辑 查看
    type: {
      type: String,
      default: '新建角色'
    }
  },

  data() {
    return {
      form: {
        permissionName: this.name
      },
      rules: [{ required: true, message: '角色不能为空' }],
      permissionOptions: [
        {
          value: -1,
          label: '全部'
        },
        {
          value: 0,
          label: '智能决策DataHow'
        },
        {
          value: 1,
          label: '拓客'
        },
        {
          value: 2,
          label: '电销'
        },
        {
          value: 3,
          label: '私域'
        },
        {
          value: 4,
          label: '客户'
        },
        {
          value: 5,
          label: '分析'
        },
        {
          value: 6,
          label: '其他'
        }
      ],
      permissionValue: -1
    }
  },

  computed: {
    show() {
      if (this.type === '新建角色') {
        return true
      }
      if (this.updatePermission.length) {
        return true
      }
      return false
    }
  },

  methods: {
    permissionUpdate(data) {
      this.$refs['form'].validate(res => {
        if (res) {
          const res = {
            name: this.form.permissionName,
            data: this.handleData(data)
          }
          console.log(res, 3322)
          this.$emit('save', res)
        }
      })
    },

    //处理权限数据
    handleData(data = []) {
      //   debugger
      if (typeof data !== 'object') {
        return []
      }
      if (data && data.length === 0) {
        return data
      }
      if (!data) {
        return []
      }
      //深拷贝，避免操作数据时影响permission组件的显示
      data = JSON.parse(JSON.stringify(data))
      //处理menu数据  将menu中的checked为false的删除
      data = data.filter(item => {
        return item.checked === true
      })
      //处理page数据 将page中checked为false的删除
      data.forEach(item => {
        let pages = item.pages
        item.pages = pages.filter(page => {
          return page.checked === true
        })
      })
      //删除menu和page中的checked
      data.forEach(item => {
        let pages = item.pages
        delete item.checked
        pages.forEach(page => {
          delete page.checked
          // 删除functions中的checked 和 checked为false的functions
          page.functions = page.functions.filter(_func => {
            return _func.checked && delete _func.checked
          })
        })
      })

      //删除页面为空的menu 没有页面则menu也删除
      data = data.filter(item => {
        return item.pages.length
      })
      return data
    },

    handleSelectChange(e) {
      this.permissionValue = e
      this.$bus.$emit('permissionValueChange', e)
    }
  }
}
</script>

<style scoped>
.h-div {
  height: calc(100% - 20px);
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #fff;
}
.header {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
}
.input {
  /*height: 120px;*/
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
  display: flex;
  align-items: center;
}
.input >>> .el-form-item__label {
  text-align: left !important;
  font-weight: normal !important;
  color: #333 !important;
}
.permission {
  height: calc(100% - 180px);
  width: 100%;
}
.permission-title {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.permission-content {
  height: calc(100% - 50px);
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
</style>
